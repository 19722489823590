/* eslint-disable curly */
/* eslint-disable radix */
/* eslint-disable one-var */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable arrow-parens */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { add, getYear, parseISO, sub, set, setDay, subQuarters } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { SharedService } from 'app/shared/shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit, OnChanges {
  @Input() reportName: string;
  @Input() filter_name: string;
  @Input() dateFilterLabel: string;
  @Input() numValueInput: any;
  @Input() clear: boolean;
  @Input() isScoredDateFilter: string;
  @Output() dateChangeEvent = new EventEmitter<any>();
  initDateFilterLabel: string;
  dateRangeValue: string;
  numValue: string;
  timeUnitValue: string;
  timeTypValue = 'Day';
  dateRange = [
    { value: 'is in the last', viewValue: 'is in the last' },
    { value: 'is in range', viewValue: 'is in range' },
    { value: 'is on', viewValue: 'is on' },
    { value: 'is on or after', viewValue: 'is on or after' },
    { value: 'is month to date', viewValue: 'is month to date' },
  ];
  timeUnit = [
    { value: 'hours', viewValue: 'hours' },
    { value: 'days', viewValue: 'days' },
    { value: 'weeks', viewValue: 'weeks' },
    { value: 'months', viewValue: 'months' },
    { value: 'quarters', viewValue: 'quarters' },
    { value: 'years', viewValue: 'years' },
  ];
  timeType = ['Day', 'Month', 'Year'];
  monthType = [
    { value: '01', viewValue: 'January' },
    { value: '02', viewValue: 'February' },
    { value: '03', viewValue: 'March' },
    { value: '04', viewValue: 'April' },
    { value: '05', viewValue: 'May' },
    { value: '06', viewValue: 'June' },
    { value: '07', viewValue: 'July' },
    { value: '08', viewValue: 'August' },
    { value: '09', viewValue: 'September' },
    { value: '10', viewValue: 'October' },
    { value: '11', viewValue: 'November' },
    { value: '12', viewValue: 'December' },
  ];
  yearType = ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
  // times in milliseconds
  start_time: Date;
  end_time: Date;
  dateValue = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  dateRangeStart = new Date(new Date().setHours(0, 0, 0));
  dateRangeEnd = new Date(new Date().setHours(0, 0, 0));
  monthTypeValue = '01';
  yearTypeValue = new Date().getFullYear().toString();
  showNumInput = true;
  showTimeSelect = false;
  showCalendar = false;
  showMonthSelect = false;
  showYearSelect = false;
  showRangeCalendar = false;
  customReportDates = [];
  newDateRange = {};

  constructor(private sharedService: SharedService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.reportName === 'listenforce' || this.filter_name === 'Scored Date' || this.filter_name === 'Reviewed Date') {
      // hide 'is month to date' dateRange
      this.dateRange = this.dateRange.slice(0, 4);
    }
    this.initDateFilterLabel = this.dateFilterLabel;
    this.numValue = this.numValueInput ? this.numValueInput : '7';
    this.parseDateFilterLabel();
    this.setTime();
    let tempReport: {
      start_date: Date;
      end_date: Date;
    }[];

    if (window.sessionStorage.customReportDates && window.sessionStorage.customReportDates !== 'undefined') {
      this.customReportDates = JSON.parse(window.sessionStorage.customReportDates || []);
      tempReport = this.customReportDates.filter((customReportDate: any) => customReportDate.html_element_name === 'doctors_internet_call_logs');

      if (tempReport.length) {
        this.dateRangeStart = new Date(tempReport[0].start_date);
        this.dateRangeEnd = new Date(tempReport[0].end_date);
        this.dateRangeValue = 'is in range';
        this.setTime();
        this.changeDate();
      }
    }

    if (this.route.snapshot.queryParamMap.get('date_range') && this.isScoredDateFilter !== 'yes') {
      let dateRangeLabel: string = this.route.snapshot.queryParamMap.get('date_range') || '';
      switch (true) {
        case dateRangeLabel.indexOf('is in the last') > -1:
          this.dateRangeValue = 'is in the last';
          this.numValue = dateRangeLabel.split('is in the last')[1].split(' ')[1];
          this.timeUnitValue = dateRangeLabel.split('is in the last')[1].split(' ')[2];
          break;
        case dateRangeLabel.indexOf('is in range') > -1:
          this.dateRangeValue = 'is in range';
          this.dateRangeStart = new Date(dateRangeLabel.split('is in range ')[1].split('-')[0]);
          this.dateRangeEnd = new Date(dateRangeLabel.split('is in range ')[1].split('-')[1]);
          break;
        case dateRangeLabel.indexOf('is on or after') > -1:
          this.dateRangeValue = 'is on or after';
          this.timeTypValue = 'Day';
          this.dateValue = new Date(dateRangeLabel.split('is on or after ')[1]);
          break;
        case dateRangeLabel.indexOf('is on') > -1:
          this.dateRangeValue = 'is on';
          this.timeTypValue = 'Day';
          this.dateValue = new Date(dateRangeLabel.split('is on ')[1]);
          break;
        case dateRangeLabel.indexOf('is in') > -1:
          this.dateRangeValue = 'is on';
          let tempVal = dateRangeLabel.split('is in ')[1];
          if (this.isNumeric(tempVal)) {
            this.timeTypValue = 'Year';
            this.yearTypeValue = dateRangeLabel.split('is in ')[1];
          } else {
            this.timeTypValue = 'Month';

            const selectedMonth = this.monthType.find((month) => month.viewValue === dateRangeLabel.split('is in ')[1]);
            this.monthTypeValue = selectedMonth?.value || '';
          }
          break;
        case dateRangeLabel.indexOf('is month to date') > -1:
          this.dateRangeValue = 'is month to date';
          break;
      }

      this.changeDate();
    }

    this.sharedService.userTimeZoneObsv$.subscribe(() => this.changeDate());
    this.changeDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['dateFilterLabel'].firstChange) {
      this.parseDateFilterLabel();
      this.changeDate();
    }
    if (changes['clear'] && !changes['clear'].firstChange) {
      this.dateFilterLabel = this.initDateFilterLabel;
      this.parseDateFilterLabel();
      this.changeDate();
    }
  }

  parseDateFilterLabel() {
    let label = this.dateFilterLabel;
    if (label.search('is in the last') !== -1) {
      this.dateRangeValue = 'is in the last';
      label = label.replace('is in the last ', '').trim();
      this.timeUnit.forEach((item) => {
        if (label.search(item.value) !== -1) {
          this.timeUnitValue = item.value;
          label = label.replace(` ${item.value}`, '');
          this.numValue = label.trim();
          return;
        }
      });
      return;
    }
    if (label.search('is in range') !== -1) {
      this.dateRangeValue = 'is in range';
      label = label.replace('is in range ', '').trim();
      this.dateRangeStart = new Date(label.split('-')[0].trim());
      this.dateRangeEnd = new Date(label.split('-')[1].trim());
      this.showNumInput = false;
      this.showRangeCalendar = true;
      this.showCalendar = false;
      this.showTimeSelect = false;
      this.showMonthSelect = false;
      this.showYearSelect = false;
      return;
    }
    if (label.search('is on or after') !== -1) {
      this.dateRangeValue = 'is on or after';
      this.showTimeSelect = false;
      this.showNumInput = false;
      this.showCalendar = true;
      this.showRangeCalendar = false;
      this.showMonthSelect = false;
      this.showYearSelect = false;
      label = label.replace('is on or after', '').trim();
      this.dateValue = new Date(label);
      return;
    }
    if (label.search('is month to date') !== -1) {
      this.dateRangeValue = 'is month to date';
      label = label.replace('is month to date', '').trim();
      this.showTimeSelect = false;
      this.showNumInput = false;
      this.showCalendar = false;
      this.showRangeCalendar = false;
      this.showMonthSelect = false;
      this.showYearSelect = false;
      return;
    }
    if (label.search('is on') !== -1 || label.search('is in') !== -1) {
      this.dateRangeValue = 'is on';
      this.showTimeSelect = true;
      label = label.replace('is on ', '').trim();
      label = label.replace('is in ', '').trim();
      if (label.search('/') !== -1) {
        this.timeTypValue = 'Day';
        this.showNumInput = false;
        this.showCalendar = true;
        this.showRangeCalendar = false;
        this.showMonthSelect = false;
        this.showYearSelect = false;
        this.dateValue = new Date(label);
      } else if (isNaN(parseInt(label))) {
        this.timeTypValue = 'Month';
        this.showNumInput = false;
        this.showCalendar = false;
        this.showRangeCalendar = false;
        this.showMonthSelect = true;
        this.showYearSelect = false;
        const foundMonth = this.monthType.find((item) => item.viewValue === label);
        this.monthTypeValue = foundMonth!.value;
      } else {
        this.timeTypValue = 'Year';
        this.showNumInput = false;
        this.showCalendar = false;
        this.showRangeCalendar = false;
        this.showMonthSelect = false;
        this.showYearSelect = true;
        this.yearTypeValue = label;
      }
    }
  }
  // set start_time & end_time
  setTime() {
    let new_startTime: Date, new_endTime: Date;
    switch (this.dateRangeValue) {
      case 'is in the last':
        this.dateFilterLabel = `${this.dateRangeValue} ${this.numValue} ${this.timeUnitValue}`;
        this.showNumInput = true;
        this.showRangeCalendar = false;
        this.showTimeSelect = false;
        this.showCalendar = false;
        this.showMonthSelect = false;
        this.showYearSelect = false;
        let duration: Duration = {};
        duration[this.timeUnitValue] = parseInt(this.numValue);
        switch (this.timeUnitValue) {
          case 'days':
            new_endTime = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            new_endTime = zonedTimeToUtc(new_endTime, this.sharedService.currentTimeZone);
            break;
          case 'weeks':
            new_endTime = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            // set day to Monday of current week
            new_endTime = setDay(new_endTime, 1);
            new_endTime = zonedTimeToUtc(new_endTime, this.sharedService.currentTimeZone);
            break;
          case 'months':
            new_endTime = set(new Date(), { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            new_endTime = zonedTimeToUtc(new_endTime, this.sharedService.currentTimeZone);
            break;
          case 'quarters':
            new_endTime = set(new Date(), { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            new_endTime = zonedTimeToUtc(new_endTime, this.sharedService.currentTimeZone);
            this.start_time = subQuarters(new_endTime, parseInt(this.numValue));
            break;
          case 'years':
            new_endTime = set(new Date(), { month: 0, date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
            new_endTime = zonedTimeToUtc(new_endTime, this.sharedService.currentTimeZone);
            break;
          default:
            // do not modify "new_endTime" for seconds, minutes, hours (entire current timestamp will be considered for end_time)
            new_endTime = new Date();
            break;
        }
        this.end_time = new_endTime;
        if (this.timeUnitValue !== 'quarters') this.start_time = sub(new_endTime, duration);
        break;
      case 'is on or after':
        this.dateFilterLabel = `${this.dateRangeValue} ${this.dateValue.getMonth() + 1}/${this.dateValue.getDate()}/${this.dateValue.getFullYear()}`;
        this.showTimeSelect = false;
        this.showNumInput = false;
        this.showCalendar = true;
        this.showRangeCalendar = false;
        this.showMonthSelect = false;
        this.showYearSelect = false;
        new_startTime = this.dateValue;
        this.start_time = zonedTimeToUtc(new_startTime, this.sharedService.currentTimeZone);
        this.end_time = new Date();
        break;
      case 'is on':
        this.showTimeSelect = true;
        switch (this.timeTypValue) {
          case 'Day':
            this.dateFilterLabel = `${this.dateRangeValue} ${this.dateValue.getMonth() + 1}/${this.dateValue.getDate()}/${this.dateValue.getFullYear()}`;
            this.showNumInput = false;
            this.showCalendar = true;
            this.showRangeCalendar = false;
            this.showMonthSelect = false;
            this.showYearSelect = false;
            new_startTime = this.dateValue;
            new_endTime = add(this.dateValue, { days: 1 });
            this.start_time = zonedTimeToUtc(new_startTime, this.sharedService.currentTimeZone);
            this.end_time = zonedTimeToUtc(new_endTime, this.sharedService.currentTimeZone);
            break;
          case 'Month':
            const selectedMonth = this.monthType.find((month) => month.value === this.monthTypeValue);
            this.dateFilterLabel = `is in ${selectedMonth?.viewValue}`;
            this.showNumInput = false;
            this.showCalendar = false;
            this.showRangeCalendar = false;
            this.showMonthSelect = true;
            this.showYearSelect = false;
            const currentYear = getYear(new Date());
            new_startTime = parseISO(`${currentYear}-${this.monthTypeValue}-01`);
            new_endTime = add(new_startTime, { months: 1 });
            this.start_time = zonedTimeToUtc(new_startTime, this.sharedService.currentTimeZone);
            this.end_time = zonedTimeToUtc(new_endTime, this.sharedService.currentTimeZone);
            break;
          case 'Year':
            this.dateFilterLabel = `is in ${this.yearTypeValue}`;
            this.showNumInput = false;
            this.showCalendar = false;
            this.showRangeCalendar = false;
            this.showMonthSelect = false;
            this.showYearSelect = true;
            new_startTime = parseISO(`${this.yearTypeValue}-01-01`);
            new_endTime = parseISO(`${parseInt(this.yearTypeValue) + 1}-01-01`);
            this.start_time = zonedTimeToUtc(new_startTime, this.sharedService.currentTimeZone);
            this.end_time = zonedTimeToUtc(new_endTime, this.sharedService.currentTimeZone);
            break;
        }
        break;
      case 'is in range':
        this.dateFilterLabel = `${this.dateRangeValue} ${this.dateRangeStart?.getMonth() + 1}/${this.dateRangeStart?.getDate()}/${this.dateRangeStart?.getFullYear()} - ${
          (this.dateRangeEnd?.getMonth() || 0) + 1
        }/${this.dateRangeEnd?.getDate() || this.dateRangeStart?.getDate()}/${this.dateRangeEnd?.getFullYear() || this.dateRangeStart?.getFullYear()}`;
        this.showNumInput = false;
        this.showRangeCalendar = true;
        this.showCalendar = false;
        this.showTimeSelect = false;
        this.showMonthSelect = false;
        this.showYearSelect = false;
        const zoned_dateStart = zonedTimeToUtc(this.dateRangeStart, this.sharedService.currentTimeZone);
        const zoned_dateEnd = zonedTimeToUtc(this.dateRangeEnd, this.sharedService.currentTimeZone);
        this.start_time = zoned_dateStart;
        this.end_time = zoned_dateEnd || this.start_time;
        this.end_time = add(this.end_time, { days: 1 });
        break;
      case 'is month to date':
        this.dateFilterLabel = 'is month to date';
        this.showNumInput = false;
        this.showRangeCalendar = false;
        this.showCalendar = false;
        this.showTimeSelect = false;
        this.showMonthSelect = false;
        this.showYearSelect = false;
        new_startTime = set(new Date(), { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
        this.start_time = zonedTimeToUtc(new_startTime, this.sharedService.currentTimeZone);
        this.end_time = new Date();
        break;
    }
    Object.assign(this.newDateRange, {
      dateRangeValue: this.dateRangeValue,
      numValue: parseInt(this.numValue),
      timeUnitValue: this.timeUnitValue,
    });
    this.dateFilterLabel = `${this.dateFilterLabel}`;
  }

  numValueChange() {
    if (!isNaN(parseInt(this.numValue))) {
      this.changeDate();
    }
  }

  isNumeric(str: any) {
    return /^-?\d+$/.test(str);
  }

  changeDate() {
    this.setTime();
    this.dateChangeEvent.emit([this.dateFilterLabel, this.start_time, this.end_time, this.newDateRange]);
  }

}
