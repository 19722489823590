import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  loadGraphData = new BehaviorSubject<any>({});


  constructor() { }
}
