/* eslint-disable */
export const categories = [
    {
        id      : 'b899ec30-b85a-40ab-bb1f-18a596d5c6de',
        parentId: null,
        name    : 'Mens',
        slug    : 'mens'
    },
    {
        id      : '07986d93-d4eb-4de1-9448-2538407f7254',
        parentId: null,
        name    : 'Ladies',
        slug    : 'ladies'
    },
    {
        id      : 'ad12aa94-3863-47f8-acab-a638ef02a3e9',
        parentId: null,
        name    : 'Unisex',
        slug    : 'unisex'
    }
];
export const brands = [
    {
        id  : 'e1789f32-9475-43e7-9256-451d2e3a2282',
        name: 'Benton',
        slug: 'benton'
    },
    {
        id  : '61d52c2a-8947-4a2c-8c35-f36baef45b96',
        name: 'Capmia',
        slug: 'capmia'
    },
    {
        id  : 'f9987124-7ada-4b93-bef7-35280b3ddbd7',
        name: 'Lara',
        slug: 'lara'
    },
    {
        id  : '5913ee46-a497-41db-a118-ee506011529f',
        name: 'Premera',
        slug: 'premera'
    },
    {
        id  : '2c4d98d8-f334-4125-9596-862515f5526b',
        name: 'Zeon',
        slug: 'zeon'
    }
];
export const tags = [
    {
        id   : '167190fa-51b4-45fc-a742-8ce1b33d24ea',
        title: 'mens'
    },
    {
        id   : '3baea410-a7d6-4916-b79a-bdce50c37f95',
        title: 'ladies'
    },
    {
        id   : '8ec8f60d-552f-4216-9f11-462b95b1d306',
        title: 'unisex'
    },
    {
        id   : '8837b93f-388b-43cc-851d-4ca8f23f3a61',
        title: '44mm'
    },
    {
        id   : '8f868ddb-d4a2-461d-bc3b-d7c8668687c3',
        title: '40mm'
    },
    {
        id   : '2300ac48-f268-466a-b765-8b878b6e14a7',
        title: '5 ATM'
    },
    {
        id   : '0b11b742-3125-4d75-9a6f-84af7fde1969',
        title: '10 ATM'
    },
    {
        id   : '0fc39efd-f640-41f8-95a5-3f1d749df200',
        title: 'automatic'
    },
    {
        id   : '7d6dd47e-7472-4f8b-93d4-46c114c44533',
        title: 'chronograph'
    },
    {
        id   : 'b1286f3a-e2d0-4237-882b-f0efc0819ec3',
        title: 'watch'
    }
];
export const vendors = [
   {
        id  : '987dd10a-43b1-49f9-bfd9-05bb2dbc7029',
        name: 'Evel',
        slug: 'evel'
    },
    {
        id  : '998b0c07-abfd-4ba3-8de1-7563ef3c4d57',
        name: 'Mivon',
        slug: 'mivon'
    },
    {
        id  : '05ebb527-d733-46a9-acfb-a4e4ec960024',
        name: 'Neogen',
        slug: 'neogen'
    }
];
export const products =[
    {
        "ouid": 4,
        "groupName": "BMW-a",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 5,
        "groupName": "BMW-b",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 6,
        "groupName": "BMW-c",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 7,
        "groupName": "Manchester AutoZone",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 8,
        "groupName": "London Tyres",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 9,
        "groupName": "Birmingham Motors",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 15,
        "groupName": "South Dakota",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 16,
        "groupName": "South California",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 17,
        "groupName": "Call center",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 18,
        "groupName": "Brazil",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 19,
        "groupName": "France",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 20,
        "groupName": "France1",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 21,
        "groupName": "France12",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 22,
        "groupName": "Spain",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 23,
        "groupName": "Buggati",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": "23648t2",
        "city": "Hawkins",
        "zipcode": "Texas",
        "state": "23742",
        "externalId": "324234"
    },
    {
        "ouid": 24,
        "groupName": "Dutch",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 25,
        "groupName": "Norway",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 26,
        "groupName": "Switzerland",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 27,
        "groupName": "Portugal",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 28,
        "groupName": "Portugal1",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 29,
        "groupName": "Portugal12",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 30,
        "groupName": "Buggati",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": "23648t2",
        "city": "Hawkins",
        "zipcode": "Texas",
        "state": "23742",
        "externalId": "324234"
    },
    {
        "ouid": 31,
        "groupName": "Portugal1243",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 32,
        "groupName": "Portugal12423",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 33,
        "groupName": "Ukraine",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 37,
        "groupName": "asdasdasaaaa",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "DealerShip",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": null,
        "topId": null,
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "qe1213"
    },
    {
        "ouid": 38,
        "groupName": "adeel1232",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "DealerShip",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": null,
        "topId": null,
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "qe1213"
    },
    {
        "ouid": 39,
        "groupName": "Ukraine-1",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 40,
        "groupName": "Ukraine-2",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 41,
        "groupName": "German Embassy Call center",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    },
    {
        "ouid": 42,
        "groupName": "Jazz Calling",
        "parentId": "BMW-1",
        "groupStatus": null,
        "industry": "Auto",
        "totalUsers": "0",
        "activeUsers": "0",
        "customerId": "null",
        "topId": "null",
        "phone": null,
        "city": null,
        "zipcode": null,
        "state": null,
        "externalId": "23648234"
    }
];
export const users = [
    {
        "ouid": 9,
        "email": "rickmortien3@gmail.com",
        "password": "$2a$12$ErdmZUdbxwLyHN1/mrD/6uVDxeu5gHueeW10ODX2oXN4WUm.3v2IC",
        "firstName": "Dave",
        "lastName": "Herman",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "admin",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": "ty34u@yopmail.com"
    },
    {
        "ouid": 10,
        "email": "rickmortien453@gmail.com",
        "password": "$2a$12$0QLSSJAlOPTvCr5nuO/PCeALAjbHLpyvjCUJ6K/sZG39sWkjW2p..",
        "firstName": "Dave",
        "lastName": "Herman",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "admin",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": "ty34u@yopmail.com"
    },
    {
        "ouid": 33,
        "email": "mike513@gmail.com",
        "password": "$2a$12$XoJgKWdjs.I6.pAu40C8mOHPP/7JNo7zpNs6VK9VH1MxtArFeNZZu",
        "firstName": "Mike",
        "lastName": "stanford",
        "parentId": null,
        "status": "inactive",
        "scoringStatus": "active",
        "role": "standard",
        "externalId": null,
        "agentId": null,
        "group": "BMW-1",
        "customerId": "ty34u@yopmail.com"
    },
    {
        "ouid": 40,
        "email": "lucas3@gmail.com",
        "password": "$2a$12$E4p1kBth593FxCGRclOjBeV8sBof0od90uq2qB8VCp6x2AG6XKTZy",
        "firstName": "Lucas",
        "lastName": "Wheeler",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "admin",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": "ty34u@yopmail.com"
    },
    {
        "ouid": 41,
        "email": "erica23@gmail.com",
        "password": "$2a$12$pUl3X0xAEm.g.9naoY/O0eTVTa3j88zF4.wFninB3OKalaPkAy0oe",
        "firstName": "Erica",
        "lastName": "Sinclair",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "admin",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": "ty34u@yopmail.com"
    },
    {
        "ouid": 45,
        "email": "erica823@gmail.com",
        "password": "$2a$12$sM8GdJNIDXtS0UlRNYi7e.7VG7RnDzOQrlJv3tt/RAWSm2Xej5MyS",
        "firstName": "Erica",
        "lastName": "Sinclair",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "admin",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": null
    },
    {
        "ouid": 47,
        "email": "erica83231@gmail.com",
        "password": "$2a$12$rdkuciNLHEMvC7tWI/NHUeblA8rDx/WB0298hSld9/6x5YxwKrY5a",
        "firstName": "Erica",
        "lastName": "Sinclair",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "standard",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": null
    },
    {
        "ouid": 48,
        "email": "asdas@asdas.comsds",
        "password": "$2a$12$I41I0.AztVAJViuSR8EhHeHkt22G5nX1j/fwBU/cb7ZlO/rCcmWoG",
        "firstName": "adeel1",
        "lastName": "asdasd",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "Readonly",
        "externalId": "qe1213",
        "agentId": null,
        "group": "BMW-1",
        "customerId": null
    },
    {
        "ouid": 50,
        "email": "dinasour1@gmail.com",
        "password": "$2a$12$6zSkv/97Y5znc23d041w1eUF5jg5x0OOI2JX4uA2.jWizha8nk30O",
        "firstName": "Ross",
        "lastName": "Geller",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "standard",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": null
    },
    {
        "ouid": 51,
        "email": "mon11@gmail.com",
        "password": "$2a$12$7MO1UkTMZ5BrKYIrWuBtquUsDenevQEuwyI5dIjUiGwpYudDcWkA2",
        "firstName": "Monica",
        "lastName": "Bing",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "standard",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": null
    },
    {
        "ouid": 52,
        "email": "Phoebe12@gmail.com",
        "password": "$2a$12$KZFfM5DXfCTadaGIbnKmJ.8nEEAvS87khcinZ/ZgwrdonnWFGXuDq",
        "firstName": "Phoebe",
        "lastName": "Hannigan",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "admin",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": null
    },
    {
        "ouid": 53,
        "email": "joey2@gmail.com",
        "password": "$2a$12$ONbLbmcNoHcje/xm1TbyMeX7ja.STVqVrBDX9fwff958kxf8.bMI6",
        "firstName": "Joey",
        "lastName": "Harrington",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "admin",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": null
    },
    {
        "ouid": 54,
        "email": "rachel23@gmail.com",
        "password": "$2a$12$4tIhJ5PQlWYnpAVSdTnflOrsbqGvS7bTDwPI9LJ72voxtdvrHhIkm",
        "firstName": "Rachel",
        "lastName": "Green",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "admin",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": null
    },
    {
        "ouid": 55,
        "email": "wither@gmail.com",
        "password": "$2a$12$Bgo8Yf.h.u3qun3WvQg8c.t203ZJ5uyTwoSOt93bjePTgHZlbrh3.",
        "firstName": "Barbie",
        "lastName": "Witherspoon",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "admin",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": null
    },
    {
        "ouid": 56,
        "email": "tom4@gmail.com",
        "password": "$2a$12$a33iPouoAAYfXGVd9eeWpOGJJXPHC9wzRtjT82qdnn7r8IusB.9Em",
        "firstName": "Tom",
        "lastName": "Hardy",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "admin",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": null
    },
    {
        "ouid": 57,
        "email": "scott3@gmail.com",
        "password": "$2a$12$S.OGHy2F61qtGUkx.MsqOuTin8Y/SQQMqE9Xr///WsRtPX0yz7XOq",
        "firstName": "Scott",
        "lastName": "Zeniger",
        "parentId": null,
        "status": "active",
        "scoringStatus": "active",
        "role": "admin",
        "externalId": "273462",
        "agentId": null,
        "group": "BMW-1",
        "customerId": null
    }
];
