<div class="flex flex-col flex-auto p-2 md:p-4">

        <div class="flex items-center justify-between">
            <div class="text-2xl font-semibold">Change Password</div>
            <button
                matDialogClose
                mat-icon-button>
                <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>

</div>

<div class="modal-body">
    <form [formGroup]="ChangePassword">
        <mat-form-field class="w-full">
            <mat-label>Current password</mat-label>
            <input
                id="password"
                matInput
                (input)="ChangeCurrr = false"
                type="password"
                [formControlName]="'Oldpassword'"
                #passwordField
                placeholder="Please enter your password"
            />
            <button
                mat-icon-button
                type="button"
                (click)="
                    passwordField.type === 'password'
                        ? (passwordField.type = 'text')
                        : (passwordField.type = 'password')
                "
                matSuffix
            >
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordField.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye-off'"
                ></mat-icon>
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordField.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye'"
                ></mat-icon>
            </button>
            <mat-error
                *ngIf="
                    ChangePassword.get('Oldpassword').hasError('required')
                "
            >
                Password is required
            </mat-error>
        </mat-form-field>
        <p
            *ngIf="ChangeCurrr"
            style="
                font-size: 12px;
                font-weight: 500;
                margin-top: 0px;
                color: #dc2626;
            "
        >
            Please enter correct password
        </p>
        <mat-form-field class="w-full">
            <mat-label>New password</mat-label>
            <input
                id="password"
                matInput
                type="password"
                #passwordFieldone
                [formControlName]="'Newpassword'"
                (keyup)="valuechange($event)"
                placeholder="Please enter your password"
            />
            <button
                mat-icon-button
                type="button"
                (click)="
                    passwordFieldone.type === 'password'
                        ? (passwordFieldone.type = 'text')
                        : (passwordFieldone.type = 'password')
                "
                matSuffix
            >
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordFieldone.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye-off'"
                ></mat-icon>
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordFieldone.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye'"
                ></mat-icon>
            </button>
            <mat-error
                *ngIf="
                    ChangePassword.get('Newpassword').hasError('required')
                "
            >
                Password is required
            </mat-error>
        </mat-form-field>
        <p
            *ngIf="passwordLengthError"
            style="
                font-size: 12px;
                font-weight: 500;
                margin-top: 0px;
                color: #b90101;
            "
        >
            Password must contain min 8 characters(one uppercase, one
            lowercase, one special character)
        </p>
        <!-- Password confirm field -->
        <mat-form-field class="w-full">
            <mat-label>Retype password</mat-label>
            <input
                id="password-confirm"
                matInput
                type="password"
                [formControlName]="'passwordConfirm'"
                #passwordConfirmField
                placeholder="Please enter your password"
            />
            <button
                mat-icon-button
                type="button"
                (click)="
                    passwordConfirmField.type === 'password'
                        ? (passwordConfirmField.type = 'text')
                        : (passwordConfirmField.type = 'password')
                "
                matSuffix
            >
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordConfirmField.type === 'password'"
                    [svgIcon]="'heroicons_solid:eye-off'"
                ></mat-icon>
                <mat-icon
                    class="icon-size-5"
                    *ngIf="passwordConfirmField.type === 'text'"
                    [svgIcon]="'heroicons_solid:eye'"
                ></mat-icon>
            </button>
            <mat-error
                *ngIf="
                    ChangePassword.get('passwordConfirm').hasError(
                        'required'
                    )
                "
            >
                Password confirmation is required
            </mat-error>
            <mat-error
                *ngIf="
                    ChangePassword.get('passwordConfirm').hasError(
                        'mustMatch'
                    )
                "
            >
                Passwords must match
            </mat-error>
        </mat-form-field>
    </form>
    <div class="row">
        <button
            class="fuse-mat-button-large w-6/12 mt-6 ml-auto mr-auto"
            mat-flat-button
            (click)="OnChangePassword()"
            [disabled]="
                ChangePassword.get('Oldpassword').hasError('required') ||
             
                ChangePassword.get('passwordConfirm').hasError(
                    'required'
                ) ||
                ChangePassword.get('passwordConfirm').hasError('mustMatch')
            "
            [color]="'primary'"
        >
            <span> Save </span>
        </button>
    </div>
</div>