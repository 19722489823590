<div class="flex items-baseline pt-4 mt-1 px-5 md:px-8">
    <p class="mr-2" style="color: #1E293B; margin: 0;">Your location is here</p>
    <div class="breadCrumbs" (click)="openConfirmationDialog()">
        <div class="mr-1 flex items-center">
            <div class="flex items-center">
                <p class="fontcss">{{ TreeParent }}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                >
                    <path
                        _ngcontent-nok-c411=""
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.904029 1.02903C1.02607 0.90699 1.22393 0.90699 1.34597 1.02903L5.09597 4.77903C5.21801 4.90107 5.21801 5.09893 5.09597 5.22097L1.34597 8.97097C1.22393 9.09301 1.02607 9.09301 0.904029 8.97097C0.78199 8.84893 0.78199 8.65107 0.904029 8.52903L4.43306 5L0.904029 1.47097C0.78199 1.34893 0.78199 1.15107 0.904029 1.02903Z"
                        fill="#505780"
                    ></path>
                </svg>
            </div>
            <div
                class="flex items-center ml-2"  
                *ngIf="this.BreadGroup2 != undefined"
            >
                <p class="fontcss">{{ BreadGroup2 }}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                >
                    <path
                        _ngcontent-nok-c411=""
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.904029 1.02903C1.02607 0.90699 1.22393 0.90699 1.34597 1.02903L5.09597 4.77903C5.21801 4.90107 5.21801 5.09893 5.09597 5.22097L1.34597 8.97097C1.22393 9.09301 1.02607 9.09301 0.904029 8.97097C0.78199 8.84893 0.78199 8.65107 0.904029 8.52903L4.43306 5L0.904029 1.47097C0.78199 1.34893 0.78199 1.15107 0.904029 1.02903Z"
                        fill="#505780"
                    ></path>
                </svg>
            </div>
            <div
                class="flex items-center ml-2"
                *ngIf="this.BreadGroup3 != undefined"
            >
                <p class="fontcss">{{ BreadGroup3 }}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                >
                    <path
                        _ngcontent-nok-c411=""
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.904029 1.02903C1.02607 0.90699 1.22393 0.90699 1.34597 1.02903L5.09597 4.77903C5.21801 4.90107 5.21801 5.09893 5.09597 5.22097L1.34597 8.97097C1.22393 9.09301 1.02607 9.09301 0.904029 8.97097C0.78199 8.84893 0.78199 8.65107 0.904029 8.52903L4.43306 5L0.904029 1.47097C0.78199 1.34893 0.78199 1.15107 0.904029 1.02903Z"
                        fill="#505780"
                    ></path>
                </svg>
            </div>
            <div
                class="flex items-center ml-2"
                *ngIf="this.BreadGroup4 != undefined"
            >
                <p class="fontcss">{{ BreadGroup4 }}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                >
                    <path
                        _ngcontent-nok-c411=""
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.904029 1.02903C1.02607 0.90699 1.22393 0.90699 1.34597 1.02903L5.09597 4.77903C5.21801 4.90107 5.21801 5.09893 5.09597 5.22097L1.34597 8.97097C1.22393 9.09301 1.02607 9.09301 0.904029 8.97097C0.78199 8.84893 0.78199 8.65107 0.904029 8.52903L4.43306 5L0.904029 1.47097C0.78199 1.34893 0.78199 1.15107 0.904029 1.02903Z"
                        fill="#505780"
                    ></path>
                </svg>
            </div>
            <div
                class="flex items-center ml-2"
                *ngIf="this.BreadGroup5 != undefined"
            >
                <p class="fontcss">{{ BreadGroup5 }}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                >
                    <path
                        _ngcontent-nok-c411=""
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.904029 1.02903C1.02607 0.90699 1.22393 0.90699 1.34597 1.02903L5.09597 4.77903C5.21801 4.90107 5.21801 5.09893 5.09597 5.22097L1.34597 8.97097C1.22393 9.09301 1.02607 9.09301 0.904029 8.97097C0.78199 8.84893 0.78199 8.65107 0.904029 8.52903L4.43306 5L0.904029 1.47097C0.78199 1.34893 0.78199 1.15107 0.904029 1.02903Z"
                        fill="#505780"
                    ></path>
                </svg>
            </div>
        </div>
    </div>
</div>
