import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GroupFlatNode } from '../groups-tree/models/GroupFlatNode';
import { FILTER_DETAILS } from './config/filter_details';
import { Filter } from './model/filter.model';
import { MatDialog } from '@angular/material/dialog';
import { REPORT_FILTERS } from './config/report_filters';
import { GroupsTreeComponent } from '../groups-tree/groups-tree.component';
import _ from 'underscore';
import { SharedService } from '../shared.service';
import { FormBuilder, FormGroup } from '@angular/forms';

interface FilterData extends Filter {
  data: any;
}

@Component({
  selector: 'app-shared-filter',
  templateUrl: './shared-filter.component.html',
  styleUrls: ['./shared-filter.component.scss']
})
export class SharedFilterComponent implements OnInit {
  @Input() reportName: string;
  @Input('show_group') show_group_filter = false;
  @Output() filterChange = new EventEmitter<Filter[]>();
  @Output() refresh = new EventEmitter();
  @Output() dateValueChange = new EventEmitter<any>();
  @Output() groupFilterChange = new EventEmitter<any>();

  defaultDateLabel: string;
  filter_data_loaded = true;
  showDropDown: boolean = false;
  filter_list: string[];
  selected_filters: FilterData[] = [];
  set_filters: Filter[] = [];
  selected_groups_filter: GroupFlatNode[] = [];
  storage_key = '';


  constructor(private sharedService: SharedService, private dialog: MatDialog, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.storage_key = this.reportName + '_savedFilters';
    this.defaultDateLabel = REPORT_FILTERS[this.reportName].defaultDateLabel;
    this.filter_list = REPORT_FILTERS[this.reportName].remaining_filters;
    this.sharedService.clearFiltersSubj.subscribe((status) => {
      this.clearAllFilters();
      if (status) this.onRefresh();
    });
    let groups = JSON.parse(localStorage.getItem('groups'));
    this.selected_groups_filter = groups ? groups  :  [];
    this.loadSavedFilters();
  }

  loadSavedFilters() {
    const saved_filters = sessionStorage.getItem(this.storage_key);
    if (saved_filters) {
      const saved_filters_parsed = JSON.parse(saved_filters);
      if (saved_filters_parsed['date_data']) this.defaultDateLabel = saved_filters_parsed['date_data'][0];
      const saved_data = saved_filters_parsed['filter_data'];
      if (saved_data)
        for (let filter in saved_data) {
          if (saved_data[filter].filter_items && saved_data[filter].filter_items.length) {
            this.selected_filters.push({ filter_name: filter, filter_items: saved_data[filter].filter_items, data: saved_data[filter].data });
            this.filterItemChange({ filter_name: filter, filter_items: saved_data[filter].filter_items });
          }
        }
      const group_data = saved_filters_parsed['group_selection'];
      if (group_data && group_data.length) this.selected_groups_filter = group_data;
    }
  }

  selectFilter(filter: string) {
    if (!this.isFilterSelected(filter)) {
      // fetch filter values from API for dynamic filters
      if (FILTER_DETAILS[filter] && FILTER_DETAILS[filter].fetch_data) {
        this.filter_data_loaded = false;
        const payload = {
          TableName : 'trackingNumbers',
          id : 158,
          obj : [
              {fieldName: filter, value: 'Single' , filterType: 'is'}
            ]
        };
        this.sharedService.getFilterData(payload).subscribe((resp) => {
          const filter_data = resp.map((el) => el[filter]);
          this.selected_filters.push({ filter_name: filter, filter_items: [{ action: 'is', value: '' }], data: filter_data });
          this.filter_data_loaded = true;
          this.saveFilterData(filter, filter_data);
        });
      }
      // get filter values from config file for static-value fitlers
      else if (FILTER_DETAILS[filter]) {
        this.selected_filters.push({ filter_name: filter, filter_items: [{ action: 'is', value: '' }], data: FILTER_DETAILS[filter].values });
        this.saveFilterData(filter, FILTER_DETAILS[filter].values);
      } else {
        this.selected_filters.push({ filter_name: filter, filter_items: [{ action: 'is', value: '' }], data: [] });
        this.saveFilterData(filter, FILTER_DETAILS[filter].values);
      }
    } else {
      this.removeFilter(filter);
      this.filterChange.emit(this.set_filters);
    }
    this.showDropDown = false;
  }

  saveFilterData(name: string, data: any) {
    const saved_filters = sessionStorage.getItem(this.storage_key);
    if (saved_filters) {
      const saved_filters_parsed = JSON.parse(saved_filters);
      saved_filters_parsed['filter_data'] ??=  {};
      saved_filters_parsed['filter_data'][`${name}`] ??= {};
      saved_filters_parsed['filter_data'][`${name}`]['data'] ??= data;
      sessionStorage.setItem(this.storage_key, JSON.stringify(saved_filters_parsed));
    }
  }

  isFilterSelected(filter: string) {
    return this.selected_filters.map((el) => el.filter_name).includes(filter);
  }

  filterItemChange(filter: Filter) {
    const saved_filters = sessionStorage.getItem(this.storage_key);
    if (saved_filters) { // save current filter status in sessionstorage to be reused later
      const saved_filters_parsed = JSON.parse(saved_filters);
      saved_filters_parsed['filter_data'] ??=  {};
      saved_filters_parsed['filter_data'][`${filter.filter_name}`] ??= {};
      saved_filters_parsed['filter_data'][`${filter.filter_name}`]['filter_name'] = filter.filter_name;
      saved_filters_parsed['filter_data'][`${filter.filter_name}`]['filter_items'] = filter.filter_items;
      sessionStorage.setItem(this.storage_key, JSON.stringify(saved_filters_parsed));
    }
    this.set_filters = this.set_filters.filter((el) => el.filter_name !== filter.filter_name);
    this.set_filters.push(filter);
    if (!filter.filter_items.length) this.removeFilter(filter.filter_name);
    this.filterChange.emit(this.set_filters);
  }

  removeFilter(filter: string) {
    this.selected_filters = this.selected_filters.filter((el) => el.filter_name !== filter);
    this.set_filters = this.set_filters.filter((el) => el.filter_name !== filter);
    this.filterChange.emit(this.set_filters);
  }

  clearAllFilters() {
    this.selected_filters = [];
    this.set_filters = [];
    this.filterChange.emit(this.set_filters);
    const saved_filters = sessionStorage.getItem(this.storage_key);
    if (saved_filters) {
      const saved_filters_parsed = JSON.parse(saved_filters);
      saved_filters_parsed['filter_data'] = {};
      sessionStorage.setItem(this.storage_key, JSON.stringify(saved_filters_parsed));
    }
  }

  onRefresh() {
    this.refresh.emit();
  }

  dateChangeEvent(event: any) {
    this.dateValueChange.emit(event);
  }

  onDateFilterChange(event: any, filter_name: string) {
    this.set_filters = this.set_filters.filter((el) => el.filter_name !== filter_name);
    const new_date_filter: Filter = {
      filter_name,
      filter_items: [
        { action: '', value: event[0] },
        { action: 'start_time', value: event[1] },
        { action: 'end_time', value: event[2] },
        { action: 'dateObj', value: JSON.stringify(event[3]) },
      ],
    };
    this.set_filters.push(new_date_filter);
    this.filterChange.emit(this.set_filters);
  }

  openGroupsFilterModal() {
    const dialogRef = this.dialog.open(GroupsTreeComponent, {
      panelClass: 'group-tree-modal',
      data: {
        first_open: true,
        single_select: false,
        selected_nodes: this.selected_groups_filter,
      },
      minHeight: '90vh',
      height: '90vh',
      width: '40vw',
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.selected_groups_filter = data.selected_nodes;
        let group_filter = _.pluck(this.selected_groups_filter, 'id');
        this.groupFilterChange.emit(group_filter);
        // save current group selection in sessionstorage to be reused later
        const saved_filters = sessionStorage.getItem(this.storage_key);
        if (saved_filters) {
          const saved_filters_parsed = JSON.parse(saved_filters);
          saved_filters_parsed['group_selection'] = data.selected_nodes;
          sessionStorage.setItem(this.storage_key, JSON.stringify(saved_filters_parsed));
        }
      }
    });
  }
  getGroupFilterLabel() {
    const num = this.selected_groups_filter ? this.selected_groups_filter.length : 'All';
    if(num=== 1) return `${num} group selected`;
    return `${num} groups selected`;
  }
  checkIfRemovable(filter_name: string) {
    const found_initial_filter = REPORT_FILTERS[this.reportName].initial_filters.find((el) => el.filter_name === filter_name);
    return found_initial_filter ? false : true;
  }
}
