import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DniService } from 'app/shared/shared-Services/dni.service';

@Component({
    selector: 'app-dni-contact-us',
    templateUrl: './dni-contact-us.component.html',
    styleUrls: ['./dni-contact-us.component.scss'],
})
export class DniContactUsComponent implements OnInit {
    ContactNumber: [];
    emptyData = '';

    constructor(
        private _dniService: DniService,
        public route: ActivatedRoute
    ) {
      const id = this.route.snapshot.paramMap.get('id')!;

        this._dniService.getDniDataPost(id).subscribe((res: any) => {
          console.log(res)
          if(res.status == true){
            if(res.body.length !== 0){
              this.ContactNumber = res.body[0].number;
            } else {
              this.emptyData = 'Number not found'
            }
          }
            
        });
    }

    ngOnInit(): void { 
    }
}
