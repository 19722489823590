<div class="filter-container">
    <!-- filter action buttons -->
    <div class="flex py-2 px-5 md:px-8" (clickOutside)="showDropDown = false;">
      <button class="w-32"
      mat-flat-button
      [color]="'primary'" (click)="showDropDown = !showDropDown">
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        Add
      </button>
      <button class="sm:inline-flex ml-3 w-32"
      mat-stroked-button
      [color]="'primary'" (click)="onRefresh()">
        <mat-icon class="hidden md:block icon-size-5"
        svgIcon="heroicons_outline:refresh"></mat-icon>
        Refresh
      </button>
      <button  class="px-3 ml-3 w-32"
      mat-button
      [color]="'primary'" (click)="clearAllFilters()">Clear all</button>
    </div>
    <!-- fitler-dropdown -->
    <!-- <div *ngIf="showDropDown" class="flex flex-col max-w-40 w-full p-3 filter-list" style="position: absolute; z-index: 9999; left: 30px; top: 57px">
      <div *ngFor="let filter of filter_list" (click)="selectFilter(filter)">
        {{ filter }}
        <mat-icon *ngIf="isFilterSelected(filter)" svgIcon="heroicons_outline:check"></mat-icon>
      </div>
    </div> -->

    <div>
        <div>
            <fuse-card
                *ngIf="showDropDown"
                class="flex flex-col max-w-40 w-full p-3 filter-list"
                style="background-color: #fff; position: absolute; z-index: 9999; left: 30px;"
            >
                <div class="flex items-center justify-between">
                    <!-- <div class="text-2xl font-semibold leading-tight">Filters</div> -->
                </div>
                <div class="flex flex-col">
                    <div class="flex flex-col">
                        <section
                            class="example-section"
                            *ngFor="let filter of filter_list"
                        >
                            <p class="mb-0 text-xs">
                                <mat-checkbox (click)="selectFilter(filter)"
                                    class="mat-primary"
                                    >{{filter}}</mat-checkbox
                                >
                            </p>
                          
                        </section>
                    </div>
                </div>
                <div class="flex items-center">
                    <button
                        class="pr-6"
                        mat-button
                        (click)="openBelowOptions()"
                        [color]="'primary'"
                    >
                        Apply
                    </button>
                </div>
            </fuse-card>
        </div>
    </div>


    <!-- filter-list -->
    <div [hidden]="!filter_data_loaded" class="filter-list">
      <app-date-filter
        *ngIf="defaultDateLabel !== 'none'"
        [reportName]="reportName"
        [dateFilterLabel]="defaultDateLabel"
        (dateChangeEvent)="dateChangeEvent($event)"
        [numValueInput]="1"
      ></app-date-filter>
      <button mat-button *ngIf="show_group_filter" (click)="openGroupsFilterModal()" class="bg-[#50d71e] filterLabel flat-button" >{{ getGroupFilterLabel() }}</button>
      <ng-container *ngFor="let filter of selected_filters">
        <ng-container [ngSwitch]="filter.filter_name.includes('Date')">
          <!-- normal text filters -->
          <ng-container *ngSwitchCase="false">
            <app-filter-item
              [reportName]="reportName"
              [filterName]="filter.filter_name"
              [initValue]="filter.filter_items"
              [filterData]="filter.id"
              (filterChange)="filterItemChange($event)"
            ></app-filter-item>
          </ng-container>
          <!-- date filters -->
          <ng-container *ngSwitchCase="true">
            <app-date-filter
              [filter_name]="filter.filter_name"
              [dateFilterLabel]="'is in the last 7 days'"
              (dateChangeEvent)="onDateFilterChange($event, filter.filter_name)"
              [numValueInput]="7"
              [clear]="false"
              isScoredDateFilter="yes"
            ></app-date-filter>
            <mat-icon class="cursor-pointer" svgIcon="heroicons_solid:x-circle" (click)="removeFilter(filter.filter_name)" *ngIf="checkIfRemovable(filter)"></mat-icon>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="!filter_data_loaded">
      <mat-spinner diameter="20"></mat-spinner>
    </ng-container>
  </div>