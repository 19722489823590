<div class="container mt-10">
    <h1 class="mb-5">DNI-Settings</h1>

    <div  *ngFor="let item of dniNames; let i = index">
        <button 
        class="fuse-mat-button-large mt-3"
        mat-flat-button
        [color]="'primary'"
        [routerLink]="['/dni-contact', item.id]"
        >
        <span > Campaign {{i + 1}}</span>
        
        </button>
    </div>
</div>


