import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DniService {
  numberId = new BehaviorSubject<any>({});
  constructor(private http: HttpClient) { }

  getDniData(){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('token', token);
    return this.http.get( 'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getDNIcampaigns');
  }

  getDniDataPost(id){
    // let  id  = this.numberId.value
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('token', token);
    return this.http.post( 'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getDNI', {id});
  }



}
