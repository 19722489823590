import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from './shared/shared.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatTreeModule } from '@angular/material/tree';
import { InventoryListComponent } from 'app/modules/admin/ecommerce/inventory/list/inventory.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { OnlyAlphabetsDirective } from './only-alphabets.directive';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { MatSlider, MatSliderModule } from '@angular/material/slider';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CdkTableModule } from '@angular/cdk/table';
import { CreateNewTrackingNumberComponent } from './modules/admin/Tracking-Numbers/create-new-tracking-number/create-new-tracking-number.component';
import { PhoneNumMaskDirective } from './modules/admin/Tracking-Numbers/create-new-tracking-number/phone-num-mask.directive';
// import { IntlInputPhoneModule } from 'intl-input-phone';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DniSettingsComponent } from './modules/admin/ui/dni-settings/dni-settings.component';
import { DniContactUsComponent } from './modules/admin/ui/dni-contact-us/dni-contact-us.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { MatSnackBarModule } from '@angular/material/snack-bar';
// import {RxReactiveDynamicFormsModule} from '@rxweb/reactive-dynamic-forms'

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

@NgModule({
    declarations: [AppComponent, OnlyAlphabetsDirective, CreateNewTrackingNumberComponent, PhoneNumMaskDirective, DniSettingsComponent, DniContactUsComponent],
    imports: [
        MatSnackBarModule,
        BrowserModule,
        CommonModule,
        SharedModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        NgOtpInputModule,
        Ng2TelInputModule,
        CdkTableModule,
        // IntlInputPhoneModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),

        MatTabsModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        CommonModule,
        MatSliderModule,
        MatToolbarModule,
        MatCheckboxModule,
        DragDropModule,
        NgxDaterangepickerMd.forRoot(),
        MatMenuModule,
        AmChartsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSortModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatTableModule,
        MatTableModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RxReactiveFormsModule
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule { }
