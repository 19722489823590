import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    private _data: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<any> {
        return this._data.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getData(): Observable<any> {
        return this._httpClient.get('api/dashboards/project').pipe(
            tap((response: any) => {
                this._data.next(response);
            })
        );
    }

    verfiyToken(token) {
        var headers_object = new HttpHeaders({ token: token });
        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient
            .post(
                'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/verify',
                {},
                httpOptions
            )
            .pipe(
                tap((response: any) => {
                    this._data.next(response);
                })
            );
    }

    getDashboardStats(startTime: any, endTime: any, groupIds?: any[]) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/dashboardStats',
            {
                startTime: startTime,
                endTime: endTime,
                groupIds: groupIds,
            },
            // {
            //     startTime: '1670590758',
            //     endTime: '1671005027',
            // },
            { headers }
        );
    }
}
