import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }


  getUsersList(data){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('token', token);
    return this.http.post('https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getUsersCheck', data, { headers });
  }

  getTnNumbers(){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('token', token);
    return this.http.get('https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getPhoneNumbers', { headers });
  }

}
