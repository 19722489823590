import { Component, OnInit } from '@angular/core';
import { DniService } from 'app/shared/shared-Services/dni.service';

@Component({
  selector: 'app-dni-settings',
  templateUrl: './dni-settings.component.html',
  styleUrls: ['./dni-settings.component.scss']
})
export class DniSettingsComponent implements OnInit {

  dniNames: any; 

  constructor(private _dniService: DniService) { }

  ngOnInit(): void {
    this._dniService.getDniData().subscribe((res: any) =>{
      this.dniNames = res.body
    })
  }

}
