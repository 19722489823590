import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterItem } from '../model/filterItem.model';
import { Filter } from '../model/filter.model';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { FILTER_DETAILS } from '../config/filter_details';
import { REPORT_FILTERS } from '../config/report_filters';


@Component({
  selector: 'app-filter-items',
  templateUrl: './filter-items.component.html',
  styleUrls: ['./filter-items.component.scss']
})
export class FilterItemsComponent implements OnInit, OnChanges {
  @Input() reportName: string;
  @Input() filterName: string;
  @Input() filterData: string[];
  @Input() initValue: FilterItem[];
  @Output() filterChange = new EventEmitter<Filter>();

  // reactive form
  filter: Filter;
  filterForm: FormGroup;
  filterType: string;
  label: string;
  filterActions: { value: string; viewValue: string }[];
  options: string[];
  filteredOptions$: Observable<string[]>[] = [];
  editable: boolean;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.options = [...this.filterData];
    if (FILTER_DETAILS[this.filterName].fetch_data && this.options.length && this.options[0].hasOwnProperty(this.filterName)) this.options = this.options.map((el) => el[this.filterName]);
    if ((this.reportName === 'agent_performance' || this.reportName === 'manager_performance' || this.reportName === 'listenforce') && this.filterName === 'Agent') this.options.unshift('any');
    if (this.filterName === 'Ring-to Number') this.options.unshift('No Key Press');
    this.options = [...new Set(this.options)];
    this.filterInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // listen for changes in @Input'filterData' fetched from API
    if (changes.filterData) {
      this.options = [...this.filterData];
      if (FILTER_DETAILS[this.filterName].fetch_data && this.options.length && this.options[0].hasOwnProperty(this.filterName)) this.options = this.options.map((el) => el[this.filterName]);
      this.options = [...new Set(this.options)];
      // this.filterInit();
    }
  }

  // GETTER for filter_items FormArray
  get filters() {
    return this.filterForm.get('filter_items') as FormArray;
  }

  filterInit() {
    this.setFilterType();
    this.setFilterActions();
    const itemCtrls = this.createItemsCtrls(this.initValue);
    // create filterForm FormGroup
    this.filterForm = this.fb.group({
      label: [this.filterName],
      filter_items: itemCtrls,
    });
    for (let i = 0; i < this.initValue.length; i++) {
      this.setFilterOptionObsv(i);
    }
  }

  // setup autcomplete obsv$
  setFilterOptionObsv(index: number) {
    const filter_items: FilterItem[] = [];
    this.filters.controls.forEach((control) => {
      filter_items.push(control.value);
    });
    const existing_values = filter_items.map((el) => el.value);
    existing_values.splice(index, 1);

    const new_options = this.options.filter((el) => !existing_values.includes(el));
    this.filteredOptions$[index] = this.filters
      .at(index)
      .get('value')!
      .valueChanges.pipe(
        filter(() => this.filterType === 'dropdown' || this.filterType === 'isonly'),
        startWith(''),
        map((value) => {
          return value ? this._filter(value, new_options) : new_options.slice();
        }),
      );
  }

  // Create a form control for each object in FilterItem array
  createItemsCtrls(items: FilterItem[]) {
    // Initilize an empty form array
    let formArrayCtrls = this.fb.array([]);
    // Iterate each value
    if (items && items.length > 0) {
      items.forEach((item) => {

        let formValue: any = this.fb.group({
          action: [item.action],
          value: [item.value]
        });

        // Create a new form control for each array value and push to form array
        formArrayCtrls.push(formValue);
      });
    }
    return formArrayCtrls;
  }

  setFilterType() {
    this.filterType = FILTER_DETAILS[this.filterName].type;
    const found_initial_filter = REPORT_FILTERS[this.reportName].initial_filters.find((el) => el.filter_name === this.filterName);
    this.editable = found_initial_filter ? false : true;
  }

  getInputType() {
    if (this.filterType === 'number' || this.filterType === 'number_text') return 'number';
    else return 'text';
  }

  setFilterActions() {
    const filterActionOptions = {
      text: [
        { value: 'is', viewValue: 'is' },
        { value: 'contains', viewValue: 'contains' },
        { value: 'doesnotcontain', viewValue: 'does not contain' },
      ],
      number: [
        { viewValue: 'is', value: 'is' },
        { viewValue: 'is >', value: 'isgreaterthan' },
        { viewValue: 'is >=', value: 'isgreaterthanequalto' },
        { viewValue: 'is <', value: 'islessthan' },
        { viewValue: 'is <=', value: 'islessthanequalto' },
      ],
      dropdown: [
        { value: 'is', viewValue: 'is' },
        { value: 'contains', viewValue: 'contains' },
        { value: 'doesnotcontain', viewValue: 'does not contain' },
      ],
      isonly: [{ value: 'is', viewValue: 'is' }],
      containsonly: [{ value: 'contains', viewValue: 'contains' }],
      number_text: [
        { value: 'is', viewValue: 'is' },
        { value: 'contains', viewValue: 'contains' },
        { value: 'doesnotcontain', viewValue: 'does not contain' },
      ],
    };
    this.filterActions = filterActionOptions[this.filterType];
  }

  private _filter(value: string, options: string[]): string[] {
    let filterValue = '';
    if (value) filterValue = value.toLowerCase();
    return options.filter((option) => option.toString().toLowerCase().includes(filterValue));
  }

  addFilter() {
    // add a new FormGroup to 'filters' FormArray
    this.filters.push(
      this.fb.group({
        filterType: [this.filterActions[0].value],
        value: [''],
      }),
    );
    // setup autcomplete obsv$
    const len = this.filters.length;
    this.setFilterOptionObsv(len - 1);
  }

  removeFilter(index: number) {
    this.filters.removeAt(index);
    this.emitFilterValues();
    this.filteredOptions$.splice(index, 1);
  }

  getRemoveStatus() {
    // show '-' button for DEFAULT filters
    if (
      (this.reportName === 'billing_usage' && this.filterName === 'Campaign Status') ||
      (this.reportName === 'scored_calls' && this.filterName === 'Scorecard') ||
      ((this.reportName === 'agent_performance' || this.reportName === 'manager_performance' || this.reportName === 'listenforce') && this.filterName === 'Agent')
    ) {
      if (this.filters.length === 1) return false;
      else return true;
    } else return this.editable;
  }

  getAddStatus() {
    // show '+' button for DEFAULT filters
    if (this.reportName === 'scored_calls' && this.filterName === 'Scorecard') return true;
    if (this.reportName === 'billing_usage' && this.filterName === 'Campaign Status') return true;
    if ((this.reportName === 'agent_performance' || this.reportName === 'manager_performance' || this.reportName === 'listenforce') && this.filterName === 'Agent') return true;
    else return this.editable;
  }

  emitFilterValues() {
    const filter_items: FilterItem[] = [];
    this.filters.controls.forEach((control) => {
      filter_items.push(control.value);
    });
    const filter: Filter = {
      filter_name: this.filterName,
      filter_items,
    };
    this.filterChange.emit(filter);
  }

  inputLengthCheck(index: number) {
    if (this.getInputType() === 'number') {
      const input_value = this.filters.at(index).value;
      this.filters.at(index).setValue({ filterType: input_value.filterType, value: parseInt(input_value.value.toString().slice(0, 9)) });
    }
  }

  onPasteFilterInput() {
    setTimeout(() => this.emitFilterValues(), 0);
  }

}
