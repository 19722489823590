import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    MatTreeFlatDataSource,
    MatTreeFlattener,
} from '@angular/material/tree';
import { ProjectService } from 'app/modules/admin/dashboards/project/project.service';
import { DashboardService } from '../shared-Services/dashboard.service';
import { GroupsDatabaseService } from './groups-database.service';
import { GroupFlatNode } from './models/GroupFlatNode';
import { GroupNode } from './models/GroupNode';

@Component({
    selector: 'app-groups-tree',
    templateUrl: './groups-tree.component.html',
    styleUrls: ['./groups-tree.component.scss'],
    providers: [
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { clickAction: 'noop' },
        },
    ],
})
export class GroupsTreeComponent {
    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    flatNodeMap = new Map<GroupFlatNode, GroupNode>();
    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    nestedNodeMap = new Map<GroupNode, GroupFlatNode>();
    treeControl: FlatTreeControl<GroupFlatNode>;
    treeFlattener: MatTreeFlattener<GroupNode, GroupFlatNode>;
    dataSource: MatTreeFlatDataSource<GroupNode, GroupFlatNode>;

    /** The selection for checklist */
    checklistSelection: SelectionModel<string>;
    selection_changed = false;
    clicked_node: GroupFlatNode | null;
    clicked_num = 0;
    tree_data_save: GroupNode[];
    currentFilterText: string = '';
    groupSelectedArray: any[] = [];

    todayTimeStamp: any;
    unixTimeStampEndDate: any;
    unixTimeStampStartDate: any;
    todayApiResponse: any;
    dashboardStats: any;
    isStatsLoaded: boolean = false;
    dateRangeForm: FormGroup;
    groupIds: any;

    constructor(
        private _database: GroupsDatabaseService,
        private dialogRef: MatDialogRef<GroupsTreeComponent>,
        private _changeDetectorRef: ChangeDetectorRef,
        private _projectService: ProjectService,
        private _dashboardService: DashboardService,
        @Inject(MAT_DIALOG_DATA) public dialog_data: any
    ) {
        this._database.initGroups();
        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this.getLevel,
            this.isExpandable,
            this.getChildren
        );
        this.treeControl = new FlatTreeControl<GroupFlatNode>(
            this.getLevel,
            this.isExpandable
        );
        this.dataSource = new MatTreeFlatDataSource(
            this.treeControl,
            this.treeFlattener
        );
        _database.dataChange.subscribe((data) => {
            this.dataSource.data = data;
            this.tree_data_save = JSON.parse(JSON.stringify(data));
            this.checklistSelection = new SelectionModel<string>(
                !this.dialog_data.single_select
            ); /* single / multiple SelectionModel */
            const selected_nodes = this.dialog_data.selected_nodes.map(
                (el) => el.id
            );
            this.checklistSelection.select(...selected_nodes);
            // need to expand all nodes initially to set selection status for all child nodes (else diplay: none)
            this.treeControl.expandAll();
            // select ALL nodes on first load
            // if (this.dialog_data.first_open && data.length) {
            //     this.treeControl.dataNodes.forEach((node) =>
            //         this.checklistSelection.select(node)
            //     );
            // }
            // need short timer to let all nodes expand before collapsing them
            setTimeout(() => {
                // this.treeControl.collapseAll();
                if (this.dialog_data.single_select && selected_nodes.length) {
                    // expand parent node of current selection
                    const current_node = this.treeControl.dataNodes.find(
                        (el) => el.id === selected_nodes[0]
                    );
                    const parent_node = this.getParentNode(current_node);
                    if (parent_node) this.treeControl.expand(parent_node);
                }
                // expand ONLY root nodes by DEFAULT
                this.treeControl.dataNodes.forEach((el) => {
                    if (el.level === 0) this.treeControl.expand(el);
                    if (el.level === 1) this.treeControl.expand(el);
                    if (el.level === 2) this.treeControl.expand(el);
                });
            }, 100);
        });
    }

    getLevel = (node: GroupFlatNode) => node?.level;

    isExpandable = (node: GroupFlatNode) => node.expandable;

    getChildren = (node: GroupNode): GroupNode[] => node.children;

    hasChild = (_: number, _nodeData: GroupFlatNode) => _nodeData.expandable;

    hasNoContent = (_: number, _nodeData: GroupFlatNode) =>
        _nodeData.item === '';

    /** Transformer to convert nested node to flat node. Record the nodes in maps for later use.*/
    transformer = (node: GroupNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode =
            existingNode && existingNode.item === node.item
                ? existingNode
                : new GroupFlatNode();
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.id = node.id;
        flatNode.expandable = !!node.children?.length;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: GroupFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every((child) => {
                return this.checklistSelection.isSelected(child.item);
            });
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: GroupFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some((child) =>
            this.checklistSelection.isSelected(child.id)
        );
        return result && !this.checklistSelection.isSelected(node.id);
    }

    selectionToggle(node: GroupFlatNode) {
        if (this.dialog_data.single_select) {
            this.checklistSelection.select(node.id);
            this.selection_changed = this.getApplyBtnVisibility();
        } else if (node.expandable) this.groupSelectionToggle(node);
        else this.groupLeafItemSelectionToggle(node);
    }

    /** Toggle the Group item selection. Select/deselect all the descendants node */
    groupSelectionToggle(node: GroupFlatNode): void {
        // first-selection, select parent node only
        if (
            (!this.clicked_node || this.clicked_node !== node) &&
            !this.checklistSelection.isSelected(node.id)
        ) {
            this.clicked_node = node;
            this.checklistSelection.select(node.id);
        } else {
            // second-selection, select parent & all children
            if (
                this.clicked_num === 0 &&
                this.checklistSelection.isSelected(node.id) &&
                this.clicked_node
            ) {
                this.checklistSelection.select(node.id);
                this.clicked_num = 1;
            } else {
                // third-selection, deselect parent & all children
                this.clicked_node = null;
                this.clicked_num = 0;
                this.checklistSelection.deselect(node.id);
            }
            const descendants = this.treeControl
                .getDescendants(node)
                .filter((node) => this.showNode(node)); // showNode(node) filters out invisible nodes (in case group filter is applied)
            this.checklistSelection.isSelected(node.id)
                ? this.checklistSelection.select(
                      ...descendants.map((el) => el.id)
                  )
                : this.checklistSelection.deselect(
                      ...descendants.map((el) => el.id)
                  );
        }
        // Force update for the parent
        // this.checkAllParentsSelection(node);
        this.selection_changed = this.getApplyBtnVisibility();
    }

    /** Toggle a leaf Group item selection. Check all the parents to see if they changed */
    groupLeafItemSelectionToggle(node: GroupFlatNode): void {
        const found_node = this.treeControl.dataNodes.find(
            (el) =>
                el.expandable === node.expandable &&
                el.level === node.level &&
                el.item === node.item &&
                el.id === node.id
        );
        this.checklistSelection.toggle(found_node!.id);
        // this.checkAllParentsSelection(node);
        this.selection_changed = this.getApplyBtnVisibility();
    }

    getApplyBtnVisibility() {
        let enableBtn = false;
        this.treeControl.dataNodes.forEach((node) => {
            if (this.checklistSelection.isSelected(node.id)) enableBtn = true;
        });
        return enableBtn;
    }

    /** Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: GroupFlatNode): void {
        let parent: GroupFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: GroupFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node.item);
        const descendants = this.treeControl.getDescendants(node);
        const descAnySelected = descendants.some((child) => {
            return this.checklistSelection.isSelected(child.item);
        });
        if (nodeSelected) {
            this.checklistSelection.select(node.item);
        }
        if (descAnySelected) {
            this.checklistSelection.select(node.item);
        }
    }

    /** Get the parent node of a node */
    getParentNode(node: GroupFlatNode): GroupFlatNode | null {
        const currentLevel = this.getLevel(node);
        if (currentLevel < 1) {
            return null;
        }
        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];
            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    filterChanged(event: Event) {
        this.currentFilterText = (
            event.target as HTMLInputElement
        ).value.toLowerCase();
        // this._database.filterTree(this.currentFilterText);
        if (this.currentFilterText) {
            this.dataSource.data = JSON.parse(
                JSON.stringify(this.tree_data_save)
            ).map((lv1) => {
                // filter out lv2 groups
                lv1.children = lv1.children.filter(
                    (lv2) =>
                        lv2.item
                            .toLowerCase()
                            .includes(this.currentFilterText) ||
                        lv2.children.some((lv3) =>
                            lv3.item
                                .toLowerCase()
                                .includes(this.currentFilterText)
                        )
                );
                // filter out lv3 groups (functionality currently removed)
                // lv1.children.forEach((lv2) => {
                //   lv2.children = lv2.children.filter((lv3) => lv3.item.toLowerCase().includes(this.currentFilterText));
                // });
                return lv1;
            });
            this.treeControl.expandAll();
        } else {
            this.dataSource.data = JSON.parse(
                JSON.stringify(this.tree_data_save)
            );
            // this.treeControl.collapseAll();
            this.treeControl.dataNodes.forEach((el) => {
                if (el.level === 0) this.treeControl.expand(el);
            });
        }
    }

    showNode(node: GroupFlatNode) {
        // show node if filter is empty
        if (this.currentFilterText === '') return true;
        // show node if node text contains filter text
        if (node.item.toLowerCase().includes(this.currentFilterText))
            return true;
        // show if current node is 3rd lvl & its 2nd lvl parent is matched
        const parent_node = this.getParentNode(node);
        if (
            node.level === 2 &&
            parent_node?.item.toLowerCase().includes(this.currentFilterText)
        )
            return true;
        // show node if any of the descendant text matches filter text
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some((el) =>
            el.item.toLowerCase().includes(this.currentFilterText)
        );
        return result;
    }
   
    closeModal() {
        const selection_data = {};
        selection_data['groups_selected'] = this.checklistSelection.selected;
        const selected_nodes = this.treeControl.dataNodes.filter((node) =>
            this.checklistSelection.isSelected(node.id)
        );
        selection_data['selected_nodes'] = selected_nodes;

        this._database.GroupsArray.next(selection_data['groups_selected']);
        this._database.selectedNodes.next(selection_data['selected_nodes']);

        if (this.dialog_data.single_select) {
            if (selected_nodes[0].level === 1) {
                selection_data['parent_node'] = this.getParentNode(
                    selected_nodes[0]
                );
            }
            if (selected_nodes[0].level === 2) {
                selection_data['parent_node'] = this.getParentNode(
                    selected_nodes[0]
                );
                selection_data['top_node'] = this.getParentNode(
                    selection_data['parent_node']
                );
            }
        }

        var startDay = new Date();
        startDay.setHours(0, 0, 0, 0);
        this.unixTimeStampStartDate = Math.floor(startDay.getTime() / 1000);

        var endDate = new Date();
        this.todayTimeStamp = endDate.getTime();
        this.unixTimeStampEndDate = Math.floor(endDate.getTime() / 1000);

        this._database.GroupsArray.subscribe((res) => {
            this.groupIds = res;

            this._changeDetectorRef.markForCheck();
        });

        console.log(this.dialog_data)

        this._projectService
            .getDashboardStats(
                this.dialog_data.dateTime[0].startDate,
                this.dialog_data.dateTime[0].endDateTime,
                this.groupIds
            )
            .subscribe((res) => {
                this.todayApiResponse = res;

                this._dashboardService.loadGraphData.next(res);
                if (this.todayApiResponse.status == 'success') {
                    this.dashboardStats = this.todayApiResponse;
                    this._database.TodayFilter.next(this.dashboardStats);

                    this._changeDetectorRef.markForCheck();
                }
            });
        this.dialogRef.close(selection_data);
    }

    cancelModal() {
        this.dialogRef.close();
    }
}
