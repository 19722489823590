import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubgroupsService {

  subGroupList = new BehaviorSubject<any>({});

  constructor(private http: HttpClient) { }


  getSubgroupsList(data){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('token', token);
    return this.http.post( 'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/getgroup', data, { headers });
  }

}
