<ng-container *ngIf="filters.controls.length > 0">
    <span>{{ filterName }}</span>
    <form [formGroup]="filterForm">
      <div formArrayName="filter_items" class="filter-items">
        <!-- individual filter CHIP -->
        <ng-container *ngFor="let filter of filters.controls; let i = index">
          <div formGroupName="{{ i }}" class="filter-chip">
            <!-- filter action -->
            <select formControlName="action" name="filterAction" appAutowidth="width" (change)="emitFilterValues()">
              <option *ngFor="let action of filterActions" [value]="action.value">{{ action.viewValue }}</option>
            </select>
            <!-- filter value -->
            <input
              [type]="getInputType()"
              formControlName="value"
              name="filterValue"
              [matAutocomplete]="auto"
              appAutowidth="size"
              (keyup)="emitFilterValues()"
              (paste)="onPasteFilterInput()"
              maxlength="50"
              min="0"
              max="999999999"
              (input)="inputLengthCheck(i)"
            />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="emitFilterValues()" panelWidth="max-content">
              <cdk-virtual-scroll-viewport class="autocomplete-viewport" itemSize="32" minBufferPxs="500" maxBufferPx="600">
                <mat-option *cdkVirtualFor="let filterOption of filteredOptions$[i] | async" [value]="filterOption" class="autocomplete-item">
                  {{ filterOption }}
                </mat-option>
              </cdk-virtual-scroll-viewport>
            </mat-autocomplete>
            <!-- remove filter -->
            <mat-icon svgIcon="heroicons_outline:x-circle" (click)="removeFilter(i)" *ngIf="getRemoveStatus()"></mat-icon>
          </div>
        </ng-container>
        <!-- add filter -->
        <!-- <mat-icon (click)="addFilter()" class="add-filter-btn" *ngIf="getAddStatus()">add_circle_outline</mat-icon> -->
        <mat-icon (click)="addFilter()" class="add-filter-btn" *ngIf="getAddStatus()" svgIcon="add_circle"></mat-icon>
      </div>
    </form>
  </ng-container>