import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Toast } from './toast.interface';

@Component({
  selector: 'app-alert-toast',
  templateUrl: './alert-toast.component.html',
  styleUrls: ['./alert-toast.component.scss']
})
export class AlertToastComponent implements OnInit {
    @Input() toast: Toast;
    @Input() i: number;
  constructor() { }

  ngOnInit(): void {
  }

  @Output() remove = new EventEmitter<number>();


}
