<div
    style="background-color: #fff"
    class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0"
>
    <div
        style="display: block; margin: 0px auto"
        class="w-full sm:w-auto md:h-full py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:bg-card"
    >
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->
            <div
                class="w-24"
                style="
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 5%;
                "
            >
                <img src="assets/images/logo/logo.svg" />
            </div>

            <!-- Title -->

            <div class="d-flex align-items-center justify-content-center" style="text-align: center">
                <div
                class="mt-8 text-2xl font-extrabold tracking-tight leading-tight"
            >
               Contact us at:

            </div>

            <div class="mt-8 ml-4" *ngIf="emptyData === ''">
                {{ContactNumber}}
            </div>

            <div class="mt-8 ml-4" *ngIf="emptyData !== ''">
                {{emptyData}}
            </div>
            
            </div>

            <button class="fuse-mat-button-large mt-1 ml-5" style="text-align: center; margin-left: 102px !important;"
            mat-flat-button
            routerLink="/dni"
           > <mat-icon svgIcon="heroicons_solid:arrow-left"></mat-icon>  <span class="ml-2">Back</span>
        </button>
        
        </div>
    </div>
</div>
