/* eslint-disable eol-last */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Filter } from '../model/filter.model';

export const REPORT_FILTERS: { [key: string]: { initial_filters: Filter[]; remaining_filters: string[]; defaultDateLabel: string } } = {
  campaigns_page: {
    initial_filters: [],
    remaining_filters: ['Campaign', 'Campaign Ext ID', 'Start Date', 'End Date', 'Campaign Status', 'Quantity'],
    defaultDateLabel: 'none',
  },
  tracking_number_page: {
    initial_filters: [{ filter_name: 'Campaign', filter_items: [{action: 'is', value: 'All' }] }],
    remaining_filters: ['Tracking Number', 'Tracking Number Name', 'Campaign', 'Ring-to Number', 'Tracking Number Type', 'Tracking Number Status'],
    defaultDateLabel: 'none',
  },
  subGroups: {
    initial_filters: [{ filter_name: 'Campaign', filter_items: [{ action: 'is', value: 'All' }] }],
    remaining_filters: ['Group Id', 'Group Name', 'External Id', 'Industry', 'Phone', 'City', 'State', 'Zip Code'],
    defaultDateLabel: 'none',
  },
  userGroups: {
    initial_filters: [{ filter_name: 'Campaign', filter_items: [{ action: 'is', value: 'All' }] }],
    remaining_filters: ['First Name', 'Last Name', 'Email', 'External Id', 'Agent Id', 'Role', 'Status', 'Cloud PBX'],
    defaultDateLabel: 'none',
  },
  routing_templates_page: {
    initial_filters: [],
    remaining_filters: ['Template Name', 'Route Type'],
    defaultDateLabel: 'none',
  },
  scorecards_page: {
    initial_filters: [],
    remaining_filters: ['Agent', 'Score Status', 'Scoring Status', 'Scorecard Score', 'Scorecard Outcome', 'Duration', 'Tracking Number', 'Tags', 'Campaign', 'Scorecard', 'Scored Date', 'Reviewed Date'],
    defaultDateLabel: 'is in the last 7 days',
  },
  six_steps: {
    initial_filters: [{ filter_name: 'Show By', filter_items: [{ action: 'is', value: 'Group Name' }] }],
    remaining_filters: ['Agent', 'Campaign', 'Group', 'Tracking Number', 'Tracking Number Name', 'Scored Date', 'Reviewed Date'],
    defaultDateLabel: 'is in the last 7 days',
  },
  analytics_summary: {
    initial_filters: [],
    remaining_filters: ['Agent', 'Call ID', 'Caller ID', 'Campaign', 'Group', 'Indicator Name', 'Indicator Type', 'Tracking Number', 'Tracking Number Name'],
    defaultDateLabel: 'is in the last 7 days',
  },
  brand_muscle: {
    initial_filters: [],
    remaining_filters: ['Tracking Number Status'],
    defaultDateLabel: 'is in the last 7 days',
  },
  blocked_calls: {
    initial_filters: [],
    remaining_filters: ['Campaign', 'Group', 'Tracking Number', 'Tracking Number Name'],
    defaultDateLabel: 'is in the last 7 days',
  },
  call_back: {
    initial_filters: [{ filter_name: 'Stack Column By', filter_items: [{action: 'is', value: 'Date' }] }],
    remaining_filters: [
      'Ad Source',
      'Agent',
      'Call ID',
      'Caller ID',
      'Call Status',
      'Campaign',
      'Custom Source 1',
      'Custom Source 2',
      'Custom Source 3',
      'Custom Source 4',
      'Custom Source 5',
      'Duration',
      'Group',
      'Indicator Name',
      'Ring-to Name',
      'Ring-to Number',
      'Tags',
      'Tracking Number',
      'Tracking Number Name',
    ],
    defaultDateLabel: 'is in the last 7 days',
  },
  caller_activity: {
    initial_filters: [{ filter_name: 'Calls By', filter_items: [{action: 'is', value: 'Caller ID' }] }],
    remaining_filters: ['Campaign', 'Group', 'Tracking Number', 'Tracking Number Name'],
    defaultDateLabel: 'is in the last 7 days',
  },
  call_logs: {
    initial_filters: [],
    remaining_filters: [
      'Recording',
      'Caller ID',
      'Destination',
      'Ring To Number',
      'Status',
      'Start',
      'Duration',
      'Disposition',
    ],
    defaultDateLabel: 'none',
  },
  cloud_PBX: {
    initial_filters: [],
    remaining_filters: [
      'Name',
      'Email',
      'Number',
      'Extension',
      'Role',
      'Status',
    ],
    defaultDateLabel: 'none',
  },
  call_logs_data_append: {
    initial_filters: [],
    remaining_filters: ['Caller ID', 'Group', 'Disposition', 'Duration', 'Tracking Number', 'Tracking Number Name', 'Ring-to Number'],
    defaultDateLabel: 'is in the last 7 days',
  },
  call_overview: {
    initial_filters: [],
    remaining_filters: ['Campaign', 'Group', 'Disposition', 'Tags', 'Tracking Number', 'Tracking Number Name', 'Ring-to Name', 'Ring-to Number'],
    defaultDateLabel: 'is in the last 7 days',
  },
  calls_by_region: {
    initial_filters: [{ filter_name: 'Show Calls By', filter_items: [{action: 'is', value: 'Zip/Postal Code' }] }],
    remaining_filters: ['Ad Source', 'Campaign', 'Group', 'Custom Source 1', 'Custom Source 2', 'Custom Source 3', 'Custom Source 4', 'Custom Source 5', 'Tracking Number', 'Tracking Number Name'],
    defaultDateLabel: 'is in the last 7 days',
  },
  call_trends: {
    initial_filters: [
      { filter_name: 'Stack Columns By', filter_items: [{action: 'is', value: 'Repeat/Unique' }] },
      { filter_name: 'Group Pie Chart By', filter_items: [{action: 'is', value: 'Group' }] },
    ],
    remaining_filters: ['Campaign', 'Group', 'Tracking Number', 'Tracking Number Name'],
    defaultDateLabel: 'is in the last 7 days',
  },
  custom_billing: {
    initial_filters: [],
    remaining_filters: ['Billing Accounts'],
    defaultDateLabel: 'is in the last 7 days',
  },
  billing_usage: {
    initial_filters: [{ filter_name: 'Campaign Status', filter_items: [{action: 'is', value: 'active' }] }],
    remaining_filters: [
      'Campaign',
      'Call Record Status',
      'Custom Source 1',
      'Custom Source 2',
      'Custom Source 3',
      'Custom Source 4',
      'Custom Source 5',
      'Group',
      'Tracking Number',
      'Tracking Number Name',
    ],
    defaultDateLabel: 'is in the last 7 days',
  },
  doctors_internet: {
    initial_filters: [],
    remaining_filters: ['Ad Source', 'Disposition', 'Duration', 'Group', 'Key Press'],
    defaultDateLabel: 'is in the last 1 days',
  },
  email_digest: {
    initial_filters: [],
    remaining_filters: [
      'Agent',
      'Group',
      'Caller ID',
      'Campaign',
      'Custom Source 1',
      'Custom Source 2',
      'Custom Source 3',
      'Custom Source 4',
      'Custom Source 5',
      'Disposition',
      'Indicator Name',
      'Indicator Type',
      'Tracking Number',
      'Tracking Number Name',
    ],
    defaultDateLabel: 'is in the last 7 days',
  },
  group_activity: {
    initial_filters: [],
    remaining_filters: [
      'Secondary Mapping',
      'Group ID',
      'Group',
      'Group Ext ID',
      'Call ID',
      'Billable Minutes',
      'Leads',
      'Conversions',
      'Call value',
      'Unique',
      'Answered',
      'Send to Voicemail',
      'Tracking Number',
      'Campaign',
    ],
    defaultDateLabel: 'is in the last 7 days',
  },
  ivr_keypress: {
    initial_filters: [],
    remaining_filters: ['Agent', 'Caller ID', 'Campaign', 'Group', 'Tracking Number', 'Tracking Number Name', 'Ring-to Number', 'Keypress Number', 'Keypress Name', 'Conversion'],
    defaultDateLabel: 'is in the last 7 days',
  },
  marketing_dashboard: {
    initial_filters: [{ filter_name: 'Group Calls By', filter_items: [{action: 'is', value: 'Group' }] }],
    remaining_filters: ['Ad Source', 'Campaign', 'Group', 'Ring-to Name', 'Ring-to Number', 'Tracking Number', 'Tracking Number Name'],
    defaultDateLabel: 'is in the last 7 days',
  },
  pimm_usa: {
    initial_filters: [],
    remaining_filters: ['Ad Source', 'Disposition', 'Duration', 'Group', 'Key Press'],
    defaultDateLabel: 'is in the last 1 days',
  },
  scored_calls: {
    initial_filters: [
      { filter_name: 'Show By', filter_items: [{action: 'is', value: 'Group Name' }] },
      { filter_name: 'Scorecard', filter_items: [{action: 'is', value: '' }] },
    ],
    remaining_filters: ['Agent', 'Campaign', 'Group', 'Tracking Number', 'Tracking Number Name', 'Reviewed Date', 'Scored Date'],
    defaultDateLabel: 'is in the last 7 days',
  },
  store_manager_scored_calls: {
    initial_filters: [
      { filter_name: 'Stack Column By', filter_items: [{action: 'is', value: 'Date' }] },
      { filter_name: 'Show By', filter_items: [{action: 'is', value: 'User Name' }] },
    ],
    remaining_filters: ['Agent', 'Campaign', 'Group', 'Tracking Number', 'Tracking Number Name', 'Reviewed Date', 'Scored Date', 'Scorecard'],
    defaultDateLabel: 'is in the last 7 days',
  },
  tags_summary: {
    initial_filters: [{ filter_name: 'Stack Column By', filter_items: [{action: 'is', value: 'Date' }] }],
    remaining_filters: ['Campaign', 'Group', 'Tracking Number', 'Tracking Number Name', 'Tags'],
    defaultDateLabel: 'is in the last 7 days',
  },
  tracking_number_settings: {
    initial_filters: [],
    remaining_filters: ['Campaign', 'Group', 'Hunt Type', 'Ring-to Number', 'Send to Voicemail', 'Tracking Number', 'Tracking Number Name', 'Tracking Number Status', 'Tracking Number Type'],
    defaultDateLabel: 'none',
  },
  user_logs: {
    initial_filters: [{ filter_name: 'Show Activity By', filter_items: [{action: 'is', value: 'User' }] }],
    remaining_filters: ['Activity', 'Group', 'User'],
    defaultDateLabel: 'is in the last 7 days',
  },
  webhook_logs: {
    initial_filters: [],
    remaining_filters: ['Webhook', 'Webhook Status'],
    defaultDateLabel: 'is in the last 7 days',
  },
  agent_performance: {
    initial_filters: [
      { filter_name: 'Agent', filter_items: [{action: 'is', value: 'any' }] },
      { filter_name: 'Scorecard', filter_items: [{action: 'is', value: '' }] },
    ],
    remaining_filters: ['Campaign', 'Group', 'Tracking Number', 'Tracking Number Name', 'Conversion', 'Scored Date', 'Reviewed Date'],
    defaultDateLabel: 'is in the last 7 days',
  },
  manager_performance: {
    initial_filters: [
      { filter_name: 'Show By', filter_items: [{action: 'is', value: 'User Name' }] },
      { filter_name: 'Agent', filter_items: [{action: 'is', value: 'any' }] },
      { filter_name: 'Scorecard', filter_items: [{action: 'is', value: '' }] },
    ],
    remaining_filters: ['Campaign', 'Group', 'Tracking Number', 'Tracking Number Name', 'Conversion', 'Scored Date', 'Reviewed Date'],
    defaultDateLabel: 'is in the last 7 days',
  },
  listenforce: {
    initial_filters: [
      { filter_name: 'Agent', filter_items: [{action: 'is', value: 'any' }] },
      { filter_name: 'Scorecard', filter_items: [{action: 'is', value: '' }] },
    ],
    remaining_filters: ['Campaign', 'Group'],
    defaultDateLabel: 'is in the last 1 months',
  },
  spam_guard: {
    initial_filters: [],
    remaining_filters: ['Caller ID', 'Campaign', 'Duration', 'Group', 'Ring-to Number', 'State', 'Tracking Number', 'Tracking Number Name', 'Tracking Number Type'],
    defaultDateLabel: 'is in the last 7 days',
  },
};