/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAutoWidth]'
})
export class AutoWidthDirective implements OnInit {
  @Input() appAutowidth: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('keyup') onKeyUp() {
    this.resize();
  }

  @HostListener('focus') onFocus() {
    this.resize();
  }

  @HostListener('change') onChange() {
    this.resize();
  }

  ngOnInit(): void {
    setTimeout(() => this.resize());
  }

  private resize() {
    if (this.appAutowidth === 'size') {this.el.nativeElement.setAttribute('size', this.el.nativeElement.value.length);}
    if (this.appAutowidth === 'width') {
      if (this.el.nativeElement.value && this.el.nativeElement.value.includes('than')) {this.renderer.setStyle(this.el.nativeElement, 'width', '5rem');}
      else {this.renderer.setStyle(this.el.nativeElement, 'width', `${this.el.nativeElement.value.length * 0.7 + 1.5}em`);}
    }
  }
}
