<span class="ml-8">
    <button mat-button [matMenuTriggerFor]="dateMenu" class="filterLabel flat-button">
      {{filter_name || 'Date range'}} {{ dateFilterLabel }}
    </button>
    <mat-menu #dateMenu="matMenu" class="w-100">
      <form>
        <div class="fields-conatiner">
        <!-- date range select -->
        <mat-form-field appearance="outline" class="form-field-md date-range-select py-0" (click)="$event.stopPropagation()" name="daterangeField">
          <mat-label>Range</mat-label>
          <mat-select class="z-50 py-0" [(ngModel)]="dateRangeValue" name="dateRangeValue" (selectionChange)="changeDate()">
            <mat-option *ngFor="let dRange of dateRange" [value]="dRange.value">
              {{ dRange.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- time num value -->
        <mat-form-field appearance="outline" class="form-field-sm" (click)="$event.stopPropagation()" *ngIf="showNumInput">
          <input matInput type="number" min="1" [(ngModel)]="numValue" (click)="numValueChange()" (input)="numValueChange()" name="numValue" />
        </mat-form-field>
        <!-- time unit -->
        <mat-form-field appearance="outline" class="form-field-md" (click)="$event.stopPropagation()" *ngIf="showNumInput">
          <mat-label>Time</mat-label>
          <mat-select class="z-50" [(ngModel)]="timeUnitValue" name="timeUnitValue" (selectionChange)="changeDate()">
            <mat-option *ngFor="let unit of timeUnit" [value]="unit.value">
              {{ unit.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- time type select -->
        <mat-form-field appearance="outline" class="timeTypeField" (click)="$event.stopPropagation()" name="timeTypeField" *ngIf="showTimeSelect">
          <mat-label>Time</mat-label>
          <mat-select class="z-50" [(ngModel)]="timeTypValue" name="timeTypValue" (selectionChange)="changeDate()">
            <mat-option *ngFor="let type of timeType" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- calendar date-picker -->
        <mat-form-field appearance="outline" class="form-field-md" (click)="$event.stopPropagation()" *ngIf="showCalendar">
          <mat-label>Date</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="dateValue" name="dateValue" (dateInput)="changeDate()" (dateChange)="changeDate()" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <!-- month type select -->
        <mat-form-field appearance="outline" class="timeSelect" (click)="$event.stopPropagation()" name="monthTypeField" *ngIf="showMonthSelect">
          <mat-label>Month</mat-label>
          <mat-select class="z-50" [(ngModel)]="monthTypeValue" name="monthType" (selectionChange)="changeDate()">
            <mat-option *ngFor="let type of monthType" [value]="type.value">
              {{ type.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- year type select -->
        <mat-form-field appearance="outline" class="timeSelect" (click)="$event.stopPropagation()" name="yearTypeField" *ngIf="showYearSelect">
          <mat-label>Year</mat-label>
          <mat-select class="z-50" [(ngModel)]="yearTypeValue" name="yearType" (selectionChange)="changeDate()">
            <mat-option *ngFor="let type of yearType" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- range calendar date-picker -->
        <mat-form-field appearance="outline" class="form-field-lg" (click)="$event.stopPropagation()" *ngIf="showRangeCalendar">
          <mat-label>Date range</mat-label>
          <mat-date-range-input [rangePicker]="pickerRange">
            <input matStartDate placeholder="Start date" [(ngModel)]="dateRangeStart" (dateInput)="changeDate()" (dateChange)="changeDate()" name="dateRangeStart" />
            <input matEndDate placeholder="End date" [(ngModel)]="dateRangeEnd" (dateInput)="changeDate()" (dateChange)="changeDate()" name="dateRangeEnd" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerRange"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerRange></mat-date-range-picker>
        </mat-form-field>
      </div>
      </form>
    </mat-menu>
  </span>