export const FILTER_DETAILS: { [key: string]: { type: string; fetch_data: boolean; values: string[] } } = {
    Activity: {
      type: 'isonly',
      fetch_data: false,
      values: ['Call Actions', 'Tracking Number', 'Campaign', 'Integrations', 'IVR Routes', 'Tags', 'User/Group', 'Webhooks', 'Distribution List', 'Scheduler', 'Score Card'],
    },
    'Ad Source': {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    Agent: {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Billing Accounts': {
      type: 'isonly',
      fetch_data: true,
      values: [],
    },
    'Billable Minutes': {
      type: 'number',
      fetch_data: false,
      values: [],
    },
    Answered: {
      type: 'number',
      fetch_data: false,
      values: [],
    },
    'City': {
      type: 'text',
      fetch_data: false,
      values: ['New York', 'Seattle', 'San Francisco', 'Portland'],
    },
    'Call Back': {
      type: 'isonly',
      fetch_data: false,
      values: ['Yes', 'No'],
    },
    'Calls By': {
      type: 'isonly',
      fetch_data: false,
      values: ['Caller Name', 'Business Name', 'Caller ID'],
    },
    'Call ID': {
      type: 'text',
      fetch_data: false,
      values: [],
    },
    'Call Status': {
      type: 'isonly',
      fetch_data: false,
      values: ['Won', 'Lost', 'In Progress'],
    },
    'Caller ID': {
      type: 'text',
      fetch_data: false,
      values: [],
    },
    'Call value': {
      type: 'number',
      fetch_data: false,
      values: [],
    },
    Campaign: {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Campaign Status': {
      type: 'isonly',
      fetch_data: false,
      values: ['active', 'inactive', 'deleted'],
    },
    'Campaign Ext ID': {
      type: 'text',
      fetch_data: false,
      values: [],
    },
    'SMS': {
      type: 'text',
      fetch_data: false,
      values: ['active', 'inactive'],
    },
    'Spam Guard': {
      type: 'text',
      fetch_data: false,
      values: ['active', 'inactive'],
    },
    'DNI': {
      type: 'text',
      fetch_data: false,
      values: ['active', 'inactive'],
    },
    'Call Record Status': {
      type: 'isonly',
      fetch_data: false,
      values: ['Active', 'Inactive'],
    },
    Conversion: {
      type: 'isonly',
      fetch_data: false,
      values: ['True', 'False'],
    },
    Conversions: {
      type: 'number',
      fetch_data: false,
      values: [],
    },
    'Custom Source 1': {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Custom Source 2': {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Custom Source 3': {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Custom Source 4': {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Custom Source 5': {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    Disposition: {
      type: 'isonly',
      fetch_data: false,
      values: ['CALLER HANGUP', 'ANSWERED', 'NO ANSWER', 'BUSY', 'HANGUP', 'REFERRAL'],
    },
    Duration: {
      type: 'number',
      fetch_data: false,
      values: [],
    },
    Group: {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Group Status': {
      type: 'isonly',
      fetch_data: false,
      values: ['active', 'inactive', 'deleted'],
    },
    'Group ID': {
      type: 'text',
      fetch_data: false,
      values: [],
    },
    'Group Ext ID': {
      type: 'text',
      fetch_data: false,
      values: [],
    },
    'Group Calls By': {
      type: 'isonly',
      fetch_data: false,
      values: ['Group', 'Campaign', 'Ad Source', 'Tracking Number Name/Number', 'Ring-to Name/Number'],
    },
    'Hunt Type': {
      type: 'isonly',
      fetch_data: false,
      values: ['simultaneous', 'overflow', 'rollover'],
    },
    Quantity: {
      type: 'number',
      fetch_data: false,
      values: [],
    },
    'Ring-to Name': {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Ring-to Number': {
      type: 'text',
      fetch_data: false,
      values: [],
    },
    'Score Status': {
      type: 'isonly',
      fetch_data: false,
      values: ['Scored', 'Unscored', 'Partial', 'Reviewed'],
    },
    'Scoring Status': {
      type: 'isonly',
      fetch_data: false,
      values: ['Scored 1', 'Scored 2', 'Rescore Request 1', 'Rescore Request 2'],
    },
    Scorecard: {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Scorecard Status': {
      type: 'isonly',
      fetch_data: false,
      values: ['Active', 'Inactive', 'Deleted'],
    },
    'Scorecard Outcome': {
      type: 'isonly',
      fetch_data: false,
      values: ['Yes', 'No'],
    },
    'Send to Voicemail': {
      type: 'isonly',
      fetch_data: false,
      values: ['Yes', 'No'],
    },
    'Show By': {
      type: 'isonly',
      fetch_data: false,
      values: ['Group Name', 'User Name'],
    },
    'Show Calls By': {
      type: 'isonly',
      fetch_data: false,
      values: ['Zip/Postal Code', 'Area Code'],
    },
    'Show Activity By': {
      type: 'isonly',
      fetch_data: false,
      values: ['User', 'Group'],
    },
    'Stack Columns By': {
      type: 'isonly',
      fetch_data: false,
      values: ['Repeat/Unique', 'Disposition', 'None'],
    },
    'Group Pie Chart By': {
      type: 'isonly',
      fetch_data: false,
      values: ['Group', 'Campaign', 'Tracking Number|Name'],
    },
    'Stack Column By': {
      type: 'isonly',
      fetch_data: false,
      values: ['Hour', 'Day', 'Week', 'Month', 'Date'],
    },
    Tags: {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Scorecard Score': {
      type: 'number',
      fetch_data: false,
      values: [],
    },
    'Tracking Number': {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Tracking Number Name': {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Tracking Number Status': {
      type: 'isonly',
      fetch_data: false,
      values: ['active', 'inactive', 'deleted', 'referral'],
    },
    'Tracking Number Type': {
      type: 'text',
      fetch_data: false,
      values: [
        'Simple',
        'IVR',
        'GeoRoute - Area Code Proximity',
        'GeoRoute - Zip Code Proximity',
        'GeoRoute - Claimed Zip Code',
        'GeoRoute - Claimed State',
        'Percentage',
        'Outbound',
        'Voicemail',
        'Schedule',
      ],
    },
    Unique: {
      type: 'number',
      fetch_data: false,
      values: [],
    },
    Webhook: {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Webhook Status': {
      type: 'isonly',
      fetch_data: false,
      values: ['SENT', 'NOT SENT'],
    },
    'Reviewed Date': {
      type: 'date',
      fetch_data: false,
      values: [],
    },
    State: {
      type: 'isonly',
      fetch_data: false,
      values: [
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CZ',
        'CO',
        'CT',
        'DE',
        'DC',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'OH',
        'OK',
        'OR',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
      ],
    },
    'Secondary Mapping': {
      type: 'isonly',
      fetch_data: false,
      values: ['Tracking Number', 'Campaign'],
    },
    'Scored Date': {
      type: 'date',
      fetch_data: false,
      values: [],
    },
    User: {
      type: 'dropdown',
      fetch_data: true,
      values: [],
    },
    'Route Type': {
      type: 'isonly',
      fetch_data: false,
      values: ['SimpleRoute', 'IvrRoute2', 'OutboundRoute', 'ScheduleRoute', 'GeoRoute', 'VoicemailRoute','PercentageBasedRoute','roundrobin','simultaneous'],
    },
    'Template Name': {
      type: 'text',
      fetch_data: false,
      values: [],
    },
    
  };