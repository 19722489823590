import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { InventoryService } from 'app/modules/admin/ecommerce/inventory/inventory.service';

import { Injectable } from '@angular/core';
import {
    MatTreeFlatDataSource,
    MatTreeFlattener,
} from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { InventoryCampService } from 'app/modules/admin/Campaign/inventory/inventory-camp.service';
// import { CallLogsService } from 'app/modules/admin/call-logs/call-logs.service';
import { SubgroupsService } from 'app/shared/shared-Services/subgroups.service';
import { UsersService } from 'app/shared/shared-Services/users.service';
import { CloudPbxUserService } from 'app/shared/shared-Services/cloud-pbx-user.service';
import { CallLogsService } from 'app/shared/shared-Services/call-logs.service';

interface FoodNode {
    name: string;
    children?: FoodNode[];
}
let PERMISSION_TREE = [];
let TREE_DATA = [];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}

export class TodoItemNode {
    children: TodoItemNode[];
    item: string;
    code?: any;
    isSelected?: any;
    id?: any;
    isDisabled?: boolean;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
    item: string;
    level: number;
    expandable: boolean;
    code?: string;
    isSelected?: boolean;
    id?: any;
    isDisabled?: boolean;
}
@Injectable()
export class ChecklistDatabase {
    dataChange = new BehaviorSubject<TodoItemNode[]>([]);
    dataChangePermission = new BehaviorSubject<TodoItemNode[]>([]);
    get data(): TodoItemNode[] {
        return this.dataChange.value;
    }
    get dataPermission(): TodoItemNode[] {
        return this.dataChangePermission.value;
    }

    constructor() {
        // this.initialize();
    }

    initialize() {
        this.treeData = TREE_DATA;
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        //     file node as children.
        const data = this.buildFileTree(TREE_DATA, '0');
        // Notify the change.
        this.dataChange.next(data);
    }
    initializePermission() {
        this.treeDataPermission = PERMISSION_TREE;
        const data = this.buildFileTreePermission(PERMISSION_TREE, '0');
        this.dataChangePermission.next(data);
    }

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `TodoItemNode`.
     */
    buildFileTree(obj: any[], level: string): TreeItemNode[] {
        return obj
            .filter(
                (o) =>
                    (<string>o.code)?.startsWith(level + '.') &&
                    (o.code.match(/\./g) || []).length ===
                        (level.match(/\./g) || []).length + 1
            )
            .map((o) => {
                const node = new TreeItemNode();
                node.item = o.text;
                node.code = o.code;
                node.id = o.id;
                const children = obj.filter((so) =>
                    (<string>so.code)?.startsWith(level + '.')
                );
                if (children && children.length > 0) {
                    node.children = this.buildFileTree(children, o.code);
                }
                return node;
            });
    }

    buildFileTreePermission(obj: any[], level: string): TreeItemNode[] {
        return obj
            .filter(
                (o) =>
                    (<string>o.code)?.startsWith(level + '.') &&
                    (o.code.match(/\./g) || []).length ===
                        (level.match(/\./g) || []).length + 1
            )
            .map((o) => {
                const node = new TreeItemNode();
                node.item = o.text;
                node.code = o.code;
                node.id = o.id;
                node.isSelected = o.isSelected;
                node.isDisabled = o.isDisabled;
                const children = obj.filter((so) =>
                    (<string>so.code)?.startsWith(level + '.')
                );
                if (children && children.length > 0) {
                    node.children = this.buildFileTreePermission(
                        children,
                        o.code
                    );
                }
                return node;
            });
    }

    /** Add an item to to-do list */
    insertItem(parent: TodoItemNode, name: string) {
        if (parent.children) {
            parent.children.push({ item: name } as TodoItemNode);
            this.dataChange.next(this.data);
        }
    }

    updateItem(node: TodoItemNode, name: string) {
        node.item = name;
        this.dataChange.next(this.data);
    }
    treeData: any[] = [];
    treeDataPermission: any[] = [];
    filteredTreeData;
}

@Component({
    selector: 'fuse-confirmation-dialog',
    templateUrl: './dialog.component.html',
    providers: [ChecklistDatabase],
    styles: [
        `
            .fuse-confirmation-dialog-panel {
                @screen md {
                    @apply w-128;
                }

                .mat-dialog-container {
                    padding: 0 !important;
                    width: 500px !important;
                }
            }
        `,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class FuseConfirmationDialogComponent implements OnInit {
    loader: boolean = false;
    dataChange = new BehaviorSubject<TodoItemNode[]>([]);
    /**
     * Constructor
     */

    flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

    /** A selected parent node to be inserted */
    selectedParent: TodoItemFlatNode | null = null;

    /** The new item's name */
    newItemName = '';

    treeControl: FlatTreeControl<TodoItemFlatNode>;

    treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

    dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

    /** The selection for checklist */
    checklistSelection = new SelectionModel<TodoItemFlatNode>(
        true /* multiple */
    );
    dataSourceTree: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

    flatNodeMapB = new Map<TodoItemFlatNode, TodoItemNode>();
    nestedNodeMapB = new Map<TodoItemNode, TodoItemFlatNode>();
    selectedParentB: TodoItemFlatNode | null = null;
    newItemNameB = '';
    treeControlB: FlatTreeControl<TodoItemFlatNode>;
    treeFlattenerB: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
    dataSourceTreeB: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
    checklistSelectionB = new SelectionModel<TodoItemFlatNode>(
        true /* multiple */
    );
    @Output() ValueofTreeDropdown: EventEmitter<any> = new EventEmitter();
    main_group: any;
    selectGroupName: any;
    listFor: any;
    filterGroupID: any;
    allNodesB: any;
    treeData: any[];
    getTrackingList: any;
    TrackFilterList: any;
    Lengthproduslist: any;
    NoCampGroups: boolean;
    _changeDetectorRef: any;
    constructor(
        private _inventoryService: InventoryService,
        private _database: ChecklistDatabase,
        private _InventoryService: InventoryCampService,
        private _CallLogsService: CallLogsService,
        private _subGroupService: SubgroupsService,
        private _usersService: UsersService,
        private _cloudPbxUserService: CloudPbxUserService
    ) {
        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this.getLevel,
            this.isExpandable,
            this.getChildren
        );
        this.treeControl = new FlatTreeControl<TodoItemFlatNode>(
            this.getLevel,
            this.isExpandable
        );
        this.dataSourceTree = new MatTreeFlatDataSource(
            this.treeControl,
            this.treeFlattener
        );
        _database.dataChangePermission.subscribe((data) => {
            this.dataSourceTree.data = data;
        });
        this.treeFlattenerB = new MatTreeFlattener(
            this.transformerB,
            this.getLevelB,
            this.isExpandableB,
            this.getChildrenB
        );
        this.treeControlB = new FlatTreeControl<TodoItemFlatNode>(
            this.getLevelB,
            this.isExpandableB
        );
        this.dataSourceTreeB = new MatTreeFlatDataSource(
            this.treeControlB,
            this.treeFlattenerB
        );
        _database.dataChange.subscribe((data) => {
            this.dataSourceTreeB.data = data;
                // console.log("data source ---",this.dataSourceTreeB.data)
            if (this.NewSelectedValue === undefined) {
                // this.checklistSelectionB.select(this.treeControlB.dataNodes[0]);
            } else {
                for (let i = 0; i < this.treeControlB.dataNodes.length; i++) {
                    if (
                        this.NewSelectedValue.code ===
                        this.treeControlB.dataNodes[i].code
                    ) {
                        // this.checklistSelectionB.select(
                        //     this.treeControlB.dataNodes[i]
                        // );
                        this.treeControlB.expand(
                            this.treeControlB.dataNodes[i - 1]
                        );
                        break;
                    }
                }
            }

            this.treeControlB.expand(this.treeControlB.dataNodes[0]);
        });
    }

    NewSelectedValue: any;

populateGroups()
{
let groupId:any=parseInt(localStorage.getItem('GroupID'));

const exists =this.treeData.some(item => item.id === groupId);
    if(exists) this.checklistSelectionB.select(groupId)
    else this.checklistSelectionB.select(this.treeData[0].id)
}

    ngOnInit(): void {

        this.main_group = JSON.parse(localStorage.getItem('main_group')) || {
            id: 0,
            name: '',
        };
        this.selectGroupName = this.main_group.name;
        this.listFor = this.main_group.name;
        this.filterGroupID = this.main_group.id;

        this.treedata();
        this._inventoryService.currentMessage.subscribe((message) => {
            this.NewSelectedValue = message;
            // this.checklistSelectionB.select(this.NewSelectedValue);
        });
    }
    async treedata() {
        this.loader = true;

        this._inventoryService
            .getAllTreeGroups()
            .subscribe(async (data: any) => {
                // this.dataSource.data = data;
                // JSON.parse(localStorage.setItem('groups',data));

                TREE_DATA = await data.body;
                this.treeData = data.body;

                this._database.initialize();
                this.loader = false;
                this.populateGroups();
            });
        if (TREE_DATA) {
            this.treeData = TREE_DATA;
            // localStorage.setItem('TREE_DUM_DATA', JSON.stringify(this.treeData))
        }
    }
    breadText = '';
    getLevel = (node: TodoItemFlatNode) => node.level;

    isExpandable = (node: TodoItemFlatNode) => node.expandable;

    getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

    hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

    hasNoContent = (_: number, _nodeData: TodoItemFlatNode) =>
        _nodeData.item === '';

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: TodoItemNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode =
            existingNode && existingNode.item === node.item
                ? existingNode
                : new TodoItemFlatNode();
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.expandable = !!node.children?.length;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every((child) => {
                return this.checklistSelection.isSelected(child);
            });
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some((child) =>
            this.checklistSelection.isSelected(child)
        );
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggle(node: TodoItemFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.forEach((child) =>
            this.checklistSelection.isSelected(child)
        );
        this.checkAllParentsSelection(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(nodeId: TodoItemFlatNode): void {
        let parent: TodoItemFlatNode | null = this.getParentNode(nodeId);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: TodoItemFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node.id);
        const descendants = this.treeControl.getDescendants(node.id);
        const descAllSelected =
            descendants.length > 0 &&
            descendants.every((child) => {
                return this.checklistSelection.isSelected(child.id);
            });
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node.id);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node.id);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    filteredTreeData;
    public filter(filterText: string) {
        if (filterText) {
            this.filteredTreeData = this.treeData.filter(
                (d) =>
                    d.text
                        .toLocaleLowerCase()
                        .indexOf(filterText.toLocaleLowerCase()) > -1
            );
            Object.assign([], this.filteredTreeData).forEach((ftd) => {
                var str = <string>ftd?.code;

                while (str?.lastIndexOf('.') > -1) {
                    const index = str.lastIndexOf('.');
                    str = str.substring(0, index);

                    if (
                        this.filteredTreeData.findIndex(
                            (t) => t.code === str
                        ) === -1
                    ) {
                        const obj = this.treeData.find((d) => d.code === str);
                        if (obj) {
                            this.filteredTreeData.push(obj);
                        }
                    }
                }
                if (ftd.code.split('.').length == 3) {
                    const matches = this.treeData.filter((element) => {
                        if (element.code.includes(<string>ftd.code + '.')) {
                            return true;
                        }
                    });
                    this.filteredTreeData =
                        this.filteredTreeData.concat(matches);
                }
            });
        } else {
            this.filteredTreeData = this.treeData;
        }

        const unique = (value, index, self) => {
            return self.indexOf(value) === index;
        };
        this.filteredTreeData = this.filteredTreeData.filter(unique);
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        // file node as children.
        const data = this._database.buildFileTree(this.filteredTreeData, '0');
        // Notify the change.
        this._database.dataChange.next(data);
        // this.checkAll1();
    }
    buildFileTree(obj: any[], level: string): TreeItemNode[] {
        return obj
            .filter(
                (o) =>
                    (<string>o.code)?.startsWith(level + '.') &&
                    (o.code.match(/\./g) || []).length ===
                        (level.match(/\./g) || []).length + 1
            )
            .map((o) => {
                const node = new TreeItemNode();
                node.item = o.text;
                node.code = o.code;
                node.id = o.id;
                const children = obj.filter((so) =>
                    (<string>so.code)?.startsWith(level + '.')
                );
                if (children && children.length > 0) {
                    node.children = this.buildFileTree(children, o.code);
                }
                return node;
            });
    }
    parent: any;
    openScrollableContent(longContent) {
        // this.modalService.open(longContent, { scrollable: true });
        for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
            let isNode = this.allNodesB.find(
                (item) => item === this.treeControl.dataNodes[i].id
            );
            if (isNode) {
                this.parent = this.getParentNode(this.treeControl.dataNodes[i]);
            }
            // this.treeControlB.expand(this.treeControlB.dataNodes[i])
        }
        if (this.parent) {
            this.treeControl.expand(this.parent);
        }
    }
    //   breadText : string = ''
    currentTextB: any = '';
    filterChanged(filterText: string) {
        this.breadText = filterText;
        this.filter(filterText);
        if (filterText) {
            this.treeControl.expandAll();
        } else {
            this.treeControl.collapseAll();
            this.treeControl.expand(this.treeControl.dataNodes[0]);
            if (this.parent) {
                this.treeControl.expand(this.parent);
            }
            // this.treeControl.expandAll();
        }
    }
    /** Select the category so we can insert the new item. */
    addNewItem(node: TodoItemFlatNode) {
        const parentNode = this.flatNodeMap.get(node);
        this._database.insertItem(parentNode!, '');
        this.treeControl.expand(node);
    }

    /** Save the node to database */
    saveNode(node: TodoItemFlatNode, itemValue: string) {
        const nestedNode = this.flatNodeMap.get(node);
        this._database.updateItem(nestedNode!, itemValue);
    }

    // openScrollableContent(longContent) {
    //     this.modalService.open(longContent, { scrollable: true });
    //   }
    //   breadText : string = ''

    private _transformerB = (node: FoodNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
        };
    };

    getLevelB = (node: TreeItemFlatNode) => node.level;

    isExpandableB = (node: TreeItemFlatNode) => node.expandable;

    getChildrenB = (node: TreeItemNode): TreeItemNode[] => node.children;

    id = (node: TreeItemNode): TreeItemNode[] => node.id;

    hasChildB = (_: number, _nodeData: TreeItemFlatNode) =>
        _nodeData.expandable;

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformerB = (node: TreeItemNode, level: number) => {
        const existingNode = this.nestedNodeMapB.get(node);
        const flatNode =
            existingNode && existingNode.item === node.item
                ? existingNode
                : new TreeItemFlatNode();
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.code = node.code;
        flatNode.id = node.id;
        flatNode.expandable = node.children && node.children.length > 0;
        this.flatNodeMapB.set(flatNode, node);
        this.nestedNodeMapB.set(node, flatNode);
        return flatNode;
    };

    /** Whether all the descendants of the node are selected */
    descendantsAllSelectedB(node: TreeItemFlatNode): boolean {
        const descendants = this.treeControlB.getDescendants(node);
        return descendants.every((child) =>
            this.checklistSelectionB.isSelected(child)
        );
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelectedB(node: TreeItemFlatNode): boolean {
        const descendants = this.treeControlB.getDescendants(node);
        const result = descendants.some((child) =>
            this.checklistSelectionB.isSelected(child)
        );
        return result && !this.descendantsAllSelectedB(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggleB(node: TreeItemFlatNode): void {
        this.checklistSelectionB.toggle(node);
        const descendants = this.treeControlB.getDescendants(node);
        this.checklistSelectionB.isSelected(node)
            ? this.checklistSelectionB.select(...descendants)
            : this.checklistSelectionB.deselect(...descendants);
    }
    saveSelectedNodesB(node: TodoItemFlatNode, e: any): void {
        let nodeName = node.item;

        //  let index = this.treeControlB.dataNodes.findIndex(i => i.item === nodeName);
        //  this.checklistSelectionB.toggle(node);
        const descendants = this.treeControlB.getDescendants(node);
        if (e) {
            descendants.filter((i) => {
                this.allNodesB.push(i.item);
            });
            this.allNodesB.push(node.item);
        } else {
            let removal: any = [];
            descendants.filter((i) => {
                removal.push(i.item);
            });
            removal.push(node.item);
            removal.forEach((el) => {
                this.allNodesB = this.allNodesB.filter((item) => {
                    if (el !== item) {
                        return item;
                    }
                });
            });
        }
        this.checklistSelectionB.isSelected(node)
            ? this.checklistSelectionB.select(...descendants)
            : this.checklistSelectionB.deselect(...descendants);
        descendants.forEach((child) =>
            this.checklistSelectionB.isSelected(child)
        );
        this.checkAllParentsSelection(node);
    }

    hasNoContentB = (_: number, _nodeData: TodoItemFlatNode) =>
        _nodeData.item === '';

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggleB(nodeId: TodoItemFlatNode, e): void {
        this.allNodesB = [];
        // this.unCheckAll();
        //   const isSelected=  this.checklistSelectionB.isSelected(node);
        if (this.checklistSelectionB.isSelected(nodeId)) {
            this.checklistSelectionB.deselect(nodeId);
            
            // console.log(node.id)
        } else {
            this.checklistSelectionB.select(nodeId);
        }

        this.allNodesB.push([...this.checklistSelectionB.selected]);
        // this.checklistSelectionB.select(node)
        this.checkAllParentsSelection(nodeId);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelectionB(node: TodoItemFlatNode): void {
        let parent: TodoItemFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelectionB(node: TodoItemFlatNode): void {
        const nodeSelected = this.checklistSelectionB.isSelected(node);
        const descendants = this.treeControlB.getDescendants(node);

        const descAllSelected =
            descendants.length > 0 &&
            descendants.every((child) => {
                return this.checklistSelectionB.isSelected(child);
            });
        if (nodeSelected && !descAllSelected) {
            this.checklistSelectionB.deselect(node);
            this.allNodesB = this.allNodesB.filter((item) => {
                if (item !== node.item) {
                    return item;
                }
            });
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelectionB.select(node);
            this.allNodesB.push(node.item);
        }
    }
    /* Get the parent node of a node */
    getParentNodeB(node: TodoItemFlatNode): TodoItemFlatNode | null {
        const currentLevel = this.getLevel(node);
        if (currentLevel < 1) {
            return null;
        }
        const startIndex = this.treeControlB.dataNodes.indexOf(node) - 1;
        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControlB.dataNodes[i];
            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    /** Select the category so we can insert the new item. */
    addNewItemB(node: TodoItemFlatNode) {
        const parentNode = this.flatNodeMap.get(node);
        this._database.insertItem(parentNode!, '');
        this.treeControlB.expand(node);
    }

    /** Save the node to database */
    saveNodeB(node: TodoItemFlatNode, itemValue: string) {
        const nestedNode = this.flatNodeMap.get(this.currentGroupNode);
        this._database.updateItem(nestedNode!, itemValue);
    }
    checkAllB() {
        this.treeControlB.expand(this.treeControlB.dataNodes[0]);
        this.allNodesB = [];
        for (let i = 0; i < this.treeControlB.dataNodes.length; i++) {
            this.allNodesB.push(this.treeControlB.dataNodes[i].item);
            if (
                !this.checklistSelectionB.isSelected(
                    this.treeControlB.dataNodes[i]
                )
            ) {
                this.checklistSelectionB.toggle(this.treeControlB.dataNodes[i]);
            }
            this.treeControlB.expand(this.treeControlB.dataNodes[i]);
        }
    }
    newCurrentArrB = [];
    unCheckAll() {
        this.newCurrentArrB = [];
        for (let i = 0; i < this.treeControlB.dataNodes.length; i++) {
            if (
                this.checklistSelectionB.isSelected(
                    this.treeControlB.dataNodes[i]
                )
            ) {
                this.checklistSelectionB.toggle(this.treeControlB.dataNodes[i]);
            }
        }
    }
    groupLevel2: string = '';
    groupLevel3: string = '';
    groupLevel4: string = '';
    groupLevel5: string = '';
    isOneGroupSelected: boolean = false;
    currentGroupNode: any;
    FilterSelectedname: any;
    NewBoolTable: boolean;
    selecteditem: any;
    getGroupsB() {
        this.newCurrentArrB = [];

        for (let i = 0; i < this.treeControlB.dataNodes.length; i++) {
            if (
                this.checklistSelectionB.isSelected(
                    this.treeControlB.dataNodes[i].id
                )
            ) {
                this.filterGroupID = this.treeControlB.dataNodes[i].id;
                let item = this.treeControlB.dataNodes[i];
                this.FilterSelectedname = this.treeControlB.dataNodes[i];

                this.selecteditem = this.checklistSelectionB.select(
                    this.treeControlB.dataNodes[i]
                );

                this.currentGroupNode = this.treeControlB.dataNodes[i];
                this.listFor = item.item;

                // this.getData(this.filterGroupID);
                this.getCallLogs(this.filterGroupID);
                this.getCampaigns(this.filterGroupID);
                // this.callUserPBX(this.filterGroupID);
                this.getCloudPbxUserList(this.filterGroupID);
                this.getCloudPbxUserListFilter(this.filterGroupID);
                this.getAllListTN(this.filterGroupID);
                // this.getTrackingNumber(this.filterGroupID);
                this.getSUbgroupList(this.filterGroupID);
                this.getUsersList(this.filterGroupID);
                this._inventoryService.GroupLevel.next(item);

                if (item.level === 0) {
                    this.groupLevel2 = '';
                    this.groupLevel3 = '';
                    this.groupLevel4 = '';
                    this.groupLevel5 = '';
                    this._inventoryService.GroupLevel2.next(this.groupLevel2);
                    this._inventoryService.GroupLevel3.next(this.groupLevel3);
                    this._inventoryService.GroupLevel4.next(this.groupLevel4);
                    this._inventoryService.GroupLevel5.next(this.groupLevel5);
                    var treePraent = item.item;
                    this._inventoryService.MainGroupName.next(treePraent);
                } else if (item.level === 1) {
                    this.groupLevel2 = item.item;
                    this.groupLevel3 = '';
                    this.groupLevel4 = '';
                    this.groupLevel5 = '';
                    this._inventoryService.GroupLevel2.next(this.groupLevel2);
                    this._inventoryService.GroupLevel3.next(this.groupLevel3);
                    this._inventoryService.GroupLevel4.next(this.groupLevel4);
                    this._inventoryService.GroupLevel5.next(this.groupLevel5);
                } else if (item.level === 2) {
                    this.groupLevel2 = this.getParentNodeB(
                        this.treeControlB.dataNodes[i]
                    ).item;
                    this.groupLevel3 = item.item;

                    this.groupLevel4 = '';
                    this.groupLevel5 = '';
                    this._inventoryService.GroupLevel2.next(this.groupLevel2);
                    this._inventoryService.GroupLevel3.next(this.groupLevel3);
                    this._inventoryService.GroupLevel4.next(this.groupLevel4);
                    this._inventoryService.GroupLevel5.next(this.groupLevel5);
                } else if (item.level === 3) {
                    this.groupLevel3 = this.getParentNodeB(
                        this.treeControlB.dataNodes[i]
                    ).item;
                    this.groupLevel4 = item.item;

                    this.groupLevel5 = '';
                    this._inventoryService.GroupLevel2.next(this.groupLevel2);
                    this._inventoryService.GroupLevel3.next(this.groupLevel3);
                    this._inventoryService.GroupLevel4.next(this.groupLevel4);
                    this._inventoryService.GroupLevel5.next(this.groupLevel5);
                } else {
                    this.groupLevel4 = this.getParentNodeB(
                        this.treeControlB.dataNodes[i]
                    ).item;
                    this.groupLevel5 = item.item;
                    this._inventoryService.GroupLevel2.next(this.groupLevel2);
                    this._inventoryService.GroupLevel3.next(this.groupLevel3);
                    this._inventoryService.GroupLevel4.next(this.groupLevel4);
                    this._inventoryService.GroupLevel5.next(this.groupLevel5);
                }
            }
        }
    }

    isCloudUser: boolean = true;
    // callUserPBX(filterGroupID) {
    //     this.isCloudUser = true;
    //     this._inventoryService
    //         .getCloudPBXuser(filterGroupID, this.isCloudUser)
    //         .subscribe((responseObj: any) => {
    //             if (responseObj?.status === 'success') {
    //                 this._inventoryService.userCloudPbx.next(
    //                     responseObj?.body.users
    //                 );
    //             }
    //         });
    // }

    /**
     * get clooudPbx UserList
     * @param filterGroupID
     */

    getCloudPbxUserList(filterGroupID) {
        let page = 1;
        let row = 10;

        let data = {
            isCpbx: true,
            groupId: filterGroupID,
            page: page,
            rows: row,
        };

        this._cloudPbxUserService
            .getCloudPbxUserList(data)
            .subscribe((res: any) => {
                if (res.status === true) {
                    this._inventoryService.userCloudPbx.next(res);
                }
            });
    }

    getCloudPbxUserListFilter(filterGroupID) {
        let page = 1;
        let row = 100;

        let data = {
            isCpbx: true,
            groupId: filterGroupID,
            page: page,
            rows: row,
        };

        this._cloudPbxUserService
            .getCloudPbxUserList(data)
            .subscribe((res: any) => {
                if (res.status === true) {
                    this._inventoryService.usersCloudPbxFilterList.next(res);
                }
            });
    }

    // getData(filterGroupID) {
    //     this.isCloudUser = false;
    //     this._inventoryService
    //         .getGroupAndUser(filterGroupID, this.isCloudUser)
    //         .subscribe((responseObj: any) => {
    //             localStorage.setItem(
    //                 'GroupID',
    //                 responseObj?.body.groupDetails.ouid
    //             );
    //             this._inventoryService.Variable.next(responseObj?.body.groups);
    //             this._inventoryService.FilterGroupsVariable.next(
    //                 responseObj?.body.groups
    //             );
    //             this._inventoryService.UserVariable.next(
    //                 responseObj?.body.users
    //             );

    //             if (
    //                 responseObj?.body.groups.length !== null ||
    //                 responseObj?.body.groups.length !== undefined
    //             ) {
    //                 this.NewBoolTable = false;
    //                 this._inventoryService.TableMessage(this.NewBoolTable);
    //             } else {
    //                 this.NewBoolTable = true;
    //                 this._inventoryService.TableMessage(this.NewBoolTable);
    //             }
    //             if (responseObj.status === 'success') {
    //                 this._inventoryService.changeMessage(
    //                     this.FilterSelectedname
    //                 );
    //                 localStorage.setItem('TreeID', this.FilterSelectedname.id);
    //             } else {
    //             }

    //         });
    // }

    getCampaigns(filterGroupID) {
        var Page = 1;
        var Row = 10;

        this._InventoryService
            .getAllCampaignList(Page, Row, filterGroupID)
            .subscribe((responseObj: any) => {
                if (responseObj?.status === true) {
                    this._InventoryService.SubVariable.next(responseObj);
                }
            });
    }

    /**
     * subgroups list
     * @param getSubGroups
     */

    getSUbgroupList(filterGroupID) {
        let page = 1;
        let row = 10;
        let data = {
            isCloudUser: false,
            ouid: filterGroupID,
            page: page,
            rows: row,
        };

        this._subGroupService.getSubgroupsList(data).subscribe((res: any) => {
            localStorage.setItem('GroupID', res.body.groupDetails.ouid);
            this._inventoryService.Variable.next(res);
            this._inventoryService.FilterGroupsVariable.next(res.body.data);
            // this._inventoryService.UserVariable.next(
            //     res['body'].users
            // );

            if (
                res.body.data.length !== null ||
                res.body.data.length !== undefined
            ) {
                this.NewBoolTable = false;
                this._inventoryService.TableMessage(this.NewBoolTable);
            } else {
                this.NewBoolTable = true;
                this._inventoryService.TableMessage(this.NewBoolTable);
            }
            if (res?.status === true) {
                this._inventoryService.changeMessage(this.FilterSelectedname);
                localStorage.setItem('TreeID', this.FilterSelectedname.id);
            } else {
            }
        });
    }

    /**
     * get Users List
     * @param filterGroupID
     */

    getUsersList(filterGroupID) {
        let page = 1;
        let row = 10;

        let data = {
            isCpbx: false,
            groupId: filterGroupID,
            page: page,
            rows: row,
        };

        this._usersService.getUsersList(data).subscribe((res: any) => {
            this._inventoryService.UserVariable.next(res);

            if (
                res.body.data.length !== null ||
                res.body.data.length !== undefined
            ) {
                this.NewBoolTable = false;
                this._inventoryService.TableMessage(this.NewBoolTable);
            } else {
                this.NewBoolTable = true;
                this._inventoryService.TableMessage(this.NewBoolTable);
            }
            if (res.status === true) {
                this._inventoryService.changeMessage(this.FilterSelectedname);
                localStorage.setItem('TreeID', this.FilterSelectedname.id);
            } else {
            }
        });
    }

    getTrackingNumber(filterGroupID) {
        this._InventoryService.CampaignIDD.subscribe((idRes) => {
            var NEwForm = idRes.campaignId;
            // this.LoadBool = true;

            var Page = 1;
            var Row = 10;

            this._InventoryService
                .getAllTrackingNumbers(NEwForm, Page, Row, filterGroupID)
                .subscribe((response: any) => {
                    if (
                        response.body.message !==
                            'There are no tracking numbers in this campaign.' &&
                        response.status === true &&
                        response.body.payload.length !== 0
                    ) {
                        this._InventoryService.TrackingNum.next(response);
                    } else {
                    }
                });
        });
    }
    getAllListTN(filterGroupID) {
        var Page = 1;
        var Row = 10;
        let filter_items =[];
        this._InventoryService
            .getNewTrackingNumberList(Page, Row, filterGroupID , filter_items )
            .subscribe((res) => {
                this.response = res;

                if (this.response?.status === true) {
                    this.getTrackingList = res;
                    this._InventoryService.AllTrackingNum.next(
                        this.getTrackingList
                    );
                } else {
                    var noElement = '';
                    this._InventoryService.AllTrackingNum.next(noElement);
                }
            });
    }

    getCallLogsList: any;
    response: any;
    getCallLogs(filterGroupID) {
        var Page = 1;
        var Row = 10;
        let filter_items =[];

        this._CallLogsService
            .getCallLogs(Page, Row, filterGroupID , filter_items)
            .subscribe((res) => {
                this.response = res;
                if (this.response?.status == true) {
                    this.getCallLogsList = res;

                    this._CallLogsService.callLogsList.next(
                        this.getCallLogsList
                    );
                }
            });
    }

    getValuesB() {}
    checkAll1B() {
        this.unCheckAll();
        for (let i = 0; i < this.treeControlB.dataNodes.length; i++) {
            let isNode = this.allNodesB.find(
                (item) => item === this.treeControlB.dataNodes[i].id
            );
            if (isNode) {
                this.checklistSelectionB.select(this.treeControlB.dataNodes[i]);
            }
            // this.treeControlB.expand(this.treeControlB.dataNodes[i])
        }
    }
    //   currentTextB: any = '';
    filterChangedB(filterText: string) {
        this.breadText = filterText;
        this.filterB(filterText);
        if (filterText) {
            this.treeControlB.expandAll();
        } else {
            this.treeControlB.collapseAll();
            this.treeControlB.expand(this.treeControlB.dataNodes[0]);
            // this.treeControl.expandAll();
        }
    }
    buildFileTreeB(obj: any[], level: string): TreeItemNode[] {
        return obj
            .filter(
                (o) =>
                    (<string>o.code)?.startsWith(level + '.') &&
                    (o.code.match(/\./g) || []).length ===
                        (level.match(/\./g) || []).length + 1
            )
            .map((o) => {
                const node = new TreeItemNode();
                node.item = o.text;
                node.code = o.code;
                node.id = o.id;
                const children = obj.filter((so) =>
                    (<string>so.code)?.startsWith(level + '.')
                );
                if (children && children.length > 0) {
                    node.children = this.buildFileTreeB(children, o.code);
                }
                return node;
            });
    }
    treeDataB: any[] = [];
    filteredTreeDataB;

    public filterB(filterText: string) {
        if (filterText) {
            this.filteredTreeDataB = this.treeDataB.filter(
                (d) =>
                    d.text
                        .toLocaleLowerCase()
                        .indexOf(filterText.toLocaleLowerCase()) > -1
            );
            Object.assign([], this.filteredTreeDataB).forEach((ftd) => {
                let str = <string>ftd.code;
                while (str.lastIndexOf('.') > -1) {
                    const index = str.lastIndexOf('.');
                    str = str.substring(0, index);
                    if (
                        this.filteredTreeDataB.findIndex(
                            (t) => t.code === str
                        ) === -1
                    ) {
                        const obj = this.treeDataB.find((d) => d.code === str);
                        if (obj) {
                            this.filteredTreeDataB.push(obj);
                        }
                    }
                }
            });
        } else {
            this.filteredTreeDataB = this.treeDataB;
        }
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        // file node as children.
        const data = this.buildFileTreeB(this.filteredTreeDataB, '0');
        // Notify the change.
        this._database.dataChange.next(data);
        this.checkAll1B();
    }
}
export class TreeItemNode {
    children: TreeItemNode[];
    item: string;
    code: string;
    id?: any;
    isSelected?: boolean;
    isDisabled?: boolean;
}
export class TreeItemFlatNode {
    item: string;
    level: number;
    expandable: boolean;
    code?: string;
    id?: any;
    isSelected?: boolean;
    isDisabled?: boolean;
}
