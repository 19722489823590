// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const BASE_URL1 = 'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com';
// const BASE_URL = 'https://ds28hr22ba.execute-api.us-west-2.amazonaws.com';

export const environment = {
  production: false,
  apiUrl: 'https://api2dev.usenumbers.ai/',
  link : 'https://dev.usenumbers.ai'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
