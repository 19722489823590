/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    BehaviorSubject,
    filter,
    map,
    Observable,
    of,
    switchMap,
    take,
    tap,
    throwError,
} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SettingService {
    constructor(private _httpClient: HttpClient) {}
    // get profile detalis //
    GetSelectedFirstName = new BehaviorSubject<string>('');
    GetSelectedLastName = new BehaviorSubject<string>('');
    GetSelectedImage = new BehaviorSubject<string>('');
    getProfileDetails() {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.get(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/getProfile',
            { headers }
        );
    }

    // update profile //

    UpdateProfile(obj: any) {
        const formData = new FormData();

        formData.append('File', obj.File);
        formData.append('firstName', obj.FirstName);
        formData.append('lastName', obj.LastName);
        formData.append('status', obj.Status);
        formData.append('language', obj.Language);
        formData.append('timeZone', obj.timeZone);

        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            'https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/updateProfile',
            formData,
            { headers }
        );
    }

    // update password //

    updatePassword(oldPassword: any, newPassword: any) {
        let token = localStorage.getItem('token');
        const headers = new HttpHeaders().set('token', token);
        return this._httpClient.post(
            ' https://w7jxpesqei.execute-api.us-east-1.amazonaws.com/changePassword',
            { oldPassword, newPassword },
            { headers }
        );
    }
}
