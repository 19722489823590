import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TrackingNumberService } from 'app/shared/shared-Services/tracking-number.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TnNumberResolver implements Resolve<any> {

   /**
     * Constructor
     */
   constructor(private _contactsService: TrackingNumberService)
   {
   }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this._contactsService.getCountries();
  }
}
