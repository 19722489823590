<div class="relative flex flex-col w-full">
    <!-- Dismiss button -->
    <!-- <ng-container *ngIf="data.dismissible">
        <div class="absolute top-0 right-0 pt-4 pr-4">
            <button
                mat-icon-button
                [matDialogClose]="undefined">
                <mat-icon
                    class="text-secondary"
                    [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>
    </ng-container> -->

    <!-- Content -->
    <div class="items-center sm:items-start p-8 pb-6 sm:pb-8">
        <!-- Icon -->
        <div class="groupTitle" style="text-align: center">Select Groups</div>
        <div class="items-center w-full mt-4">
            <mat-form-field
                class="fuse-mat-no-subscript pr-2"
                style="width: inherit"
            >
                <!-- <mat-label>Confirm button label</mat-label> -->
                <input
                    [value]="breadText"
                    placeholder="Search groups"
                    (input)="filterChanged($event.target.value)"
                    matInput
                    formControlName="'label'"
                />
            </mat-form-field>
        </div>
        <div
            *ngIf="loader"
            style="
                min-height: 25vh;
                max-height: 60vh;
                align-items: center;
                justify-content: center;
                display: flex;
            "
        >
            <mat-progress-spinner
                *ngIf="loader"
                [diameter]="24"
                [mode]="'indeterminate'"
            ></mat-progress-spinner>
        </div>
        <div class="treeContainer">
            <!-- <input class="groupFilterSearch" [value]="currentText" placeholder="Search groups" (input)="filterChanged($event.target.value)">
              <small class="invalid" *ngIf="isAnyGroup">Please select at least one group.</small> -->
            <mat-tree
                *ngIf="!loader"
                style="overflow-y: scroll; min-height: 20vh; max-height: 60vh"
                [dataSource]="dataSourceTreeB"
                [treeControl]="treeControlB"
            >
                <mat-tree-node
                    *matTreeNodeDef="let node"
                    matTreeNodeToggle
                    matTreeNodePadding
                >
                    <button mat-icon-button disabled></button>
                    <mat-checkbox
                        class="checklist-leaf-node"
                        [checked]="checklistSelectionB.isSelected(node.id)"
                        (change)="
                            todoLeafItemSelectionToggleB(node.id, $event.checked)
                        "
                        >{{ node.item | slice : 0 : 40 }}</mat-checkbox
                    >
                    <!-- <mat-checkbox class="checklist-leaf-node"
                               
                                (change)="todoLeafItemSelectionToggle(node, $event.checked)">{{node.item | slice:0:40}}</mat-checkbox> -->
                </mat-tree-node>

                <mat-tree-node
                    *matTreeNodeDef="let node; when: hasChild"
                    matTreeNodePadding
                >
                    <button
                        mat-icon-button
                        matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.item"
                    >
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" *ngIf="!treeControl.isExpanded(node)" height="20" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                          </svg> -->

                        <mat-icon *ngIf="!treeControlB.isExpanded(node)"
                            >keyboard_arrow_right</mat-icon
                        >

                        <mat-icon *ngIf="treeControlB.isExpanded(node)"
                            >keyboard_arrow_down</mat-icon
                        >
                        <!-- <svg
                            xmlns="http://www.w3.org/2000/svg"
                            *ngIf="treeControl.isExpanded(node)"
                            height="20"
                            width="20"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg> -->
                        <!-- <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon> -->
                    </button>
                    <mat-checkbox
                        [checked]="checklistSelectionB.isSelected(node.id)"
                        (change)="
                            todoLeafItemSelectionToggleB(node.id, $event.checked)
                        "
                        >{{ node.item | slice : 0 : 40 }}</mat-checkbox
                    >
                    <!-- <mat-checkbox
                  (change)="todoLeafItemSelectionToggle(node, $event.checked)">{{node.item | slice:0:40}}</mat-checkbox> -->
                </mat-tree-node>
            </mat-tree>
        </div>

        <div class="groupBtns">
            <!-- <button class="CloseBtn" (click)="modal.close('Close click')">Close</button>
            <button class="ApplyBtn" (click)="onApplyGroups();">Apply</button> -->
        </div>
    </div>

    <!-- Actions -->
    <ng-container>
        <div
            class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10"
        >
            <!-- Cancel -->
            <ng-container>
                <button mat-stroked-button [matDialogClose]="'cancelled'">
                    Close
                </button>
            </ng-container>

            <!-- Confirm -->
            <ng-container>
                <button
                    mat-flat-button
                    [color]="'primary'"
                    (click)="getGroupsB()"
                    [matDialogClose]="'confirmed'"
                >
                    Apply
                </button>
            </ng-container>
        </div>
    </ng-container>
</div>
