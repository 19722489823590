import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { CallLogsService } from 'app/modules/admin/call-logs/call-logs.service';
import { InventoryService } from 'app/modules/admin/ecommerce/inventory/inventory.service';
import { GroupsDatabaseService } from 'app/shared/groups-tree/groups-database.service';
import { GroupsTreeComponent } from 'app/shared/groups-tree/groups-tree.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-bread-crumbs',
    templateUrl: './bread-crumbs.component.html',
    styleUrls: ['./bread-crumbs.component.scss'],
})
export class BreadCrumbsComponent implements OnInit {
    configForm: FormGroup;
    Checkemail: string;
    users$: any[];
    groupList$: any[];
    PermissionGroups: any[];
    SelectChildern: any[];
    WholeRes: any;
    MainLoader: boolean;
    TreeParent: any;
    SelectedGroupFromTree: any;
    // selectGroupList: boolean;
    NewShow: boolean;
    isLoading: boolean;
    BreadGroup2: string;
    BreadGroup3: string;
    BreadGroup4: string;
    BreadGroup5: string;

    @Input() item: boolean;
    response: any;
    CallLogsList: any;
    Lengthproduslist: any;
    orgprodusList: any;
    NoCampGroups: boolean;
    filterItems: any[] = [];
    isCloudUser: boolean = false;
    sub = new Subject();
    selected_data: any;
    selected_groups: any;
    breadLevels: any = [];
    constructor(
        private _inventoryService: InventoryService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _CallLogsService: CallLogsService,
        private dialog: MatDialog,
        private _database: GroupsDatabaseService
    ) {
        this.configForm = this._formBuilder.group({
            title: 'Remove Group',
            message:
                'Are you sure you want to remove this contact permanently? <span class="font-medium">This action cannot be undone!</span>',
            icon: this._formBuilder.group({
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'warn',
            }),
            actions: this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show: true,
                    label: 'Remove',
                    color: 'warn',
                }),
                cancel: this._formBuilder.group({
                    show: true,
                    label: 'Cancel',
                }),
            }),
            dismissible: true,
        });
    }
  
    ngOnInit(): void {
        this.getData();
        this._inventoryService.MainGroupName.subscribe((res) => {
            if (res) {
                this.TreeParent = res;
            }
        });
        this._inventoryService.GroupLevel2.subscribe((res) => {
            this.BreadGroup2 = '';
            if (res === '') {
                this.BreadGroup2 = undefined;
            } else {
                this.BreadGroup2 = res;
            }
        });
        this._inventoryService.GroupLevel3.subscribe((res) => {
            this.BreadGroup3 = '';
            if (res === '') {
                this.BreadGroup3 = undefined;
            } else {
                this.BreadGroup3 = res;
            }
        });
        this._inventoryService.GroupLevel4.subscribe((res) => {
            this.BreadGroup4 = '';
            if (res === '') {
                this.BreadGroup4 = undefined;
            } else {
                this.BreadGroup4 = res;
            }
        });
        this._inventoryService.GroupLevel5.subscribe((res) => {
            this.BreadGroup5 = '';
            if (res === '') {
                this.BreadGroup5 = undefined;
            } else {
                this.BreadGroup5 = res;
            }
        });
        // this._inventoryService.GroupLevel.subscribe((res) => {
        //     this.breadLevels = res.item;
        //     console.log(this.breadLevels, 'this.breadLevels');
        // });
    }
    
    ngOnDestroy() {
        this.sub.next('');
        this.sub.complete();
    }
    getData() {
        this.isCloudUser = this.item;
        let ouid = localStorage.getItem('TreeID');
        this._inventoryService
            .getGroupAndUser(ouid, this.isCloudUser)
            .subscribe((responseObj: any) => {
                if (responseObj.status === true) {
                    this.SelectChildern = [...responseObj?.body.children];

                    let NewChild = JSON.stringify(this.SelectChildern);

                    localStorage.setItem('Child', NewChild);
                    this.SelectChildern = this.WholeRes;
                    this._changeDetectorRef.markForCheck();

                    this.TreeParent = responseObj?.body.groupDetails.groupName;
                    this._inventoryService.MainGroupName.next(this.TreeParent);
                    this._inventoryService.GetSelectedGroupname =
                        this.SelectedGroupFromTree;
                } else {
                    if (
                        responseObj.body.message === 'Authentication failed.' ||
                        responseObj.body.message === 'Token not provided.'
                    ) {
                        this._router.navigateByUrl('/sign-in');
                        localStorage.removeItem('token');
                        this.MainLoader = false;
                        localStorage.removeItem('Group');
                        localStorage.removeItem('role');
                        this._changeDetectorRef.markForCheck();
                        this.NewShow = true;
                    }
                }
                this.isLoading = false;
            });
    }
   
    openConfirmationDialog() {
        this._changeDetectorRef.markForCheck();

        const dialogRef = this._fuseConfirmationService.open(
            this.configForm.value
        );

        dialogRef.afterClosed().subscribe((result) => {});
    }
}
