import { BotsModule } from './modules/admin/bot/bot.module';
/* eslint-disable arrow-parens */
import { Route } from '@angular/router';
import { AuthenticationGuard } from 'app/_guards/authentication.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AdminGuard } from './_guards/admin.guard';
import { TnNumberResolver } from './modules/admin/Tracking-Numbers/tn-number.resolver';
import { DniSettingsComponent } from './modules/admin/ui/dni-settings/dni-settings.component';
import { DniContactUsComponent } from './modules/admin/ui/dni-contact-us/dni-contact-us.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: '/sign-in' },
    // {path : 'dashboardComp', component : DashboardComponent},
    // {path : 'groupcomp', component : GroupsComponent},
    // {path : 'teamcomp', component : KeywordListComponent},
    // {path : 'recording', component : RecordingsTableComponent},
    // Redirect signed in user to the '/dashboards/project'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    // {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboards/project'},

    // Auth routes for guests
    {
        path: '',
        // canActivate: [AuthenticationGuard],
        canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
            {
                path: 'forgot-otp',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
            {
                path: 'dni',
                component: DniSettingsComponent
            },
            {
                path: 'dni-contact/:id',
                component: DniContactUsComponent
            }
        ],
    },


    // Auth routes for authenticated users
    {
        path: '',
        // canActivate: [AuthenticationGuard],
        // canActivateChild: [AuthenticationGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    // {
    //     path: '',
    //     component  : LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     },
    //     children   : [
    //     ]
    // },

    // Admin routes
    {
        path: '',
        canActivate: [AuthenticationGuard],
        // canActivateChild: [AuthenticationGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            // Dashboards
            {
                path: 'dashboards',
                children: [
                    {
                        path: 'project',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/dashboards/project/project.module'
                            ).then((m) => m.ProjectModule),
                    },
                    // {path: 'analytics', component : GroupsComponent},
                    {
                        path: 'finance',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/dashboards/finance/finance.module'
                            ).then((m) => m.FinanceModule),
                    },
                    {
                        path: 'crypto',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/dashboards/crypto/crypto.module'
                            ).then((m) => m.CryptoModule),
                    },
                    {
                        path: 'superAdmin',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/dashboards/super-admin/super-admin.module'
                            ).then((m) => m.SuperAdminModule),
                    },
                ],
            },

            {
                path: 'apps',
                resolve: {
                    tnNumber: TnNumberResolver,
                },
                children: [
                    {
                        path: '',
                        canActivate: [AdminGuard],
                        loadChildren: () =>
                            import(
                                'app/modules/admin/ecommerce/ecommerce.module'
                            ).then((m) => m.ECommerceModule),
                    },
                    {
                        path: '',
                        canActivate: [AdminGuard],
                        loadChildren: () =>
                            import(
                                'app/modules/admin/Campaign/ecommerce.module'
                            ).then((m) => m.ECommerceModule),
                    },
                    {
                        path: '',
                        canActivate: [AdminGuard],
                        loadChildren: () =>
                            import(
                                'app/modules/admin/Recording-list/recordinglist.module'
                            ).then((m) => m.RecordingListModule),
                    },
                    {
                        path: '',
                        canActivate: [AdminGuard],
                        resolve: {
                            countries: TnNumberResolver
                        },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/Tracking-Numbers/tracking-number/tracking-number.module'
                            ).then((m) => m.TrackingNumberModule),
                    },
                    {
                        path: '',
                        canActivate: [AdminGuard],
                        loadChildren: () =>
                            import(
                                'app/modules/admin/call-logs/call-logs.module'
                            ).then((m) => m.CallLogsModule),
                    },
                    {
                        path: '',
                        canActivate: [AdminGuard],
                        loadChildren: () =>
                            import(
                                'app/modules/admin/webhook-logs/webhook-logs.module'
                            ).then((m) => m.WebhookLogsModule),
                    },
                    {
                        path: '',
                        canActivate: [AdminGuard],
                        loadChildren: () =>
                            import(
                                'app/modules/admin/webhook/webhook.module'
                            ).then((m) => m.WebhookModule),
                    },
                    {
                        path: '',
                        canActivate: [AdminGuard],
                        loadChildren: () =>
                            import(
                                'app/modules/admin/bot/bot.module'
                            ).then((m) => m.BotsModule),
                    },
                    {
                        path: '',
                        canActivate: [AdminGuard],
                        loadChildren: () =>
                            import(
                                'app/modules/admin/cloud-pbx/cloud-pbx.module'
                            ).then((m) => m.CloudPbxModule),
                    },
                    {
                        path: 'profile',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/pages/settings/settings.module'
                            ).then((m) => m.SettingsModule),
                    },
                ],
            },
        ],
    },
];
