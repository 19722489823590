import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CloudPbxUserService {

  cloudPbxpaginationList = new BehaviorSubject<any>({});
  
  constructor(private http: HttpClient) { }


  getCloudPbxUserList(data){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('token', token);
    return this.http.post( 'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getUsersCheck', data, { headers });
  }
}
