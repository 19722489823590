import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseValidators } from '@fuse/validators';
import { InventoryService } from 'app/modules/admin/ecommerce/inventory/inventory.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  ChangePassword: FormGroup;
  ChangeCurrr: boolean = false;
  updateUserouid: number;

  constructor(private _formBuilder: FormBuilder, private _inventoryService: InventoryService,
    ) { }

  ngOnInit(): void {

     // Change Password
     this.ChangePassword = this._formBuilder.group(
      {
          Oldpassword: ['', Validators.required],
          Newpassword: ['', Validators.required],
          passwordConfirm: ['', Validators.required],
      },
      {
          validators: FuseValidators.mustMatch(
              'Newpassword',
              'passwordConfirm'
          ),
      }
  );
  }

  OnChangePassword() {
    this._inventoryService
        .updateUserPassword(
            this.ChangePassword.value.Oldpassword,
            this.ChangePassword.value.Newpassword,
            this.updateUserouid
        )
        .subscribe((res) => {
            if (res['status'] === 'success') {
                this.ChangeCurrr = false;
                // this.isAlertFuse = true;
                // this.modalService.dismissAll();
                setTimeout(() => {
                    // this.isAlertFuse = false;
                    // this._changeDetectorRef.markForCheck();
                }, 1000);
                this.ChangePassword.reset();
            } else {
                if (res['body'] === 'NOT MATCHED') {
                    this.ChangeCurrr = true;
                }
            }
        });
}


passwordLengthError : boolean;

    valuechange(passwordStr){
        if(passwordStr.target.value ){
            if(!this.validatePassword(passwordStr.target.value)){
                this.passwordLengthError = true;
            } else {
                this.passwordLengthError = false;
            }
        } 

        if(!passwordStr.target.value){
            this.passwordLengthError = false;
        }
    }

    validatePassword(password) {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&=,./;:'"#^`~><|{}+)(_-])[A-Za-z\d@$!%*?&=,./;:`~'"#^><|{}+)(_-]{8,15}$/;
        return passwordPattern.test(password);
    }

}
