/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { Filter } from '../shared-filter/model/filter.model';
import { BehaviorSubject, map, Observable, Subject, tap } from 'rxjs';
import { SharedService } from '../shared.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Contact, Country } from 'app/modules/admin/Tracking-Numbers/create-new-tracking-number/country.type';
import {environment} from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TrackingNumberService {

  public _countries: BehaviorSubject<Country[] | null> = new BehaviorSubject(null);
  public _contacts: BehaviorSubject<Contact[] | null> = new BehaviorSubject(null);
  public _contact: BehaviorSubject<Contact | null> = new BehaviorSubject(null);

  apiUrl =environment.apiUrl 

  filterObj: Filter[] = [];
  refreshData = new Subject<void>();
  group_selected: string;
  start_time: Date;
  end_time: Date;

  public _allCountries: Country[];
  // private _allStates: State[];

  constructor(private sharedService: SharedService, private http: HttpClient) { }


  /**
   * group filters
   */

 setGroupFilter() {
    this.filterObj = this.filterObj.filter(el => el.filter_name !== 'Group');
    const group_filter: Filter = {
      filter_name: 'Group',
      filter_items: [
        {
          action: 'is',
          value: this.group_selected,
        },
      ],
    };
    this.filterObj.push(group_filter);
  }

  initDates(duration = 7, time_unit = 'days') {
    const saved_filters = sessionStorage.getItem('tracking_number_page_savedFilters');
    if (saved_filters) {
      const saved_filters_parsed = JSON.parse(saved_filters);
      this.start_time = new Date(saved_filters_parsed['date_data'][1]);
      this.end_time = new Date(saved_filters_parsed['date_data'][2]);
    } else {
      const init_times = this.sharedService.getStartEndTimes(duration, time_unit);
      this.start_time = init_times.start_time;
      this.end_time = init_times.end_time;
    }
  }



  getTnNumbers(post){
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('token', token);
    return this.http.post( `${this.apiUrl}number/getAreaTn`, post, { headers });
  }


   /**
     * Getter for countries
     */
   get countries$(): Observable<Country[]>
   {
       return this._countries.asObservable();
   }

      /**
     * Getter for contact
     */
      get contact$(): Observable<Contact>
      {
          return this._contact.asObservable();
      }
  
      /**
       * Getter for contacts
       */
      get contacts$(): Observable<Contact[]>
      {
          return this._contacts.asObservable();
      }

         /**
     * Get contacts
     */
    getContacts(): Observable<Contact[]>
    {
        return this.http.get<Contact[]>('api/apps/contacts/all').pipe(
            tap((contacts) => {
                this._contacts.next(contacts);
            })
        );
    }

     /**
     * Get countries
     */
     getCountries(): Observable<Country[]>
     {
         return this.http.get<Country[]>('api/apps/contacts/countries').pipe(
             tap((countries) => {
                 this._countries.next(countries);
             })
         );
     }


     get allCountries() {
      return this._allCountries;
    }
  
    // get allStates() {
    //   return this._allStates;
    // }
  
    initializeAllCountries(): Observable<Country[]> {
      console.log("Initializing");
      let countriesObservable$ = this.http.get<Country[]>('api/apps/contacts/countries');
  
      return countriesObservable$.pipe(
        map(countries => {
          this._allCountries = countries;
          return countries;
        })
      )
    }
  
    // initializeAllStates(): Observable<State[]> {
    //   let statesObservable$ = this.http.get<State[]>(`${baseUrl}/states`);
  
    //   return statesObservable$.pipe(
    //     map(states => {
    //       this._allStates = states;
    //       return states;
    //     })
    //   )
    // }
  
    private handleError(error: HttpErrorResponse) {
      console.error("Problem trying to retrieve geo array!", error);
    };
  
    findCountryCodeByTwoLetterAbbreviation(abbreviation: string): string {
      let code: string = null;
  
      if (!this.allCountries) {
        this.initializeAllCountries().subscribe(
          (countries: Country[]) => code = this.findCountryCode(abbreviation)
        )
      } else {
        code = this.findCountryCode(abbreviation);
      }
  
      return code;
    }
  
    private findCountryCode(abbreviation: string): string {
      let code = null;
  
      if (abbreviation && abbreviation.trim().length > 0) {
        for (let i = 0; i < this.allCountries.length; i++) {
          let country = this.allCountries[i];
          if (country.iso.toUpperCase() == abbreviation.toUpperCase()) {
            code = country.code;
            break;
          }
        }
      }
  
      console.log("Country code is " + code);
      return code;
    }


}
