export enum HttpCallStatus {
    success = 'success',
    failure = 'failure',
}

export enum AuthRoles {
    admin = 'Admin',
}

export enum FormControlNameEnums {
    overflowNumbers = 'overflowNumbers',
    flagValues = 'flagValues',
    singleNumberData = 'singleNumberData'
}
