<div class="flex-col w-full">
    <app-bread-crumbs [item]="isCloudUser"></app-bread-crumbs>

    <div class="overflow-auto">
        <div class="ml-8 mr-8 mt-2 flex justify-between items-center">
            <div class="text-4xl font-semibold tracking-tight mb-3">
                Number Type
            </div>
            <mat-icon routerLink="/apps/tracking-number" style="cursor: pointer" svgIcon="close"></mat-icon>
        </div>
        <div class="flex items-center justify-between w-full px-8 md:px-8 relative mb-2 mt-2">
            <div *ngIf="!showEdit"
                class="flex items-center mt-2 sm:mb-2 p-0.5 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700">
                <button [disabled]="isDisabled" class="px-6" [ngClass]="{
                        'bg-card pointer-events-none shadow': yearlyBilling,
                        'bg-transparent': !yearlyBilling
                    }" mat-flat-button (click)="yearlyBilling = true" disableRipple>
                    Single Number
                </button>
                <!-- <button
                    [disabled]="isDisabled"
                    class="px-6"
                    [ngClass]="{
                        'bg-card pointer-events-none shadow': !yearlyBilling,
                        'bg-transparent': yearlyBilling
                    }"
                    mat-flat-button
                    (click)="yearlyBilling = false"
                    disableRipple
                >
                    Number Poolsss
                </button> -->
            </div>

            <!-- for edit -->
            <div *ngIf="showEdit"
                class="flex items-center mt-2 sm:mb-2 p-0.5 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700">
                <button [disabled]="isDisabled" class="px-6" [ngClass]="{
                        'bg-card pointer-events-none shadow': singleNumber,
                        'bg-transparent': !singleNumber
                    }" mat-flat-button disableRipple>
                    Single Number
                </button>
                <!-- <button
                    [disabled]="isDisabled"
                    class="px-6"
                    [ngClass]="{
                        'bg-card pointer-events-none shadow': !singleNumber,
                        'bg-transparent': singleNumber
                    }"
                    mat-flat-button
                    disableRipple
                >
                    Number Pool
                </button> -->
            </div>
            <div class="flex items-center ml-6" *ngIf="showEdit">
                <div>
                    <button class="hidden sm:inline-flex ml-3 w-32" mat-flat-button [color]="'primary'"
                        (click)="triggerModal(trackingNumberDel)">
                        <mat-icon class="icon-size-5" svgIcon="delete_outline"></mat-icon>
                        <span class="ml-2">Delete</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- Single Number -->
        <div *ngIf="yearlyBilling">
            <form [formGroup]="singleNumberForm"
                class="flex flex-col mt-2 p-8 pt-4 pb-4 bg-card rounded-2xl overflow-hidden">
                <div class="flex justify-between justify-items-center">
                    <div style="width: 45%">
                  

                        <mat-form-field class="w-full">
                            <mat-label>NPA-NXX (Area code) </mat-label>
                            <input matInput id="areacode" name="areacode" maxlength="3" max="3" type="text"
                                [readonly]="readOnly" (keypress)="keyPressNumeric($event)" (input)="
                                    getSuggesttnNumbers($event.target.value)
                                " [formControlName]="'areacode'" [placeholder]="'Please enter your Area code'" />
                            <mat-error *ngIf="
                                    singleNumberForm
                                        .get('areacode')
                                        .hasError('required')
                                ">
                                Please enter the Area code
                            </mat-error>
                        </mat-form-field>

                        <!-- trackign number  in create case-->
                        <mat-form-field class="fuse-mat-dense flex-auto w-full" *ngIf="!showEdit">
                            <mat-label>Tracking Number</mat-label>
                            <mat-select [formControlName]="'number'">
                                <mat-option class="mt-1" [readonly]="readOnly" appPhoneMask
                                    *ngFor="let item of tnSuggestedNumber" [value]="item.number">{{ item.number
                                    }}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="
                                    singleNumberForm
                                        .get('number')
                                        .hasError('required') &&
                                    this.tnSuggestedNumber.length !== 0
                                ">
                                Please select Tracking number
                            </mat-error>

                            <mat-error *ngIf="this.tnSuggestedNumber.length == 0">
                                Tracking numbers not found against this area
                                code
                            </mat-error>
                        </mat-form-field>


                        <!-- tracking number in edit case -->
                        <mat-form-field class="fuse-mat-dense flex-auto w-full" *ngIf="showEdit">
                            <mat-label>Tracking Number</mat-label>
                            <input matInput [readonly]="readOnly" id="numberName" type="text"
                                [formControlName]="'number'" />
                        </mat-form-field>
                        
                        
                        <mat-form-field class="w-full">
                            <mat-label>Tracking number name</mat-label>
                            <input matInput  id="numberName" type="text"
                                [formControlName]="'numberName'"
                                maxlength="30"
                                onkeypress="return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))"
                                [placeholder]="
                                    'Please enter your Tracking Number Name'
                                " />
                            <mat-error *ngIf="
                                    singleNumberForm
                                        .get('numberName')
                                        .hasError('required')
                                ">
                                Please enter the tracking number name
                            </mat-error>
                            <mat-error *ngIf="singleNumberForm.get('numberName').hasError('maxlength')">
                                Tracking number name must not exceed 30 characters
                              </mat-error>
                        </mat-form-field>

                
                    </div>
                    <div style="width: 45%">
                        <div class="w-full flex items-center mt-5 pt-2 mb-5">
                            <mat-label>Active</mat-label>
                            <mat-slide-toggle [formControlName]="'trackingStatus'" [color]="'primary'"
                                class="ml-2 pl-5"></mat-slide-toggle>
                        </div>
                        <div class="w-full flex items-center mt-5 pt-5 mb-5">
                            <mat-label>Record Call</mat-label>
                            <mat-slide-toggle [formControlName]="'recordCall'" [color]="'primary'"
                                class="ml-2 pl-5"></mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <!-- Routing Options -->
        <div class="ml-8 mt-2 mr-8" style="
                display: flex;
                justify-content: space-between;
                align-items: center;
            ">
            <div class="text-4xl font-semibold tracking-tight">
                Routing Options
            </div>
        </div>

        <!-- routing Options Button -->
        <div class="flex items-center justify-between w-full px-8 md:px-8 relative mb-2">
            <!-- For Large Screen -->
            <div
                class="onHide hidden lg:block xl:block 2xl:block md:flex items-center mt-2 sm:mb-2 p-0.5 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700">
                <button class="px-6" [ngClass]="{
                        'bg-card pointer-events-none shadow': Single_Phone,
                        'bg-transparent': !Single_Phone
                    }" mat-flat-button (click)="
                        Single_Phone = true;
                        Round_Robin = false;
                        IVR = false;
                        Simultaneous = false
                    " disableRipple>
                    Single
                </button>

                <button class="px-6" [ngClass]="{
                        'bg-card pointer-events-none shadow': IVR,
                        'bg-transparent': !IVR
                    }" mat-flat-button (click)="
                        Single_Phone = false;
                        IVR = true;
                        Round_Robin = false;
                        Simultaneous = false;
                        IVR = true
                    " disableRipple>
                    IVR
                </button>
            </div>

            <!-- small screen dropdown for routing options -->
            <mat-form-field class="w-80 block md:hidden lg:hidden">
                <mat-select [(ngModel)]="selectedOption">
                    <!-- <mat-option
                        (click)="
                            Single_Phone = false;
                            Round_Robin = true;
                            IVR = false;
                            Simultaneous = false;
                            IVR = false
                        "
                        value="roundRobin"
                        >Round Robin</mat-option
                    > -->
                    <!-- <mat-option
                        (click)="
                            Single_Phone = false;
                            Round_Robin = false;
                            IVR = false;
                            Simultaneous = true
                        "
                        value="simultaneous"
                        >Simultaneous</mat-option
                    > -->
                    <mat-option (click)="
                            Single_Phone = false;
                            Round_Robin = false;
                            IVR = true;
                            Simultaneous = false
                        " value="ivr">IVR</mat-option>
                    <mat-option (click)="
                            Single_Phone = true;
                            Round_Robin = false;
                            IVR = false;
                            Simultaneous = false
                        " value="singlePnone">Single Phone</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!--  -->

        <!-- Single Number Form -->
        <form *ngIf="Single_Phone" [formGroup]="createSingleForm" class="ml-8 pb-4 justify-items-center">
            <div>
                <p style="
                        color: #787f8a;
                        font-weight: 500;
                        font-size: 14px;
                        margin-bottom: 3px;
                    ">
                    Ring to phone number
                </p>
                <!-- <div>
                    <mat-form-field class="w-60">
                        <input
                            ng2TelInput
                            (countryChange)="onCountryChange($event)"
                            ng2TelInput
                            [ng2TelInputOptions]="telOptions"
                            matInput
                            maxlength="13"
                            max="13"
                            type="text"
                            (keypress)="keyPressNumeric($event)"
                            [formControlName]="'phonenumber'"
                            [placeholder]="'Phone number'"
                        />
                    </mat-form-field>

                </div> -->

                <ng-container>
                    <div class="relative flex">
                        <mat-form-field class="w-[303px] size">
                            <!-- <mat-label *ngIf="first">Phone</mat-label> -->
                            <input matInput maxlength="14" [formControlName]="'phonenumber'"
                                [countryCode]="selectedCountryCode" appPhoneNumMask [placeholder]="'Phone number'" />
                            <mat-select class="mr-1.5" [formControlName]="'country'" matPrefix>
                                <mat-select-trigger>
                                    <span class="flex items-center">
                                        <span class="hidden sm:flex w-6 h-4 mr-1 overflow-hidden" [style.background]="
                                                'url(\'/assets/images/apps/contacts/flags.png\') no-repeat 0 0'
                                            " [style.backgroundSize]="
                                                '24px 3876px'
                                            " [style.backgroundPosition]="
                                                getCountryByIso(
                                                    createSingleForm.get(
                                                        'country'
                                                    ).value
                                                ).flagImagePos
                                            "></span>
                                        <span class="sm:mx-0.5 font-medium text-default">{{
                                            getCountryByIso(
                                            createSingleForm.get(
                                            "country"
                                            ).value
                                            ).code
                                            }}</span>
                                    </span>
                                </mat-select-trigger>
                                <ng-container *ngFor="
                                        let country of countries;
                                        trackBy: trackByFn
                                    ">
                                    <mat-option [value]="country.iso" (click)="onCountryChange(country, i)">
                                        <span class="flex items-center">
                                            <span class="w-16 h-4 overflow-hidden" [style.background]="
                                                    'url(\'/assets/images/apps/contacts/flags.png\') no-repeat 0 0'
                                                " [style.backgroundSize]="
                                                    '24px 3876px'
                                                " [style.backgroundPosition]="
                                                    country.flagImagePos
                                                "></span>
                                            <span class="ml-2">{{
                                                country.name
                                                }}</span>
                                            <span class="ml-2 font-medium">{{
                                                country.code
                                                }}</span>
                                        </span>
                                    </mat-option>
                                </ng-container>
                            </mat-select>

                            <mat-error *ngIf="
                                    createSingleForm
                                        .get('phonenumber')
                                        .hasError('required')
                                ">
                                Please enter the ring to phone number
                            </mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>

                <div>
                    <div class="flex justify-start items-center">
                        <div class="w-full basis-1/3 flex items-center mt-5 pt-5 mb-5">
                            <!-- <mat-label>Play Whisper Before Call</mat-label> -->
                            <!-- <mat-slide-toggle
                                [color]="'primary'"
                                class="mr-6 pl-5"
                                [formControlName]="
                                    'playWhisperBeforeCallSingle'
                                "
                            ></mat-slide-toggle> -->
                        </div>
                        <div *ngIf="
                                this.createSingleForm.value
                                    .playWhisperBeforeCallSingle == true
                            " class="w-full basis-1/3 flex justify-start items-center mt-5 pt-5 mb-5">
                            <div class="md:flex sm:block items-center">
                                <mat-form-field class="md:w-60 sm:w-10">
                                    <textarea [formControlName]="
                                            'playWhisperBeforeCallTextSingle'
                                        " (input)="
                                            whisperFunction($event.target.value)
                                        " matInput name="whisper" id="textarea" cols="50" rows="5"></textarea>
                                </mat-form-field>
                                <div class="flex md:block">
                                    <button (click)="
                                            openDialogWithTemplateRef(
                                                playWhisperSingleCall
                                            )
                                        ">
                                        <mat-icon color="primary" class="cursor-pointer ml-6 icon-size-8"
                                            svgIcon="feather:plus-circle"></mat-icon>
                                    </button>

                                    <!-- Play Whisper Before Call -->
                                    <ng-container>
                                        <div class="justify-center items-center">
                                            <ng-template #playWhisperSingleCall>
                                                <div class="flex justify-between">
                                                    <h2 matDialogTitle class="w-100">
                                                        Choose Prompt
                                                    </h2>
                                                    <mat-icon class="icon-size-5" matDialogClose
                                                        [svgIcon]="'close'"></mat-icon>
                                                </div>
                                                <mat-dialog-content>
                                                    <ng-container>
                                                        <ng-container>
                                                            <div class="grid">
                                                                <!-- Header -->
                                                                <div class="inventory-grid z-10 sticky top-0 flex py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                                                    matSort mat-table matSortDisableClear>
                                                                    <div style="
                                                                            width: 250px;
                                                                        " class="pr-5">
                                                                        File
                                                                        Name
                                                                    </div>
                                                                    <div style="
                                                                            width: 350px;
                                                                        " class="text-center pl-5">
                                                                        Audio
                                                                    </div>
                                                                    <div style="
                                                                            width: 100px;
                                                                        " class="text-center pl-5">
                                                                        Delete
                                                                    </div>
                                                                </div>

                                                                <ng-container>
                                                                    <ng-container>
                                                                        <div *ngIf="!this.createSingleForm.value.singleCallUrl"
                                                                            class="w-full inventory-grid flex justify-center items-center py-3 px-6 md:px-8 border-b">
                                                                            <p matDialogText>
                                                                                No
                                                                                Audio
                                                                                Files
                                                                            </p>
                                                                        </div>
                                                                        <div class="cursor-pointer inventory-grid flex items-center py-3 px-6 md:px-8"
                                                                            style="
                                                                                font-size: 14px;
                                                                            ">
                                                                            <div style="
                                                                                    width: 250px;
                                                                                " class="truncate cursor-pointer pr-5"
                                                                                *ngIf="this.createSingleForm.value.singleCallUrl">
                                                                                {{
                                                                                fileNameSingleCall[2]
                                                                                }}
                                                                            </div>
                                                                            <div style="
                                                                                    width: 350px;
                                                                                "
                                                                                class="truncate cursor-pointer flex justify-between items-center pl-5">
                                                                                <audio controls style="
                                                                                        width: 100%;
                                                                                    " *ngIf="
                                                                                        this
                                                                                            .createSingleForm
                                                                                            .value
                                                                                            .singleCallUrl
                                                                                    ">
                                                                                    <source [src]="
                                                                                            this
                                                                                                .createSingleForm
                                                                                                .value
                                                                                                .singleCallUrl
                                                                                        " type="audio/mp3" />
                                                                                </audio>
                                                                            </div>
                                                                            <div style="
                                                                                    width: 100px;
                                                                                " *ngIf="
                                                                                    this
                                                                                        .createSingleForm
                                                                                        .value
                                                                                        .singleCallUrl
                                                                                ">
                                                                                <mat-icon (click)="
                                                                                        deleteFileButton(
                                                                                            items
                                                                                        )
                                                                                    " aria-hidden="false" color="warn"
                                                                                    fontIcon="delete"
                                                                                    class="ml-5"></mat-icon>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </mat-dialog-content>
                                                <mat-dialog-actions>
                                                    <div class="flex justify-between items-center w-full">
                                                        <input #csvInput hidden="true" type="file" accept=".wav"
                                                            [formControlName]="
                                                                'singleCallAudio'
                                                            " onclick="this.value=null" (change)="
                                                                InputChange(
                                                                    $event
                                                                )
                                                            " />
                                                        <button mat-flat-button color="primary" (click)="
                                                                csvInput.click()
                                                            " [hidden]="
                                                                this
                                                                    .createSingleForm
                                                                    .value
                                                                    .singleCallUrl
                                                            ">
                                                            <mat-icon svgIcon="mat_outline:file_upload"></mat-icon>
                                                            &nbsp; Upload Audio
                                                        </button>

                                                        <button mat-flat-button color="primary" (click)="SaveFileInput()" [disabled]="!this.createSingleForm.value.singleCallUrl
                                                            " [matDialogClose]="
                                                                closeDialog ==
                                                                true
                                                            ">
                                                            Save
                                                        </button>
                                                    </div>
                                                </mat-dialog-actions>
                                            </ng-template>
                                        </div>
                                    </ng-container>
                                    <br />
                                    <!-- <button (click)="PlayTextToSpeech()">
                                        <mat-icon
                                            color="primary"
                                            class="mat-icon cursor-pointer ml-6 icon-size-8"
                                            svgIcon="mat_solid:play_circle"
                                        ></mat-icon>


                                    </button> -->

                                    <button mat-icon-button color="primary"
                                        aria-label="Example icon button with a menu icon" class="ml-5 icon-size-8"
                                        (click)="PlayTextToSpeech()">
                                        <!-- <mat-icon
                                        *ngIf="
                                            isPlayAudio
                                        "
                                        >play_circle_outline</mat-icon
                                    >
                                    <mat-icon
                                        *ngIf="
                                            !isPlayAudio
                                        "
                                        >pause_circle_outline</mat-icon
                                    > -->

                                        <mat-icon *ngIf="!isPlayAudio" color="primary"
                                            class="mat-icon cursor-pointer icon-size-8"
                                            svgIcon="mat_solid:pause_circle"></mat-icon>

                                        <mat-icon *ngIf="isPlayAudio" color="primary"
                                            class="mat-icon cursor-pointer icon-size-8"
                                            svgIcon="mat_solid:play_circle"></mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="basis-1/2"></div>
                    </div>
                </div>
            </div>
        </form>

        <!-- IVR   -->

        <!-- when click on IVR TAB -->
        <div *ngIf="IVR" class="p-8 pt-0 bg-card rounded-2xl overflow-hidden">
            <div>
                <div>
                    <form [formGroup]="ivrFormArray">
                        <div formArrayName="overFlowSection">
                            <div *ngFor=" let Ivr of ivrArray.controls; let i = index">
                                <div [formGroupName]="i" [ngStyle]="{'background-color': getColor(i)}" class="p-3 pb-3" style="border-bottom: 0.2px solid  rgb(208, 208, 208);">
                                    <!-- AUDIO UPLOAD  -->
                                    <div class="mt-4">
                                        <div class="flex lg:items-center sm:items-start alignment">
                                            <div>
                                                <!-- plus icon -->
                                                <button (click)="openDialogWithTemplateRef(playWhisperBeforeCall)" 
                                                        class="custom-upload-button" [ngStyle]="choosePromptReplacer ? { 'min-width':'100%' }: {'min-width': '165%'}">
                                                    <mat-icon color="primary" class="cursor-pointer ml-6 icon-size-2"
                                                        svgIcon="feather:upload-cloud"></mat-icon>
                                                    {{ choosePromptReplacer || "Choose Prompt"}}
                                                </button>
                                                <!-- plus icon end -->
                                                <!-- Choose Prompt dialog modal-->
                                                <ng-template #playWhisperBeforeCall>
                                                    <div class="flex justify-between">
                                                        <h2 matDialogTitle class="w-100">Choose Prompt</h2>
                                                        <mat-icon class="icon-size-5" matDialogClose [svgIcon]="'close'"></mat-icon>
                                                    </div>
                                                    <!-- dialog when click on upload -->
                                                    <!-- FOR IVR -->
                                                    <mat-dialog-content>
                                                        <ng-container>
                                                            <div class="grid">
                                                                <div
                                                                    class="inventory-grid z-10 sticky top-0 flex py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5">
                                                                    <div style="width: 250px;" class="pr-5"> File Name</div>
                                                                    <div style="width: 350px;" class="text-center pl-5">
                                                                        Audio
                                                                    </div>
                                                                    <div style="width: 100px;" class="text-center pl-5">
                                                                        Delete
                                                                    </div>
                                                                </div>
                                                                <ng-container *ngIf="audioFiles?.length === 0">
                                                                    <div class="w-full inventory-grid flex justify-center items-center py-3 px-6 md:px-8 border-b">
                                                                        <p>No Audio Files</p>
                                                                    </div>
                                                                </ng-container>

                                                                <ng-container *ngFor="let audio of audioFiles; let i = index">
                                                                    <div class="inventory-grid flex items-center py-3 px-6 md:px-8"
                                                                        style="font-size: 14px;">
                                                                        <div style=" width: 250px;" class="truncate cursor-pointer pr-5">
                                                                            <input type="radio"
                                                                                name="selectedAudio"
                                                                                (change)="selectAudio(audio,i)"/>
                                                                            {{audio.filename}}
                                                                        </div>
                                                                        <div style="width: 350px;" class="truncate flex justify-between items-center pl-5">
                                                                            <audio controls style="width: 100%;">
                                                                                <source [src]="audio.url" type="audio/mp3" />
                                                                            </audio>
                                                                        </div>
                                                                        <div style=" width: 100px;">
                                                                            <mat-icon (click)="deleteFile( audio,i)" aria-hidden="false" color="warn"fontIcon="delete" class="ml-5"></mat-icon>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                    </mat-dialog-content>

                                                    <mat-dialog-actions>
                                                        <div
                                                            class="flex justify-between items-center w-full">
                                                            <input #csvInput type="file" hidden="true"
                                                                type="file" accept=".mp3, .mp4, audio/*"
                                                                [formControlName]="
                                                                    'BeforeCallAudio'
                                                                " (change)="
                                                                    InputChangeIVR(
                                                                        $event,
                                                                        i
                                                                    )
                                                                " />
                                                            <button mat-flat-button color="primary"
                                                                (click)="
                                                                    csvInput.click()
                                                                ">
                                                                <mat-icon
                                                                    svgIcon="mat_outline:file_upload"></mat-icon>
                                                                &nbsp;Upload
                                                                Audio
                                                            </button>

                                                            <div class="flex justify-end w-full">
                                                                <button mat-flat-button
                                                                    matDialogClose="" color="primary">
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </mat-dialog-actions>
                                                </ng-template>
                                            </div>
                                            <audio  #audioPlayer controls style="display: none;">
                                                <source *ngIf="fileUrl" [src]="fileUrl" type="audio/mp3" />
                                            </audio>
                                            <button mat-icon-button color="primary" *ngIf="fileUrl" aria-label="Audio control button"
                                            class="ml-5" (click)="toggleAudio(audioPlayer)">
                                                <mat-icon *ngIf="!audioPlaying" color="primary" class="mat-icon cursor-pointer icon-size-8" 
                                                        svgIcon="mat_solid:play_circle"></mat-icon>
                                            
                                                <mat-icon *ngIf="audioPlaying" color="primary" class="mat-icon cursor-pointer icon-size-8" 
                                                        svgIcon="mat_solid:pause_circle"></mat-icon>
                                            </button>
                                        
                                        </div>
                                    </div>

                                    <div class="mt-4">
                                        <div class="flex lg:items-center sm:items-start alignment justify-between">
                                            <div class="flex items-center mb-5">
                                                <mat-label>Play Whisper Before Call</mat-label>
                                                <mat-slide-toggle [color]="'primary'" class="mr-6 pl-5"
                                                    [formControlName]="'playWhisperBeforeCall'" (change)="toggleCheckPlayWhisperBeforeCall($event,i)">
                                                </mat-slide-toggle>
                                            </div>
                                            <div>
                                                <div class="md:flex sm:block items-center ml-8">
                                                    <mat-form-field class="md:w-60 sm:w-10">
                                                        <textarea 
                                                            [formControlName]="'playWhisperBeforeCallText'" 
                                                            (input)="whisperFunction($event.target.value)" 
                                                            matInput name="whisper" id="textarea" cols="50" rows="5">
                                                        </textarea>
                                                    </mat-form-field>
                                                    <div class="flex md:block">
                                                        <!-- Play Whisper Before Call -->
                                                        <ng-container>
                                                            <div class="justify-center items-center">
                                                                <ng-template #playWhisperBeforeCall>
                                                                    <div class="flex justify-between">
                                                                        <h2 matDialogTitle class="w-100">
                                                                            Choose
                                                                            Prompt
                                                                        </h2>
                                                                        <mat-icon class="icon-size-5" matDialogClose
                                                                            [svgIcon]="
                                                                                'close'
                                                                            "></mat-icon>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                        <br />

                                                        <button mat-icon-button color="primary"
                                                            aria-label="Example icon button with a menu icon"
                                                            class="ml-5" (click)="PlayTextToSpeechIVR(i)">
                                                            <mat-icon *ngIf="!isPlayAudio" color="primary"
                                                                class="mat-icon cursor-pointer icon-size-8"
                                                                svgIcon="mat_solid:pause_circle"></mat-icon>

                                                            <mat-icon *ngIf="isPlayAudio" color="primary"
                                                                class="mat-icon cursor-pointer icon-size-8"
                                                                svgIcon="mat_solid:play_circle"></mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- AUDIO UPLOAD end   -->
                                    <div style="
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                        ">
                                        <!-- KEY PRESS -->
                                        <mat-form-field>
                                            <mat-label>Key Press</mat-label>
                                            <input matInput maxlength="1" [formControlName]="'keyPress'" max="1"
                                                (keypress)="
                                                    keyPressNumeric($event)
                                                " type="text" [placeholder]="
                                                    'Please enter key press 0-9'
                                                " />

                                            <mat-error *ngIf="
                                                    Ivr.controls.keyPress.errors
                                                ">
                                                Duplicate value found.
                                            </mat-error>
                                        </mat-form-field>

                                        <button class="flex ml-auto" style="align-self: flex-start"
                                            *ngIf="ivrArray.controls.length > 1" (click)="removeIvrArray(i)"
                                            mat-small-fab color="primary"
                                            aria-label="Example icon button with a menu icon">
                                            <mat-icon svgIcon="close"></mat-icon>
                                        </button>
                                    </div>

                                    <div class="w-full mb-2 mt-2">
                                        <div>
                                            <div>
                                                <!-- DESTINATION -->
                                                <!-- <mat-form-field
                                                    class="w-[303px] size"
                                                >
                                                    <mat-label
                                                        >Destination</mat-label
                                                    >
                                                    <input
                                                        matInput
                                                        [formControlName]="
                                                            'destination'
                                                        "
                                                        type="text"
                                                        [placeholder]="'Sales'"
                                                    />

                                                    <mat-error
                                                        *ngIf="
                                                            Ivr.get(
                                                                'destination'
                                                            ).hasError(
                                                                'required'
                                                            )
                                                        "
                                                    >
                                                        Please enter destination
                                                    </mat-error>
                                                </mat-form-field>
                                                  -->
                                                <!-- DESTINATION END -->
                                                <br />

                                                <!-- ACTION -->
                                                <mat-form-field class="w-[303px] size">
                                                    <mat-label>Action</mat-label>
                                                    <mat-select class="z-50" [value]="ActionIVR" [formControlName]="
                                                            'IVRAction'
                                                        ">
                                                        <mat-option class="z-50" (click)="
                                                                this.ActionIVR =
                                                                    'Forwarding to a phone number'
                                                            " [value]="
                                                                'Forwarding to a phone number'
                                                            ">Forwarding to a
                                                            phone
                                                            number</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <!-- ACTION END  -->
                                            </div>

                                            <!-- ROUTING TYPE -->
                                            <mat-form-field class="w-[303px] size mt-2">
                                                <mat-label>Routing Type</mat-label>
                                                <mat-select [formControlName]="
                                                        'IVRRoutingType'
                                                    ">
                                                    <mat-option [value]="1" (click)="
                                                            changeSingleRouting(
                                                                1,
                                                                i
                                                            )
                                                        ">
                                                        Single Number
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div class="w-[303px] size" *ngIf="
                                                    this.ivrFormArray.value
                                                        .overFlowSection[i]
                                                        .IVRRoutingType === 1
                                                " class="mt-4">
                                                <ng-container>
                                                    <div class="relative w-[303px] size flex">
                                                        <mat-form-field
                                                            class="fuse-mat-no-subscript flex-auto w-[303px] size">
                                                            <mat-label style="
                                                                    color: #787f8a;
                                                                    font-weight: 500;
                                                                    font-size: 14px;
                                                                    margin-bottom: 3px;
                                                                ">Ring to phone
                                                                number</mat-label>
                                                            <input matInput maxlength="14" [countryCode]="
                                                                    selectedCountryCode
                                                                " appPhoneNumMask [formControl]="
                                                                    Ivr.get(
                                                                        'IVRRingToNumber'
                                                                    )
                                                                " #ivrRingToNumber (keypress)="
                                                                    keyPressNumeric(
                                                                        $event
                                                                    )
                                                                " (countryChange)="
                                                                    onCountryChangeIVR(
                                                                        $event,
                                                                        i
                                                                    )
                                                                " (change)="
                                                                    checkformValue(
                                                                        Ivr.get(
                                                                            'IVRRingToNumber'
                                                                        )
                                                                    )
                                                                " [placeholder]="
                                                                    'Phone number'
                                                                " />
                                                            <mat-select class="mr-1.5" [formControl]="
                                                                    Ivr.get(
                                                                        'country'
                                                                    )
                                                                " matPrefix>
                                                                <mat-select-trigger>
                                                                    <span class="flex items-center">
                                                                        <span
                                                                            class="hidden sm:flex w-6 h-4 mr-1 overflow-hidden"
                                                                            [style.background]="
                                                                                'url(\'/assets/images/apps/contacts/flags.png\') no-repeat 0 0'
                                                                            " [style.backgroundSize]="
                                                                                '24px 3876px'
                                                                            " [style.backgroundPosition]="getCountryByIso(Ivr.get('country').value)?.flagImagePos || '0 0'"></span>
                                                                        <span
                                                                            class="sm:mx-0.5 font-medium text-default"> {{ getCountryByIso(Ivr.get('country').value)?.code || 'Select country' }}</span>
                                                                    </span>
                                                                </mat-select-trigger>
                                                                <ng-container *ngFor="let country of countries; trackBy: trackByFn">
                                                                    <mat-option [value]="
                                                                            country.iso
                                                                        " (click)="
                                                                            onCountryChangeIVR(
                                                                                country,
                                                                                i
                                                                            )
                                                                        ">
                                                                        <span class="flex items-center">
                                                                            <span class="w-6 h-4 overflow-hidden"
                                                                                [style.background]="
                                                                                    'url(\'/assets/images/apps/contacts/flags.png\') no-repeat 0 0'
                                                                                " [style.backgroundSize]="
                                                                                    '24px 3876px'
                                                                                " [style.backgroundPosition]="
                                                                                    country.flagImagePos
                                                                                "></span>
                                                                            <span class="ml-2">{{
                                                                                country.name
                                                                                }}</span>
                                                                            <span class="ml-2 font-medium">{{
                                                                                country.code
                                                                                }}</span>
                                                                        </span>
                                                                    </mat-option>
                                                                </ng-container>
                                                            </mat-select>

                                                            <!-- <mat-error *ngIf="ivrFormArray.controls[IVRRingToNumber].invalid && (ivrFormArray.controls[IVRRingToNumber].dirty || ivrFormArray.controls[IVRRingToNumber].touched)">
                                                                <span *ngIf="ivrFormArray.controls[IVRRingToNumber].hasError('required')">Input is required</span>
                                                                <span *ngIf="ivrFormArray.controls[IVRRingToNumber].hasError('minlength')">Input must be at least 6 characters</span>
                                                              </mat-error> -->
                                                            <!-- <mat-error *ngIf="Ivr.get('IVRRingToNumber').errors?.minlength">IVRRingToNumber must be at least {{ Ivr.get('IVRRingToNumber').errors.minlength.requiredLength }} characters long.</mat-error> -->

                                                            <mat-error *ngIf="
                                                                    Ivr.get(
                                                                        'IVRRingToNumber'
                                                                    ).hasError(
                                                                        'required'
                                                                    )
                                                                ">
                                                                Please enter the
                                                                ring to phone
                                                                number
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </ng-container>

                                                <!-- </mat-form-field> -->
                                            </div>
                                            <!-- ROUTING TYPE END  -->

                                            <!-- RING TO NUMBEER -->
                                            <div>
                                                <div *ngIf="
                                                        this.ivrFormArray.value
                                                            .overFlowSection[i]
                                                            .IVRRoutingType ===
                                                        2
                                                    " class="mt-4">
                                                    <div formArrayName="IVROverflowNumbers">
                                                        <p style="
                                                                margin-bottom: -18px;
                                                                color: #787f8a;
                                                                font-weight: 500;
                                                                font-size: 14px;
                                                            ">
                                                            Ring to phone number
                                                        </p>
                                                        <div *ngFor="
                                                                let ivrof of ivrarrRobin(
                                                                    i
                                                                ).controls;
                                                                let r = index
                                                            ">
                                                            <div class="form-container" [formGroupName]="
                                                                    r
                                                                ">
                                                                <div class="flex items-center mt-6">
                                                                    <!-- <mat-form-field class="w-[303px]">
                                                                        <input ng2TelInput (countryChange)="
                                                                                onCountryChangeIVR(
                                                                                    $event,
                                                                                    i,
                                                                                    r
                                                                                )
                                                                            " [ng2TelInputOptions]="
                                                                                telOptions
                                                                            " matInput id="phonenumber" maxlength="13"
                                                                            max="13" type="text" (keypress)="
                                                                                keyPressNumeric(
                                                                                    $event
                                                                                )
                                                                            " formControlName="number" [placeholder]="
                                                                                'Phone number'
                                                                            " />
                                                                    </mat-form-field> -->

                                                                    <ng-container>
                                                                        <div class="relative flex">
                                                                            <mat-form-field
                                                                                class="fuse-mat-no-subscript w-[303px] size flex-auto">
                                                                                <!-- <mat-label *ngIf="first">Ring to phone
                                                                                number</mat-label> -->
                                                                                <input matInput maxlength="14"
                                                                                    [countryCode]="
                                                                                        selectedCountryCode
                                                                                    " appPhoneNumMask [formControl]="
                                                                                        ivrof.get(
                                                                                            'number'
                                                                                        )
                                                                                    " (keypress)="
                                                                                        keyPressNumeric(
                                                                                            $event
                                                                                        )
                                                                                    " (countryChange)="
                                                                                        onCountryChangeIVR(
                                                                                            $event,
                                                                                            i
                                                                                        )
                                                                                    " (change)="
                                                                                        checkformValue()
                                                                                    " [placeholder]="
                                                                                        'Phone number'
                                                                                    " />
                                                                                <mat-select class="mr-1.5"
                                                                                    [formControl]="
                                                                                        ivrof.get(
                                                                                            'country'
                                                                                        )
                                                                                    " matPrefix>
                                                                                    <mat-select-trigger>
                                                                                        <span class="flex items-center">
                                                                                            <span
                                                                                                class="hidden sm:flex w-6 h-4 mr-1 overflow-hidden"
                                                                                                [style.background]="
                                                                                                    'url(\'/assets/images/apps/contacts/flags.png\') no-repeat 0 0'
                                                                                                "
                                                                                                [style.backgroundSize]="
                                                                                                    '24px 3876px'
                                                                                                "
                                                                                                [style.backgroundPosition]="getCountryByIso(Ivr.get('country').value)?.flagImagePos || '0 0'"></span>
                                                                                            <span
                                                                                                class="sm:mx-0.5 font-medium text-default">{{ getCountryByIso(Ivr.get('country').value)?.code || 'Select country' }}</span>
                                                                                        </span>
                                                                                    </mat-select-trigger>
                                                                                    <ng-container *ngFor="
                                                                                            let country of countries;
                                                                                            trackBy: trackByFn
                                                                                        ">
                                                                                        <mat-option [value]="
                                                                                                country.iso
                                                                                            " (click)="
                                                                                                onCountryChangeIVR(
                                                                                                    country,
                                                                                                    i,
                                                                                                    r
                                                                                                )
                                                                                            ">
                                                                                            <span
                                                                                                class="flex items-center">
                                                                                                <span
                                                                                                    class="w-6 h-4 overflow-hidden"
                                                                                                    [style.background]="
                                                                                                        'url(\'/assets/images/apps/contacts/flags.png\') no-repeat 0 0'
                                                                                                    "
                                                                                                    [style.backgroundSize]="
                                                                                                        '24px 3876px'
                                                                                                    "
                                                                                                    [style.backgroundPosition]="country.flagImagePos || '0 0'"></span>
                                                                                                <span class="ml-2">{{
                                                                                                    country.name
                                                                                                    }}</span>
                                                                                                <span
                                                                                                    class="ml-2 font-medium">{{
                                                                                                    country.code
                                                                                                    }}</span>
                                                                                            </span>
                                                                                        </mat-option>
                                                                                    </ng-container>
                                                                                </mat-select>

                                                                                <mat-error *ngIf="
                                                                                        ivrof
                                                                                            .get(
                                                                                                'number'
                                                                                            )
                                                                                            .hasError(
                                                                                                'required'
                                                                                            )
                                                                                    ">
                                                                                    Please
                                                                                    enter
                                                                                    the
                                                                                    ring
                                                                                    to
                                                                                    phone
                                                                                    number
                                                                                </mat-error>
                                                                            </mat-form-field>
                                                                        </div>
                                                                    </ng-container>

                                                                    <div class="flex justify-between items-center">
                                                                        <p class="hidden md:block ml-4 mr-3 mb-0">
                                                                            After
                                                                        </p>
                                                                        <mat-form-field>
                                                                            <mat-select formControlName="rings">
                                                                                <mat-option [value]="
                                                                                        '1'
                                                                                    ">1</mat-option>
                                                                                <mat-option [value]="
                                                                                        '2'
                                                                                    ">2</mat-option>
                                                                                <mat-option [value]="
                                                                                        '3'
                                                                                    ">3</mat-option>
                                                                                <mat-option [value]="
                                                                                        '4'
                                                                                    ">4</mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                        <p class="hidden md:block ml-4 mr-3 mb-0">
                                                                            Rings
                                                                        </p>
                                                                    </div>
                                                                    <button class="mt-2 ml-3" *ngIf="
                                                                            ivrarrRobin(
                                                                                i
                                                                            )
                                                                                .controls
                                                                                .length >
                                                                            1
                                                                        " (click)="
                                                                            removeivrRobinAt(
                                                                                i,
                                                                                r
                                                                            )
                                                                        " mat-small-fab color="primary"
                                                                        aria-label="Example icon button with a menu icon">
                                                                        <mat-icon svgIcon="delete"></mat-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="flex example-button-container pt-8">
                                                        <button (click)="
                                                                addivrRobin(i)
                                                            " aria-label="Example icon button with a delete icon">
                                                            <mat-icon color="primary" class="mat-icon icon-size-6"
                                                                svgIcon="feather:plus-circle"></mat-icon>
                                                        </button>
                                                        <mat-label class="pl-3">Add Overflow
                                                            Numbers</mat-label>
                                                    </div>
                                                </div>
                                                <div *ngIf="
                                                        this.ivrFormArray.value
                                                            .overFlowSection[i]
                                                            .IVRRoutingType ===
                                                        3
                                                    " class="mt-4">
                                                    <div formArrayName="IVROverflowNumbers">
                                                        <p style="
                                                                margin-bottom: -18px;
                                                                color: #787f8a;
                                                                font-weight: 500;
                                                                font-size: 14px;
                                                            ">
                                                            Ring to phone number
                                                        </p>
                                                        <div *ngFor="
                                                                let ivrof1 of ivrarrSimal(
                                                                    i
                                                                ).controls;
                                                                let s = index
                                                            ">
                                                            <div class="form-container" [formGroupName]="
                                                                    s
                                                                ">
                                                                <div class="flex items-center mt-6">
                                                                    <!-- <mat-form-field class="w-[303px]">
                                                                        <input ng2TelInput (countryChange)="
                                                                                onCountryChangeIVR(
                                                                                    $event,
                                                                                    i,
                                                                                    s
                                                                                )
                                                                            " [ng2TelInputOptions]="
                                                                                telOptions
                                                                            " matInput id="phonenumber" maxlength="13"
                                                                            max="13" type="text" (keypress)="
                                                                                keyPressNumeric(
                                                                                    $event
                                                                                )
                                                                            " formControlName="number" [placeholder]="
                                                                                'Phone number'
                                                                            " />
                                                                    </mat-form-field> -->

                                                                    <ng-container>
                                                                        <div class="relative flex">
                                                                            <mat-form-field
                                                                                class="fuse-mat-no-subscript w-[303px] size flex-auto">
                                                                                <!-- <mat-label *ngIf="first">Ring to phone
                                                                                number</mat-label> -->
                                                                                <input matInput maxlength="14"
                                                                                    [countryCode]="
                                                                                        selectedCountryCode
                                                                                    " appPhoneNumMask [formControl]="
                                                                                        ivrof1.get(
                                                                                            'number'
                                                                                        )
                                                                                    " (keypress)="
                                                                                        keyPressNumeric(
                                                                                            $event
                                                                                        )
                                                                                    " (countryChange)="
                                                                                        onCountryChangeIVR(
                                                                                            $event,
                                                                                            i
                                                                                        )
                                                                                    " [placeholder]="
                                                                                        'Phone number'
                                                                                    " />
                                                                                <mat-select class="mr-1.5"
                                                                                    [formControl]="
                                                                                        ivrof1.get(
                                                                                            'country'
                                                                                        )
                                                                                    " matPrefix>
                                                                                    <mat-select-trigger>
                                                                                        <span class="flex items-center">
                                                                                            <span
                                                                                                class="hidden sm:flex w-6 h-4 mr-1 overflow-hidden"
                                                                                                [style.background]="
                                                                                                    'url(\'/assets/images/apps/contacts/flags.png\') no-repeat 0 0'
                                                                                                "
                                                                                                [style.backgroundSize]="
                                                                                                    '24px 3876px'
                                                                                                "
                                                                                                [style.backgroundPosition]="
                                                                                                    getCountryByIso(
                                                                                                        ivrof1.get(
                                                                                                            'country'
                                                                                                        )
                                                                                                            .value
                                                                                                    )
                                                                                                        .flagImagePos
                                                                                                "></span>
                                                                                            <span
                                                                                                class="sm:mx-0.5 font-medium text-default">{{
                                                                                                getCountryByIso(
                                                                                                ivrof1.get(
                                                                                                "country"
                                                                                                )
                                                                                                .value
                                                                                                )
                                                                                                .code
                                                                                                }}</span>
                                                                                        </span>
                                                                                    </mat-select-trigger>
                                                                                    <ng-container *ngFor="
                                                                                            let country of countries;
                                                                                            trackBy: trackByFn
                                                                                        ">
                                                                                        <mat-option [value]="
                                                                                                country.iso
                                                                                            " (click)="
                                                                                                onCountryChangeIVR(
                                                                                                    country,
                                                                                                    i,
                                                                                                    s
                                                                                                )
                                                                                            ">
                                                                                            <span
                                                                                                class="flex items-center">
                                                                                                <span
                                                                                                    class="w-6 h-4 overflow-hidden"
                                                                                                    [style.background]="
                                                                                                        'url(\'/assets/images/apps/contacts/flags.png\') no-repeat 0 0'
                                                                                                    "
                                                                                                    [style.backgroundSize]="
                                                                                                        '24px 3876px'
                                                                                                    "
                                                                                                    [style.backgroundPosition]="
                                                                                                        country.flagImagePos
                                                                                                    "></span>
                                                                                                <span class="ml-2">{{
                                                                                                    country.name
                                                                                                    }}</span>
                                                                                                <span
                                                                                                    class="ml-2 font-medium">{{
                                                                                                    country.code
                                                                                                    }}</span>
                                                                                            </span>
                                                                                        </mat-option>
                                                                                    </ng-container>
                                                                                </mat-select>

                                                                                <mat-error *ngIf="
                                                                                        ivrof1
                                                                                            .get(
                                                                                                'number'
                                                                                            )
                                                                                            .hasError(
                                                                                                'required'
                                                                                            )
                                                                                    ">
                                                                                    Please enter the ring to phone number
                                                                                </mat-error>
                                                                            </mat-form-field>
                                                                        </div>
                                                                    </ng-container>

                                                                    <button class="mt-2 ml-3" *ngIf="
                                                                            ivrarrSimal(i).controls.length > 1
                                                                        "(click)="removeivrSimalAt(i,s)" 
                                                                        mat-small-fab color="primary"
                                                                        aria-label="Example icon button with a menu icon">
                                                                        <mat-icon svgIcon="delete"></mat-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="flex example-button-container pt-8" *ngIf="
                                                            this.ivrFormArray
                                                                .value
                                                                .overFlowSection[
                                                                i
                                                            ].IVRRoutingType ===
                                                            3
                                                        ">
                                                        <button (click)="
                                                                addivrSimal(i)
                                                            " aria-label="Example icon button with a delete icon">
                                                            <mat-icon color="primary" class="mat-icon icon-size-6"
                                                                svgIcon="feather:plus-circle"></mat-icon>
                                                        </button>
                                                        <mat-label class="pl-3">Add Overflow
                                                            Numbers</mat-label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- RING TO NUMBER END -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <button class="w-32 mt-2" [hidden]="ivrArray.controls.length >= 10" mat-flat-button
                        (click)="addIvrArray()" [color]="'primary'">
                        <span class="ml-2">Add Key</span>
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:chevron-right'"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <!--  -->

        <!-- IVR CODE END -->

        <!-- save Button -->

        <div *ngIf="yearlyBilling" class="flex items-center ml-8 mr-8 mb-2 justify-between">
            <button class="w-32" routerLink="/apps/tracking-number" mat-stroked-button [color]="'primary'">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:chevron-left'"></mat-icon>
                <span class="ml-2">Back</span>
            </button>
            <button class="ml-3 w-32" mat-flat-button 
                [disabled]=" !isSubmit || checkValiditySingle()"
                (click)="onSave();" [color]="'primary'"
                [ngClass]="cursorDrop ? 'cursor-not-allowed' : 'cursor-pointer'">
                <span class="ml-2">Save</span>
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:chevron-right'"></mat-icon>
            </button>
        </div>

        <!-- <div *ngIf="!yearlyBilling" class="flex items-center ml-8 mr-8 mb-2 justify-between">
            <button routerLink="/apps/tracking-number" class="w-32" mat-stroked-button [color]="'primary'">
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:chevron-left'"></mat-icon>
                <span class="ml-2">Back</span>
            </button>
            <button class="w-32 ml-3" [disabled]="
                    poolNumberForm.invalid ||
                    (createSingleForm.invalid &&
                        roundRobinForm.invalid &&
                        simaltaneousForm.invalid &&
                        ivrFormArray.invalid &&
                        ivrArray.invalid) ||
                    !isSubmit ||
                    checkValiditySingle()
                " (click)="onSave()" mat-flat-button [color]="'primary'" (click)="checkStatusmes()"
                [ngClass]="cursorDrop ? 'cursor-not-allowed' : 'cursor-pointer'">
                <span class="ml-2">Save</span>
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:chevron-right'"></mat-icon>
            </button>
        </div> -->

        <!--  -->
    </div>
</div>

<ng-template #trackingNumberDel let-modal>
    <div class="modal-header" style="justify-content: flex-start">
        <mat-icon style="align-self: flex-start" svgIcon="heroicons_outline:exclamation" color="warn"></mat-icon>
        <div style="display: flex; flex-direction: column" class="ml-2">
            <h5>Remove Tracking Number</h5>
            <p>
                Are you sure you want to remove Tracking Number permanently?
                This action cannot be undone!
            </p>
        </div>
    </div>
    <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <mat-icon style="position: absolute; top: 10px; right: 10px" svgIcon="close"></mat-icon>
    </a>
    <div
        class="flex items-center justify-center rounded-bl-3xl rounded-br-3xl sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10 ng-star-inserted">
        <button mat-stroked-button="" class="mat-focus-indicator mat-stroked-button mat-button-base ng-star-inserted"
            type="button" (click)="modal.dismiss('Cross click')">
            <span class="mat-button-wrapper"> Cancel</span></button><button (click)="OnDeleteTN()" mat-flat-button=""
            class="mat-focus-indicator mat-flat-button mat-button-base mat-warn ng-star-inserted" type="button">
            <span class="mat-button-wrapper"> Remove</span>
        </button>
    </div>
</ng-template>