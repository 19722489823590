import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CallLogsService {
    public callLogsList: BehaviorSubject<any[] | null> = new BehaviorSubject(
        null
    );
    constructor(private _httpClient: HttpClient) {}

    //Call Logs List
    //get call Logs
    getCallLogs(Page, Row, ouid) {
        let token = localStorage.getItem('token');
        var headers_object = new HttpHeaders({ token: token });

        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getCallLogs',
            {
                page: Page,
                rows: Row,
                groupId: ouid,
            },
            httpOptions
        );
    }

    ////transcriptions
    getTranscriptions(calluuid) {
        let token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });
        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getTranscriptions',
            { calluuid: calluuid },
            httpOptions
        );
    }

    ////recordings
    getRecordings(key) {
        let token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });
        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/getIndicators',
            { recordingKey: key },
            httpOptions
        );
    }

    reAnalyzed(key, uuid, source) {
        let token = localStorage.getItem('token');
        const headers_object = new HttpHeaders({ token: token });
        const httpOptions = {
            headers: headers_object,
        };
        return this._httpClient.post(
            'https://67vk4afxrk.execute-api.us-east-1.amazonaws.com/reAnalyze',
            { recordingKey: key, calluuid: uuid, sourceNumber: source },
            httpOptions
        );
    }
}
