<p mat-dialog-title class="text-center">Select Groups</p>
<div mat-dialog-content>
    <div class="treeContainer">
        <input
            class="groupFilterSearch"
            [value]="currentFilterText"
            placeholder="Search groups"
            (input)="filterChanged($event)"
        />

        <cdk-virtual-scroll-viewport
            itemSize="38"
            minBufferPx="500"
            maxBufferPx="900"
            class="virtual-viewport"
        >
            <ng-container *cdkVirtualFor="let node of dataSource">
                <div
                    class="tree-node"
                    [style.padding-left]="node.level * 24 + 'px'"
                    [style.display]="showNode(node) ? 'flex' : 'none'"
                    style="align-items: center;"
                >
                    <!-- Disabled button padding for leaf nodes -->
                    <button
                        mat-icon-button
                        disabled
                        *ngIf="!node.expandable"
                    ></button>
                    <!-- Expand/Collapse button for parent nodes-->
                    <button
                        mat-icon-button
                        (click)="treeControl.toggle(node)"
                        *ngIf="node.expandable"
                        style="margin-bottom: 0.5rem;"
                    >
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{
                                treeControl.isExpanded(node)
                                    ? "expand_more"
                                    : "chevron_right"
                            }}
                        </mat-icon>
                    </button>
                    <mat-checkbox
                        class="checklist-leaf-node"
                        [checked]="checklistSelection.isSelected(node.id)"
                        (click)="selectionToggle(node)"
                        [indeterminate]="descendantsPartiallySelected(node)"
                        color="primary"
                    >
                        {{ node.item }}
                    </mat-checkbox>
                </div>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    </div>
</div>

<div mat-dialog-actions>
    <button
        (click)="closeModal()"
        class="sm:inline-flex ml-3 w-32 float-right"
        mat-flat-button
        [color]="'primary'"
    >
        <span>Apply</span>
    </button>
    <button
        mat-dialog-close
        class="sm:inline-flex ml-3 w-32 float-right"
        mat-flat-button
        [color]="'secondary'"
        (click)="cancelModal()"
    >
        <span>Cancel</span>
    </button>
</div>
