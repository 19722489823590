import { Injectable } from '@angular/core';
import { InventoryService } from 'app/modules/admin/ecommerce/inventory/inventory.service';
import { BehaviorSubject } from 'rxjs';
import { GroupNode } from './models/GroupNode';
import { TreeItemNode } from './models/TreeItemNode';
let TREE_DATA = [];

@Injectable({
    providedIn: 'root',
})
export class GroupsDatabaseService {
    dataChange = new BehaviorSubject<any>([]);
    GroupsArray = new BehaviorSubject<any>([]);
    TodayFilter = new BehaviorSubject<any>([]);
    selectedNodes = new BehaviorSubject<any>([]);

    loader: boolean;

    constructor(private _inventoryService: InventoryService) {}
    treeData: any;
    initGroups() {
        this.loader = true;

        this._inventoryService
            .getAllTreeGroups()
            .subscribe(async (data: any) => {
                // this.dataSource.data = data;
                // JSON.parse(localStorage.setItem('groups',data));

                TREE_DATA = await data.body;
                this.treeData = data.body;

                const New = this.buildFileTree(TREE_DATA, '0');
                this.dataChange.next(New);
            });
        // if (TREE_DATA) {
        //     this.treeData = TREE_DATA;
        //     // localStorage.setItem('TREE_DUM_DATA', JSON.stringify(this.treeData))
        // }

        ////////////////////////////////////////////////////
    }

    buildFileTree(obj: any[], level: string): TreeItemNode[] {
        return obj
            .filter(
                (o) =>
                    (<string>o.code)?.startsWith(level + '.') &&
                    (o.code.match(/\./g) || []).length ===
                        (level.match(/\./g) || []).length + 1
            )
            .map((o) => {
                const node = new TreeItemNode();
                node.item = o.text;
                node.code = o.code;
                // node.isSelected = o.isSelected;
                node.id = o.id;
                const children = obj.filter((so) =>
                    (<string>so.code)?.startsWith(level + '.')
                );
                if (children && children.length > 0) {
                    node.children = this.buildFileTree(children, o.code);
                }
                return node;
            });
    }
}
